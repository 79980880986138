import React from 'react';
// import {getOrdersReady, } from "../../utilityFunctions/WarehouseUtil";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Dialog from '@mui/material/Dialog';
import Colors from "../../components/ui/Colors";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

let orderClicked = null;

export default function ActiveOrders(props) {
    
    const {loginUser, warehouseProducts} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=> {
        
        renderReadyOrders();

    }, []);

    const initialValues = async () => {

        if(!props.location.state || !props.location.state.order || !props.location.state.order.deliveryOrderResponse){
            
            return;
        }
        
        const orders = props.location.state.order.deliveryOrderResponse;

        // const orders = await getOrdersReady(loginUser.jwtToken);

        const activeOrders = orders.filter((el) => (el.status !=="pending"));

        // console.log(activeOrders);

        return activeOrders;
    };

    const [readyOrders, setReadyOrders] = React.useState(null);

    // Function to handle selecting an order when clicking on the list item
    const selectOrder = (order) => {
        orderClicked=order;
        goToBatches();
        // setActionsBox(true);
    };

    const renderReadyOrders = async () => {
        setLoading(true);
        const ordersArray = await initialValues();
        setLoading(false);

        if(!ordersArray){
            return;
        }

        // ordersArray.sort((a, b) => a.orderCode - b.orderCode);
        ordersArray.sort((a, b) => a.description - b.description);

        setReadyOrders(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {ordersArray.map((order)=> {
                    const foundProduct = warehouseProducts.find((obj) => obj.itemNumber === order.itemNumber);
                    return(
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton key={generateRandomKey()} onClick={() => selectOrder(order)}>
                                <Grid key={generateRandomKey()}  container spacing={1}>
                                    <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(order.date))}`}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.orderCode}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.clientName}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.description}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{new Intl.NumberFormat('es-CO').format(order.orderAmount)}</Typography>
                                    </Grid>
                                    {foundProduct?
                                        <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2">{new Intl.NumberFormat('es-CO').format(order.orderAmount * foundProduct.content)}</Typography>
                                        </Grid>
                                        :
                                        <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2">{new Intl.NumberFormat('es-CO').format("Kg")}</Typography>
                                        </Grid>
                                    }
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.status === "pending" ? "Pendiente" : order.status === "active" ? "Activa" : order.status === "inPickup" ? "En cargue" : "Completada" }</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    )
                })

                }
            </List>
        );
    }

    const [actionsBox, setActionsBox] = React.useState(false);
    
    const closeActionBox = () => {
        setActionsBox(false);
    }

    const goToUpdate = () => {
        history.push({
            pathname: "/warehouse/update-orders",
            state: {
                order: orderClicked,
            }
        });
    };

    const goToBatches = () => {
        history.push({
            pathname: "/warehouse/batches-order",
            state: {
                order: orderClicked,
            }
        });
    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : 
            <>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container sx={{marginTop: "1rem"}}>
                            <Grid  item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Fecha Orden</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Orden/Factura</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Cliente</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Producto</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Cantidad</Typography>
                            </Grid>
                            <Grid  item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Peso</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Estado</Typography>
                            </Grid>                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {readyOrders}
                    </Grid>
                </Grid>

                <Dialog open={actionsBox} onClose={closeActionBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "40%", md: "30%"},
                                height: "20%"
                            },
                        },
                    }}
                >
                    <List sx={{width: "100%",}}>
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={goToUpdate}>
                                <Typography variant="h6">Completar Orden</Typography>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={goToBatches} sx={{textAlign: "center"}}>
                                <Typography variant="h6">Seleccionar Estibas</Typography>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Dialog>
            </>
            }
            </Box>
        </Box>
    )
}
