import React from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../components/ui/Colors";
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Slot from "./Slot";
import QrScan from './QrScan';
import {getActiveProductBatches, createNewPosition} from "../../utilityFunctions/WarehouseUtil";
import TurnRightIcon from '@mui/icons-material/TurnRight';
import StraightIcon from '@mui/icons-material/Straight';
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const positionObject = {
    id: "",
    rack: "",
    hall: "",
    depth: "",
    level: "",
    username: "",
}

let levelDetail = [];

let floorBatches = [];

export default function WarehouseStructure(props) {

    const{loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=> {
        levelDetail = [];
        floorBatches = [];
        positionObject.username=loginUser.accountId;
        initialPositions();
    }, []);

    const[renderScan, setRenderScan] = React.useState("");

    const scanProduct = () => {

        setRenderScan(
            <QrScan labelAssignment={labelAssignment} />
        );
    };

    const labelAssignment = (labelId) => {
        positionObject.id=labelId;
    };

    const handleSlot = (positionSelected) => {
        handleNewPosition(positionSelected);
        
    };

    const handleNewPosition = async (positionSelected) => {
        
        if(!positionSelected || !positionSelected.positionName){
            return;
        }

        const newPosition = positionSelected.positionName;

        const initialLetter = newPosition.slice(0, 1);

        if(initialLetter==="t" || initialLetter==="v"){
            positionObject.rack=newPosition;
            positionObject.level = positionSelected.level;
        }
        else{
            positionObject.rack = newPosition.slice(0, 2);
            positionObject.hall = newPosition.slice(2, 4);
            positionObject.depth = newPosition.slice(4, 6);
            positionObject.level = positionSelected.level;
        }
        
        if(props.location.state && props.location.state.batch){
            positionObject.id = props.location.state.batch.id;
            
            registerPosition();
        }

        else{
            scanProduct();
        }

    };

    const registerPosition = async () => {
       
        setLoading(true);
        const batch = await createNewPosition(positionObject,loginUser.jwtToken);
        setLoading(false);

        if(!batch){

            swal({
                title: "Ocurrión un problema",
                icon: "error",
                button: "ok",
            });
            return;
        };

        swal({
            title: "Estiba registrada",
            text: `En la posición: ${batch.position === "floor" ? "Piso" : batch.position}`,
            icon: "success",
            button: "Súper",
        }).then(()=>{
            history.push({
                pathname: "/handle_profile",
            })
        });
    };

    const [floorCounter, setFloorCounter] = React.useState(0);

    //V type (floor)
    const[v1, setV1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v1" />);
    const[v2, setV2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v2" />);
    const[v3, setV3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v3" />);
    const[v4, setV4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v4" />);
    const[v5, setV5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v5" />);
    const[v6, setV6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v6" />);
    const[v7, setV7] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v7" />);
    const[v8, setV8] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v8" />);
    const[v9, setV9] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v9" />);
    const[v10, setV10] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v10" />);
    const[v11, setV11] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v11" />);
    const[v12, setV12] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v12" />);
    const[v13, setV13] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v13" />);
    const[v14, setV14] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v14" />);
    const[v15, setV15] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v15" />);
    const[v16, setV16] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v16" />);
    const[v17, setV17] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v1" />);
    const[v18, setV18] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v18" />);
    const[v19, setV19] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v19" />);
    const[v20, setV20] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v20" />);
    const[v21, setV21] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v21" />);
    const[v22, setV22] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v22" />);
    const[v23, setV23] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v23" />);
    const[v24, setV24] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v24" />);
    const[v25, setV25] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v25" />);
    const[v26, setV26] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v26" />);
    const[v27, setV27] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v27" />);
    const[v28, setV28] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v28" />);
    const[v29, setV29] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v29" />);
    const[v30, setV30] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v30" />);
    const[v31, setV31] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v31" />);
    const[v32, setV32] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v32" />);
    const[v33, setV33] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v33" />);
    const[v34, setV34] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v34" />);
    const[v35, setV35] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v35" />);
    const[v36, setV36] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v36" />);
    const[v37, setV37] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v37" />);
    const[v38, setV38] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v38" />);
    const[v39, setV39] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v39" />);
    const[v40, setV40] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v40" />);
    const[v41, setV41] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v41" />);
    const[v42, setV42] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="v42" />);

    const[t1, setT1]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t1" />);
    const[t2, setT2]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t2" />);
    const[t3, setT3]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t3" />);
    const[t4, setT4]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t4" />);
    const[t5, setT5]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t5" />);
    const[t6, setT6]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t6" />);
    const[t7, setT7]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t7" />);
    const[t8, setT8]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t8" />);
    const[t9, setT9]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t9" />);
    const[t10, setT10]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t10" />);
    const[t11, setT11]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t11" />);
    const[t12, setT12]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t12" />);
    const[t13, setT13]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t13" />);
    const[t14, setT14]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t14" />);
    const[t15, setT15]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t15" />);
    const[t16, setT16]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t16" />);
    const[t17, setT17]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t17" />);
    const[t18, setT18]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t18" />);
    const[t19, setT19]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t19" />);
    const[t20, setT20]= React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="t20" />);
    const[rAp1p1, setrAp1p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp1p1" />);
    const[rAp1p2, setrAp1p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp1p2" />);
    const[rAp1p3, setrAp1p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp1p3" />);
    const[rAp1p4, setrAp1p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp1p4" />);
    const[rAp1p5, setrAp1p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp1p5" />);
    const[rAp2p1, setrAp2p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp2p1" />);
    const[rAp2p2, setrAp2p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp2p2" />);
    const[rAp2p3, setrAp2p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp2p3" />);
    const[rAp2p4, setrAp2p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp2p4" />);
    const[rAp2p5, setrAp2p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp2p5" />);
    const[rAp3p1, setrAp3p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp3p1" />);
    const[rAp3p2, setrAp3p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp3p2" />);
    const[rAp3p3, setrAp3p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp3p3" />);
    const[rAp3p4, setrAp3p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp3p4" />);
    const[rAp3p5, setrAp3p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp3p5" />);
    const[rAp4p1, setrAp4p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp4p1" />);
    const[rAp4p2, setrAp4p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp4p2" />);
    const[rAp4p3, setrAp4p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp4p3" />);
    const[rAp4p4, setrAp4p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp4p4" />);
    const[rAp4p5, setrAp4p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp4p5" />);
    const[rAp5p1, setrAp5p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp5p1" />);
    const[rAp5p2, setrAp5p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp5p2" />);
    const[rAp5p3, setrAp5p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp5p3" />);
    const[rAp5p4, setrAp5p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp5p4" />);
    const[rAp5p5, setrAp5p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp5p5" />);
    const[rAp6p1, setrAp6p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp6p1" />);
    const[rAp6p2, setrAp6p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp6p2" />);
    const[rAp6p3, setrAp6p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp6p3" />);
    const[rAp6p4, setrAp6p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp6p4" />);
    const[rAp6p5, setrAp6p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rAp6p5" />);
    const[rBp1p1, setrBp1p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp1p1" />);
    const[rBp1p2, setrBp1p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp1p2" />);
    const[rBp1p3, setrBp1p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp1p3" />);
    const[rBp1p4, setrBp1p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp1p4" />);
    const[rBp1p5, setrBp1p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp1p5" />);
    const[rBp1p6, setrBp1p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp1p6" />);
    const[rBp1p7, setrBp1p7] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp1p7" />);
    const[rBp2p1, setrBp2p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp2p1" />);
    const[rBp2p2, setrBp2p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp2p2" />);
    const[rBp2p3, setrBp2p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp2p3" />);
    const[rBp2p4, setrBp2p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp2p4" />);
    const[rBp2p5, setrBp2p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp2p5" />);
    const[rBp2p6, setrBp2p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp2p6" />);
    const[rBp2p7, setrBp2p7] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp2p7" />);
    const[rBp3p1, setrBp3p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp3p1" />);
    const[rBp3p2, setrBp3p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp3p2" />);
    const[rBp3p3, setrBp3p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp3p3" />);
    const[rBp3p4, setrBp3p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp3p4" />);
    const[rBp3p5, setrBp3p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp3p5" />);
    const[rBp3p6, setrBp3p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp3p6" />);
    const[rBp3p7, setrBp3p7] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp3p7" />);
    const[rBp4p1, setrBp4p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp4p1" />);
    const[rBp4p2, setrBp4p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp4p2" />);
    const[rBp4p3, setrBp4p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp4p3" />);
    const[rBp4p4, setrBp4p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp4p4" />);
    const[rBp4p5, setrBp4p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp4p5" />);
    const[rBp4p6, setrBp4p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp4p6" />);
    const[rBp4p7, setrBp4p7] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp4p7" />);
    const[rBp5p1, setrBp5p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp5p1" />);
    const[rBp5p2, setrBp5p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp5p2" />);
    const[rBp5p3, setrBp5p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp5p3" />);
    const[rBp5p4, setrBp5p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp5p4" />);
    const[rBp5p5, setrBp5p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp5p5" />);
    const[rBp5p6, setrBp5p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp5p6" />);
    const[rBp5p7, setrBp5p7] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rBp5p7" />);
    const[rCp1p1, setrCp1p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp1p1" />);
    const[rCp1p2, setrCp1p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp1p2" />);
    const[rCp1p3, setrCp1p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp1p3" />);
    const[rCp2p1, setrCp2p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp2p1" />);
    const[rCp2p2, setrCp2p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp2p2" />);
    const[rCp2p3, setrCp2p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp2p3" />);
    const[rCp3p1, setrCp3p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp3p1" />);
    const[rCp3p2, setrCp3p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp3p2" />);
    const[rCp3p3, setrCp3p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp3p3" />);
    const[rCp4p1, setrCp4p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp4p1" />);
    const[rCp4p2, setrCp4p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp4p2" />);
    const[rCp4p3, setrCp4p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp4p3" />);
    const[rCp5p1, setrCp5p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp5p1" />);
    const[rCp5p2, setrCp5p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp5p2" />);
    const[rCp5p3, setrCp5p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp5p3" />);
    const[rCp6p1, setrCp6p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp6p1" />);
    const[rCp6p2, setrCp6p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp6p2" />);
    const[rCp6p3, setrCp6p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rCp6p3" />);
    const[rDp1p1, setrDp1p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp1p1" />);
    const[rDp1p2, setrDp1p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp1p2" />);
    const[rDp2p1, setrDp2p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp2p1" />);
    const[rDp2p2, setrDp2p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp2p2" />);
    const[rDp3p1, setrDp3p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp3p1" />);
    const[rDp3p2, setrDp3p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp3p2" />);
    const[rDp4p1, setrDp4p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp4p1" />);
    const[rDp4p2, setrDp4p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp4p2" />);
    const[rDp5p1, setrDp5p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp5p1" />);
    const[rDp5p2, setrDp5p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp5p2" />);
    const[rDp6p1, setrDp6p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp6p1" />);
    const[rDp6p2, setrDp6p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rDp6p2" />);
    const[rEp1p1, setrEp1p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp1p1" />);
    const[rEp1p2, setrEp1p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp1p2" />);
    const[rEp1p3, setrEp1p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp1p3" />);
    const[rEp2p1, setrEp2p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp2p1" />);
    const[rEp2p2, setrEp2p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp2p2" />);
    const[rEp2p3, setrEp2p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp2p3" />);
    const[rEp3p1, setrEp3p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp3p1" />);
    const[rEp3p2, setrEp3p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp3p2" />);
    const[rEp3p3, setrEp3p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp3p3" />);
    const[rEp4p1, setrEp4p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp4p1" />);
    const[rEp4p2, setrEp4p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp4p2" />);
    const[rEp4p3, setrEp4p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp4p3" />);
    const[rEp5p1, setrEp5p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp5p1" />);
    const[rEp5p2, setrEp5p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp5p2" />);
    const[rEp5p3, setrEp5p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rEp5p3" />);
    const[rFp1p1, setrFp1p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp1p1" />);
    const[rFp1p2, setrFp1p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp1p2" />);
    const[rFp1p3, setrFp1p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp1p3" />);
    const[rFp1p4, setrFp1p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp1p4" />);
    const[rFp1p5, setrFp1p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp1p5" />);
    const[rFp1p6, setrFp1p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp1p6" />);
    const[rFp2p1, setrFp2p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp2p1" />);
    const[rFp2p2, setrFp2p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp2p2" />);
    const[rFp2p3, setrFp2p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp2p3" />);
    const[rFp2p4, setrFp2p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp2p4" />);
    const[rFp2p5, setrFp2p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp2p5" />);
    const[rFp2p6, setrFp2p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp2p6" />);
    const[rFp3p1, setrFp3p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp3p1" />);
    const[rFp3p2, setrFp3p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp3p2" />);
    const[rFp3p3, setrFp3p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp3p3" />);
    const[rFp3p4, setrFp3p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp3p4" />);
    const[rFp3p5, setrFp3p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp3p5" />);
    const[rFp3p6, setrFp3p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp3p6" />);
    const[rFp4p1, setrFp4p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp4p1" />);
    const[rFp4p2, setrFp4p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp4p2" />);
    const[rFp4p3, setrFp4p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp4p3" />);
    const[rFp4p4, setrFp4p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp4p4" />);
    const[rFp4p5, setrFp4p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp4p5" />);
    const[rFp4p6, setrFp4p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rFp4p6" />);
    const[rGp1p1, setrGp1p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp1p1" />);
    const[rGp1p2, setrGp1p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp1p2" />);
    const[rGp1p3, setrGp1p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp1p3" />);
    const[rGp1p4, setrGp1p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp1p4" />);
    const[rGp1p5, setrGp1p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp1p5" />);
    const[rGp1p6, setrGp1p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp1p6" />);
    const[rGp1p7, setrGp1p7] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp1p7" />);
    const[rGp1p8, setrGp1p8] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp1p8" />);
    const[rGp2p1, setrGp2p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp2p1" />);
    const[rGp2p2, setrGp2p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp2p2" />);
    const[rGp2p3, setrGp2p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp2p3" />);
    const[rGp2p4, setrGp2p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp2p4" />);
    const[rGp2p5, setrGp2p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp2p5" />);
    const[rGp2p6, setrGp2p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp2p6" />);
    const[rGp2p7, setrGp2p7] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp2p7" />);
    const[rGp2p8, setrGp2p8] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp2p8" />);
    const[rGp3p1, setrGp3p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp3p1" />);
    const[rGp3p2, setrGp3p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp3p2" />);
    const[rGp3p3, setrGp3p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp3p3" />);
    const[rGp3p4, setrGp3p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp3p4" />);
    const[rGp3p5, setrGp3p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp3p5" />);
    const[rGp3p6, setrGp3p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp3p6" />);
    const[rGp3p7, setrGp3p7] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp3p7" />);
    const[rGp3p8, setrGp3p8] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp3p8" />);
    const[rGp4p1, setrGp4p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp4p1" />);
    const[rGp4p2, setrGp4p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp4p2" />);
    const[rGp4p3, setrGp4p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp4p3" />);
    const[rGp4p4, setrGp4p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp4p4" />);
    const[rGp4p5, setrGp4p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp4p5" />);
    const[rGp4p6, setrGp4p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp4p6" />);
    const[rGp4p7, setrGp4p7] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp4p7" />);
    const[rGp4p8, setrGp4p8] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp4p8" />);
    const[rGp5p1, setrGp5p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp5p1" />);
    const[rGp5p2, setrGp5p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp5p2" />);
    const[rGp5p3, setrGp5p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp5p3" />);
    const[rGp5p4, setrGp5p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp5p4" />);
    const[rGp5p5, setrGp5p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp5p5" />);
    const[rGp5p6, setrGp5p6] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp5p6" />);
    const[rGp5p7, setrGp5p7] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp5p7" />);
    const[rGp5p8, setrGp5p8] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rGp5p8" />);
    const[rHp1p1, setrHp1p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rHp1p1" />);
    const[rHp1p2, setrHp1p2] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rHp1p2" />);
    const[rHp1p3, setrHp1p3] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rHp1p3" />);
    const[rHp1p4, setrHp1p4] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rHp1p4" />);
    const[rHp1p5, setrHp1p5] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rHp1p5" />);
    const[rIp1p1, setrIp1p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rIp1p1" />);
    const[rIp2p1, setrIp2p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rIp2p1" />);
    const[rIp3p1, setrIp3p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rIp3p1" />);
    const[rIp4p1, setrIp4p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rIp4p1" />);
    const[rIp5p1, setrIp5p1] = React.useState(<Slot used={false} levelBox={handleSlot} handleNewPosition={handleNewPosition} positionName="rIp5p1" />);


    
    const buildLevels = (productsList) => {
        return new Promise((resolve, reject) => {
            productsList.forEach((productBatch) => {
                if(productBatch.warehouseDeliveryCheck){
                    const detail = levelDetail.find((el) => el.position === productBatch.position);
                    // console.log(detail);

                    if(!detail || productBatch.position === "floor"){
                        const newDetail = {
                            position: productBatch.position,
                            l1: null,
                            l2: null,
                            l3: null,
                        }

                        if(productBatch.positionLevel && productBatch.positionLevel === "l1"){
                            newDetail.l1 = productBatch;
                        }
                        else if(productBatch.positionLevel && productBatch.positionLevel === "l2"){
                            newDetail.l2 = productBatch;
                        }
                        else if(productBatch.positionLevel && productBatch.positionLevel === "l3"){
                            newDetail.l3 = productBatch;
                        }

                        levelDetail.push(newDetail);
                    }

                    else{
                        if(productBatch.positionLevel && productBatch.positionLevel === "l1"){
                            detail.l1 = productBatch;
                        }
                        else if(productBatch.positionLevel && productBatch.positionLevel === "l2"){
                            detail.l2 = productBatch;
                        }
                        else if(productBatch.positionLevel&& productBatch.positionLevel === "l3"){
                            detail.l3 = productBatch;
                        }
                    }

                    // console.log(levelDetail);
                }

            });

            resolve (levelDetail);
        })
            
        
    };

    
    const initialPositions = async () => {

        const batchList = await getActiveProductBatches(loginUser.jwtToken);

        if(!batchList){
            return;
        }

        const returnedLevels = await buildLevels(batchList);

        levelDetail.forEach((el)=>{

            let isNotNull = false;
            let busyLevels = 0;

            if(el){
                
                if(el.l1 && el.l1.deliveryOrder){
                    isNotNull = true;
                }
                if(el.l2 && el.l2.deliveryOrder){
                    isNotNull = true;
                }
                if(el.l3 && el.l3.deliveryOrder){
                    isNotNull = true;
                }

                if(el.l1){
                    busyLevels++;
                }
                if(el.l2){
                    busyLevels++;
                }
                if(el.l3){
                    busyLevels++;
                }

            }

            if("v1"===el.position){ setV1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)}; 
            if("v2"===el.position){ setV2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v3"===el.position){ setV3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v4"===el.position){ setV4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v5"===el.position){ setV5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v6"===el.position){ setV6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v7"===el.position){ setV7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v8"===el.position){ setV8(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v9"===el.position){ setV9(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v10"===el.position){ setV10(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v11"===el.position){ setV11(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v12"===el.position){ setV12(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v13"===el.position){ setV13(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v14"===el.position){ setV14(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v15"===el.position){ setV15(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v16"===el.position){ setV16(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v17"===el.position){ setV17(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v18"===el.position){ setV18(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v19"===el.position){ setV19(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v20"===el.position){ setV20(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v21"===el.position){ setV21(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v22"===el.position){ setV22(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v23"===el.position){ setV23(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v24"===el.position){ setV24(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v25"===el.position){ setV25(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v26"===el.position){ setV26(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v27"===el.position){ setV27(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v28"===el.position){ setV28(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v29"===el.position){ setV29(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v30"===el.position){ setV30(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v31"===el.position){ setV31(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v32"===el.position){ setV32(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v33"===el.position){ setV33(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v34"===el.position){ setV34(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v35"===el.position){ setV35(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v36"===el.position){ setV36(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v37"===el.position){ setV37(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v38"===el.position){ setV38(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v39"===el.position){ setV39(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v40"===el.position){ setV40(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v41"===el.position){ setV41(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("v42"===el.position){ setV42(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("t1"===el.position){ setT1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t2"===el.position){ setT2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t3"===el.position){ setT3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t4"===el.position){ setT4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t5"===el.position){ setT5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t6"===el.position){ setT6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t7"===el.position){ setT7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t8"===el.position){ setT8(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t9"===el.position){ setT9(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t10"===el.position){ setT10(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t11"===el.position){ setT11(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t12"===el.position){ setT12(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t13"===el.position){ setT13(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t14"===el.position){ setT14(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t15"===el.position){ setT15(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t16"===el.position){ setT16(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t17"===el.position){ setT17(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t18"===el.position){ setT18(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t19"===el.position){ setT19(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            if("t20"===el.position){ setT20(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Rack A
            //Hall (pasillo) 1
            if("rAp1p1"===el.position){ setrAp1p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp1p2"===el.position){ setrAp1p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            

            if("rAp1p3"===el.position){ setrAp1p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};


            if("rAp1p4"===el.position){ setrAp1p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};


            if("rAp1p5"===el.position){ setrAp1p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 2
            if("rAp2p1"===el.position){ setrAp2p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp2p2"===el.position){ setrAp2p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            

            if("rAp2p3"===el.position){ setrAp2p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            
            if("rAp2p4"===el.position){ setrAp2p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            

            if("rAp2p5"===el.position){ setrAp2p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            

            //Hall (pasillo) 3
            if("rAp3p1"===el.position){ setrAp3p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            

            if("rAp3p2"===el.position){ setrAp3p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            

            if("rAp3p3"===el.position){ setrAp3p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};


            if("rAp3p4"===el.position){ setrAp3p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};


            if("rAp3p5"===el.position){ setrAp3p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};


            //Hall (pasillo) 4
            if("rAp4p1"===el.position){ setrAp4p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};


            if("rAp4p2"===el.position){ setrAp4p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp4p3"===el.position){ setrAp4p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};


            if("rAp4p4"===el.position){ setrAp4p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp4p5"===el.position){ setrAp4p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 5
            if("rAp5p1"===el.position){ setrAp5p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp5p2"===el.position){ setrAp5p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp5p3"===el.position){ setrAp5p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp5p4"===el.position){ setrAp5p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp5p5"===el.position){ setrAp5p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 6
            if("rAp6p1"===el.position){ setrAp6p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp6p2"===el.position){ setrAp6p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp6p3"===el.position){ setrAp6p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp6p4"===el.position){ setrAp6p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rAp6p5"===el.position){ setrAp6p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Rack B
            //Hall (pasillo) 1
            if("rBp1p1"===el.position){ setrBp1p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp1p2"===el.position){ setrBp1p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp1p3"===el.position){ setrBp1p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp1p4"===el.position){ setrBp1p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp1p5"===el.position){ setrBp1p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp1p6"===el.position){ setrBp1p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp1p7"===el.position){ setrBp1p7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 2
            if("rBp2p1"===el.position){ setrBp2p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp2p2"===el.position){ setrBp2p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp2p3"===el.position){ setrBp2p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp2p4"===el.position){ setrBp2p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp2p5"===el.position){ setrBp2p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp2p6"===el.position){ setrBp2p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp2p7"===el.position){ setrBp2p7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 3
            if("rBp3p1"===el.position){ setrBp3p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp3p2"===el.position){ setrBp3p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp3p3"===el.position){ setrBp3p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp3p4"===el.position){ setrBp3p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp3p5"===el.position){ setrBp3p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp3p6"===el.position){ setrBp3p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp3p7"===el.position){ setrBp3p7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 4
            if("rBp4p1"===el.position){ setrBp4p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp4p2"===el.position){ setrBp4p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp4p3"===el.position){ setrBp4p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp4p4"===el.position){ setrBp4p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp4p5"===el.position){ setrBp4p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp4p6"===el.position){ setrBp4p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp4p7"===el.position){ setrBp4p7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 5
            if("rBp5p1"===el.position){ setrBp5p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp5p2"===el.position){ setrBp5p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp5p3"===el.position){ setrBp5p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp5p4"===el.position){ setrBp5p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp5p5"===el.position){ setrBp5p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp5p6"===el.position){ setrBp5p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rBp5p7"===el.position){ setrBp5p7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Rack C
            //Hall (pasillo) 1
            if("rCp1p1"===el.position){ setrCp1p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp1p2"===el.position){ setrCp1p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp1p3"===el.position){ setrCp1p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 2
            if("rCp2p1"===el.position){ setrCp2p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp2p2"===el.position){ setrCp2p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp2p3"===el.position){ setrCp2p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 3
            if("rCp3p1"===el.position){ setrCp3p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp3p2"===el.position){ setrCp3p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp3p3"===el.position){ setrCp3p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 4
            if("rCp4p1"===el.position){ setrCp4p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp4p2"===el.position){ setrCp4p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp4p3"===el.position){ setrCp4p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 5
            if("rCp5p1"===el.position){ setrCp5p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp5p2"===el.position){ setrCp5p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp5p3"===el.position){ setrCp5p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 6
            if("rCp6p1"===el.position){ setrCp6p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp6p2"===el.position){ setrCp6p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rCp6p3"===el.position){ setrCp6p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Rack D
            //Hall (pasillo) 1
            if("rDp1p1"===el.position){ setrDp1p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rDp1p2"===el.position){ setrDp1p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 2
            if("rDp2p1"===el.position){ setrDp2p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rDp2p2"===el.position){ setrDp2p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 3
            if("rDp3p1"===el.position){ setrDp3p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rDp3p2"===el.position){ setrDp3p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 4
            if("rDp4p1"===el.position){ setrDp4p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rDp4p2"===el.position){ setrDp4p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 5
            if("rDp5p1"===el.position){ setrDp5p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rDp5p2"===el.position){ setrDp5p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

                //Hall (pasillo) 6
                if("rDp6p1"===el.position){ setrDp6p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

                if("rDp6p2"===el.position){ setrDp6p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Rack E
            //Hall (pasillo) 1
            if("rEp1p1"===el.position){ setrEp1p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rEp1p2"===el.position){ setrEp1p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rEp1p3"===el.position){ setrEp1p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 2
            if("rEp2p1"===el.position){ setrEp2p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rEp2p2"===el.position){ setrEp2p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rEp2p3"===el.position){ setrEp2p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 3
            if("rEp3p1"===el.position){ setrEp3p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rEp3p2"===el.position){ setrEp3p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rEp3p3"===el.position){ setrEp3p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 4
            if("rEp4p1"===el.position){ setrEp4p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rEp4p2"===el.position){ setrEp4p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rEp4p3"===el.position){ setrEp4p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 5
            if("rEp5p1"===el.position){ setrEp5p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rEp5p2"===el.position){ setrEp5p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rEp5p3"===el.position){ setrEp5p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Rack F
            //Hall (pasillo) 1
            if("rFp1p1"===el.position){ setrFp1p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp1p2"===el.position){ setrFp1p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp1p3"===el.position){ setrFp1p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp1p4"===el.position){ setrFp1p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp1p5"===el.position){ setrFp1p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp1p6"===el.position){ setrFp1p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 2
            if("rFp2p1"===el.position){ setrFp2p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp2p2"===el.position){ setrFp2p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp2p3"===el.position){ setrFp2p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp2p4"===el.position){ setrFp2p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp2p5"===el.position){ setrFp2p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp2p6"===el.position){ setrFp2p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 3
            if("rFp3p1"===el.position){ setrFp3p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp3p2"===el.position){ setrFp3p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp3p3"===el.position){ setrFp3p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp3p4"===el.position){ setrFp3p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp3p5"===el.position){ setrFp3p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp3p6"===el.position){ setrFp3p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 4
            if("rFp4p1"===el.position){ setrFp4p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp4p2"===el.position){ setrFp4p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp4p3"===el.position){ setrFp4p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp4p4"===el.position){ setrFp4p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp4p5"===el.position){ setrFp4p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rFp4p6"===el.position){ setrFp4p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

                //Rack G
            //Hall (pasillo) 1
            if("rGp1p1"===el.position){ setrGp1p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp1p2"===el.position){ setrGp1p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp1p3"===el.position){ setrGp1p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp1p4"===el.position){ setrGp1p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp1p5"===el.position){ setrGp1p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp1p6"===el.position){ setrGp1p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp1p7"===el.position){ setrGp1p7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp1p8"===el.position){ setrGp1p8(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 2
            if("rGp2p1"===el.position){ setrGp2p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp2p2"===el.position){ setrGp2p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp2p3"===el.position){ setrGp2p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp2p4"===el.position){ setrGp2p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp2p5"===el.position){ setrGp2p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp2p6"===el.position){ setrGp2p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp2p7"===el.position){ setrGp2p7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp2p8"===el.position){ setrGp2p8(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 3
            if("rGp3p1"===el.position){ setrGp3p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp3p2"===el.position){ setrGp3p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp3p3"===el.position){ setrGp3p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp3p4"===el.position){ setrGp3p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp3p5"===el.position){ setrGp3p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp3p6"===el.position){ setrGp3p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp3p7"===el.position){ setrGp3p7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp3p8"===el.position){ setrGp3p8(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 4
            if("rGp4p1"===el.position){ setrGp4p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp4p2"===el.position){ setrGp4p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp4p3"===el.position){ setrGp4p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp4p4"===el.position){ setrGp4p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp4p5"===el.position){ setrGp4p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp4p6"===el.position){ setrGp4p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp4p7"===el.position){ setrGp4p7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp4p8"===el.position){ setrGp4p8(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 5
            if("rGp5p1"===el.position){ setrGp5p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp5p2"===el.position){ setrGp5p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp5p3"===el.position){ setrGp5p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp5p4"===el.position){ setrGp5p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp5p5"===el.position){ setrGp5p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp5p6"===el.position){ setrGp5p6(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp5p7"===el.position){ setrGp5p7(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rGp5p8"===el.position){ setrGp5p8(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};
            
            //Rack H
            //Hall (pasillo) 1
            if("rHp1p1"===el.position){ setrHp1p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rHp1p2"===el.position){ setrHp1p2(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rHp1p3"===el.position){ setrHp1p3(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rHp1p4"===el.position){ setrHp1p4(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            if("rHp1p5"===el.position){ setrHp1p5(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Rack I
            //Hall (pasillo) 1
            if("rIp1p1"===el.position){ setrIp1p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 2
            if("rIp2p1"===el.position){ setrIp2p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 3
            if("rIp3p1"===el.position){ setrIp3p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 4
            if("rIp4p1"===el.position){ setrIp4p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Hall (pasillo) 5
            if("rIp5p1"===el.position){ setrIp5p1(<Slot used={true} positionName={`${el.position}`} levelBox={handleSlot} productBatch={el} deliveryOrder={isNotNull} busyLevels={busyLevels}/>)};

            //Floor
            if("floor"===el.position){
                if(el.l1){
                    floorBatches.push(el.l1);
                    setFloorCounter((prevCount) => prevCount + 1);
                }
            }
        });


    };

    const[levelsRender, setLevelsRender] = React.useState(false);

    const [levelBox, setLevelBox] = React.useState(false);

    const closeLevelBox = () => {
        setLevelBox(false);
    };

    const [floorBox, setFloorBox] = React.useState(false);

    const handleFloor = () => {
        // setFloorBox(true);
        if(props.location.state && props.location.state.batch){
            positionObject.id = props.location.state.batch.id;
            positionObject.rack="floor";
            positionObject.level = "l1";
            
            registerPosition();

            return;
        }
        else{
            closeFloorBox();
            history.push({
            pathname: "/warehouse/batches-in-floor",
            state: {
                floorBatches:floorBatches,
            }
        });
        }
    };

    const closeFloorBox = () => {
        setFloorBox(false);
    }

    const handleFloorPosition = () => {
        closeFloorBox();
        if(props.location.state && props.location.state.batch){
            positionObject.id = props.location.state.batch.id;
            positionObject.rack="floor";
            positionObject.level = "l1";
            
            registerPosition();
        }
    };

    const BatchesInFloor = () => {
        closeFloorBox();
        history.push({
            pathname: "/warehouse/batches-in-floor",
            state: {
                floorBatches:floorBatches,
            }
        });
    };


    return (
    <Box sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%"}}>
        <Box
            sx={{
                display: "flex",
                position: "relative",
                // flex: 1,
                justifyContent: "center",
                alignItems: "start",
                width: "95%",
                height: "100%",
                textAlign: "center",
            }}
        >
        {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Box
                sx={{
                    display: "flex",
                    position: "relative",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "start",
                    width: "100%",
                    maxWidth: "1400px",
                    height: {xs: "660px", sm: "660px"},
                    maxHeight: {xs: "660px", sm: "660px"},
                    minHeight: {xs: "660px", sm: "660px"},
                    textAlign: "center",
                    marginLeft: {xs: "5%", sm: "5%",}
                }} 
            >
                <Grid container sx={{position: "absolute", top: {xs: "10.5%", sm: "13.8%"}, left: {xs: "1%", sm: "1.5%"}, width: "50%"}}>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>C1</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>C2</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>C3</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>C4</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>C5</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>C6</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{position: "absolute", top: {xs: "18%", sm: "20%"}, left: {xs: "1%", sm: "1.5%"}, width: "50%"}}>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>B1</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>B2</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>B3</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>B4</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>B5</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="column" sx={{position: "absolute", top: {xs: "74.8%", sm: "77.5%"}, left: {xs: "22.5%", sm: "22%"}, width: "2%"}}>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>A6</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.3rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>A5</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.3rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>A4</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.35rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>A3</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.4rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>A2</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.4rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>A1</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="column" sx={{position: "absolute", top: {xs: "79%", sm: "82%"}, left: {xs: "35%", sm: "28%"}, width: "2%"}}>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.3rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>I5</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.3rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>I4</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.35rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>I3</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.4rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>I2</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.4rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>I1</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="column" sx={{position: "absolute", top: {xs: "24%", sm: "27%"}, left: {xs: "33%", sm: "35%"},  width: "2%"}}>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.3rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>E1</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.3rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>E2</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.35rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>E3</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.4rem", sm: "0.2rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>E4</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.4rem", sm: "0.2rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>E5</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="column" sx={{position: "absolute", top: {xs: "43.5%", sm: "46.6%"}, left: {xs: "33%", sm: "35%"}, width: "2%"}}>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.3rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>G1</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.3rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>G2</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.35rem", sm: "0.1rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>G3</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.4rem", sm: "0.2rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>G4</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start", marginTop: {xs: "0.4rem", sm: "0.2rem"}}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>G5</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{position: "absolute", top: {xs: "7.5%", sm: "10.5%"}, left: {xs: "46%", sm: "47%"}, width: "50%"}}>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>D1</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>D2</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>D3</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>D4</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>D5</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>D6</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{position: "absolute", top: {xs: "18%", sm: "20%"}, left: {xs: "55%", sm: "56%"}, width: "50%"}}>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>F4</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>F3</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>F2</Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>F1</Typography>
                    </Grid>
                    
                </Grid>
                <Grid container sx={{position: "absolute", top: {xs: "66.3%", sm: "70%"}, left: {xs: "37.5%", sm: "39.5%"}, width: {xs: "24.4%", sm: "23.2%"}}}>
                    <Grid item xs={2} sm={2} md={2} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>H1</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>H2</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>H3</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>H4</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} sx= {{textAlign: "start"}}> 
                        <Typography sx={{color: "blue", fontSize: {xs: "0.6rem", sm: "0.8rem"}}}>H5</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{position: "absolute", top: {xs: "10%", sm: "13%"}, left: {xs: "78%", sm: "75%"}, width: "20%"}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={handleFloor} variant="contained" sx={{backgroundColor: "#e10000", width: {xs: "1.6rem", sm: "7rem"}, height: {xs: "1.8rem", sm: "100%"}, fontSize: {xs: "0.8rem", sm: "1.1rem"}}}>
                            Piso
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center", marginTop: {xs: "0", sm: "0.8rem"}}}>
                        <Typography sx={{color: "black"}}>{floorCounter}</Typography>
                    </Grid>
                </Grid>

                <Grid container sx={{display: "flex", justifyContent: {xs: "center", sm: "center"}, alignItems: "center", width: "100%", height: "98%", marginTop: "2%"}}>
                    <Grid item xs={6} sm={6} md={6} sx={{height: "100%"}}>
                        <Grid container sx={{display: "flex", justifyContent: {xs: "center", sm: "center"}, alignItems: "start", width: "100%", height: "100%"}}>
                            <Grid item xs={1} sm={1} md={1}> {/* 1 */}
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {rCp1p3}
                                    </Grid>
                                    <Grid item> {/* f1 */}
                                        {rCp1p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rCp1p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>C1</Typography>
                                    </Grid> */}
                                    {/* <Grid item sx={{marginTop: "1.63rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>B1</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: "4rem"}}> {/* f4 */}
                                        {rBp5p1}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rBp5p2}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rBp5p3}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rBp5p4}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rBp5p5}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rBp5p6}
                                    </Grid>
                                    <Grid item> {/* f10 */}
                                        {rBp5p7}
                                    </Grid>
                                    <Grid item sx={{marginTop: "2.95rem"}}> {/* f14 */}
                                        {v33}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v38}
                                    </Grid>
                                    <Grid item> {/* f16 */}
                                        {v6}
                                    </Grid>
                                    <Grid item> {/* f17 */}
                                        {v1}
                                    </Grid>
                                    <Grid item sx={{marginTop: "4.5rem"}}> {/* f16 */}
                                        {rAp6p5}
                                    </Grid>
                                    <Grid item> {/* f17 */}
                                        {rAp5p5}
                                    </Grid>
                                    <Grid item> {/* f18 */}
                                        {rAp4p5}
                                    </Grid>
                                    <Grid item> {/* f19 */}
                                        {rAp3p5}
                                    </Grid>
                                    <Grid item> {/* f20 */}
                                        {rAp2p5}
                                    </Grid>
                                    <Grid item> {/* f21 */}
                                        {rAp1p5}
                                    </Grid>
                                    <Grid item> {/* f22 */}
                                        {v30}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 2 */}
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {rCp2p3}
                                    </Grid>
                                    <Grid item> {/* f1 */}
                                        {rCp2p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rCp2p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>C2</Typography>
                                    </Grid> */}
                                    {/* <Grid item sx={{marginTop: "1.63rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>B2</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: "4rem"}}> {/* f4 */}
                                        {rBp4p1}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rBp4p2}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rBp4p3}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rBp4p4}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rBp4p5}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rBp4p6}
                                    </Grid>
                                    <Grid item> {/* f10 */}
                                        {rBp4p7}
                                    </Grid>
                                    <Grid item sx={{marginTop: "2.95rem"}}> {/* f14 */}
                                        {v34}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v39}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v7}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v2}
                                    </Grid>
                                    <Grid item sx={{marginTop: "4.5rem"}}> {/* f16 */}
                                        {rAp6p4}
                                    </Grid>
                                    <Grid item> {/* f17 */}
                                        {rAp5p4}
                                    </Grid>
                                    <Grid item> {/* f18 */}
                                        {rAp4p4}
                                    </Grid>
                                    <Grid item> {/* f19 */}
                                        {rAp3p4}
                                    </Grid>
                                    <Grid item> {/* f20 */}
                                        {rAp2p4}
                                    </Grid>
                                    <Grid item> {/* f21 */}
                                        {rAp1p4}
                                    </Grid>
                                    <Grid item> {/* f22 */}
                                        {v31}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 3*/}
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {rCp3p3}
                                    </Grid>
                                    <Grid item> {/* f1 */}
                                        {rCp3p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rCp3p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>C3</Typography>
                                    </Grid> */}
                                    {/* <Grid item sx={{marginTop: "1.63rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>B3</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: "4rem"}}> {/* f4 */}
                                        {rBp3p1}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rBp3p2}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rBp3p3}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rBp3p4}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rBp3p5}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rBp3p6}
                                    </Grid>
                                    <Grid item> {/* f10 */}
                                        {rBp3p7}
                                    </Grid>
                                    <Grid item sx={{marginTop: "2.95rem"}}> {/* f14 */}
                                        {v35}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v40}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v8}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v3}
                                    </Grid>
                                    <Grid item sx={{marginTop: "4.5rem"}}> {/* f16 */}
                                        {rAp6p3}
                                    </Grid>
                                    <Grid item> {/* f17 */}
                                        {rAp5p3}
                                    </Grid>
                                    <Grid item> {/* f18 */}
                                        {rAp4p3}
                                    </Grid>
                                    <Grid item> {/* f19 */}
                                        {rAp3p3}
                                    </Grid>
                                    <Grid item> {/* f20 */}
                                        {rAp2p3}
                                    </Grid>
                                    <Grid item> {/* f21 */}
                                        {rAp1p3}
                                    </Grid>
                                    <Grid item> {/* f22 */}
                                        {v32}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 4 */}
                                <Grid container direction="column"> 
                                    <Grid item> {/* f1 */}
                                        {rCp4p3}
                                    </Grid>
                                    <Grid item> {/* f1 */}
                                        {rCp4p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rCp4p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>C4</Typography>
                                    </Grid> */}
                                    {/* <Grid item sx={{marginTop: "1.63rem"}}>
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>B4</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: "4rem"}}> {/* f4 */}
                                        {rBp2p1}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rBp2p2}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rBp2p3}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rBp2p4}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rBp2p5}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rBp2p6}
                                    </Grid>
                                    <Grid item> {/* f10 */}
                                        {rBp2p7}
                                    </Grid>
                                    <Grid item sx={{marginTop: "2.95rem"}}> {/* f14 */}
                                        {v36}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v41}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v9}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v4}
                                    </Grid>
                                    <Grid item sx={{marginTop: "4.5rem"}}> {/* f16 */}
                                        {rAp6p2}
                                    </Grid>
                                    <Grid item> {/* f17 */}
                                        {rAp5p2}
                                    </Grid>
                                    <Grid item> {/* f18 */}
                                        {rAp4p2}
                                    </Grid>
                                    <Grid item> {/* f19 */}
                                        {rAp3p2}
                                    </Grid>
                                    <Grid item> {/* f20 */}
                                        {rAp2p2}
                                    </Grid>
                                    <Grid item> {/* f21 */}
                                        {rAp1p2}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 5 */}
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {rCp5p3}
                                    </Grid>
                                    <Grid item> {/* f1 */}
                                        {rCp5p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rCp5p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>C5</Typography>
                                    </Grid> */}
                                    {/* <Grid item sx={{marginTop: "1.63rem"}}>
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>B5</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: "4rem"}}> {/* f4 */}
                                        {rBp1p1}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rBp1p2}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rBp1p3}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rBp1p4}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rBp1p5}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rBp1p6}
                                    </Grid>
                                    <Grid item> {/* f10 */}
                                        {rBp1p7}
                                    </Grid>
                                    <Grid item sx={{marginTop: "2.95rem"}}> {/* f14 */}
                                        {v37}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v42}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v10}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {v5}
                                    </Grid>
                                    <Grid item sx={{marginTop: "4.5rem"}}> {/* f16 */}
                                        {rAp6p1}
                                    </Grid>
                                    <Grid item> {/* f17 */}
                                        {rAp5p1}
                                    </Grid>
                                    <Grid item> {/* f18 */}
                                        {rAp4p1}
                                    </Grid>
                                    <Grid item> {/* f19 */}
                                        {rAp3p1}
                                    </Grid>
                                    <Grid item> {/* f20 */}
                                        {rAp2p1}
                                    </Grid>
                                    <Grid item> {/* f21 */}
                                        {rAp1p1}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 6 */}
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {rCp6p3}
                                    </Grid>
                                    <Grid item> {/* f1 */}
                                        {rCp6p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rCp6p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>C6</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: "4rem"}}> {/* f4 */}
                                        {v23}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {v24}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {v25}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {v26}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {v27}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {v28}
                                    </Grid>
                                    <Grid item> {/* f10 */}
                                        {v29}
                                    </Grid>
                                    {/* <Grid item sx={{marginTop: "12.7rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>A6</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>A5</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>A4</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>A3</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>A2</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}>
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>A1</Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 7 */}
                                <Grid container direction="column">
                                    {/* <Grid item sx={{marginTop: "30.4rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>I5</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>I4</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>I3</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>I2</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>I1</Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 8 */}
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {v13}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {v12}
                                    </Grid>
                                    <Grid item> {/* f3 */}
                                        {v11}
                                    </Grid>
                                    <Grid item sx={{marginTop: "28rem"}}> {/* f18 */}
                                        {rIp5p1}
                                    </Grid>
                                    <Grid item> {/* f19 */}
                                        {rIp4p1}
                                    </Grid>
                                    <Grid item> {/* f20 */}
                                        {rIp3p1}
                                    </Grid>
                                    <Grid item> {/* f21 */}
                                        {rIp2p1}
                                    </Grid>
                                    <Grid item> {/* f22 */}
                                        {rIp1p1}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} sx={{position: "relative"}}> {/* 9 */}
                                <Grid sx={{position: "absolute", top: {xs:"4rem", sm: "4rem"}, left: {xs: "-2rem", sm: "-3rem"}}}>
                                    <Grid item>
                                        <TurnRightIcon sx={{fontSize: {xs:"4.5rem", sm: "6rem"}, color: "#e10000"}}/>
                                    </Grid>
                                </Grid>
                                <Grid sx={{position: "absolute", top: {xs: "26.5rem", sm: "25rem"}, left: {xs: "-2.2rem", sm: "-3rem"}}}>
                                    <Grid item>
                                        <TurnRightIcon sx={{fontSize: {xs:"4.5rem", sm: "6rem"}, color: "#e10000", transform: "rotate(270deg)"}}/>
                                    </Grid>
                                </Grid>
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {v16}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {v15}
                                    </Grid>
                                    <Grid item> {/* f3 */}
                                        {v14}
                                    </Grid>
                                    {/* <Grid item sx={{marginTop: "5.4rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>E1</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>E2</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>E3</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>E4</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>E5</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.4rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>G1</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>G2</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>G3</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>G4</Typography>
                                    </Grid>
                                    <Grid item sx={{marginTop: "0.2rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>G5</Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 10 */}
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {v19}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {v18}
                                    </Grid>
                                    <Grid item> {/* f3 */}
                                        {v17}
                                    </Grid>
                                    <Grid item sx={{marginTop: {xs: "4.04rem", sm: "4.05rem"}}}> {/* f4 */}
                                        {v20}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rEp1p1}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rEp2p1}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rEp3p1}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rEp4p1}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rEp5p1}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.5rem"}}> {/* f11 */}
                                        {rGp1p1}
                                    </Grid>
                                    <Grid item> {/* f12 */}
                                        {rGp2p1}
                                    </Grid>
                                    <Grid item> {/* f13 */}
                                        {rGp3p1}
                                    </Grid>
                                    <Grid item> {/* f14 */}
                                        {rGp4p1}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {rGp5p1}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.5rem"}}> {/* f16 */}
                                        {rHp1p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>H1</Typography>
                                    </Grid> */}
                                </Grid> 
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 11 */}
                                <Grid container direction="column">
                                    <Grid item sx={{marginTop: {xs: "7.982rem", sm: "8rem"}}}> {/* f4 */}
                                        {v21}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rEp1p2}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rEp2p2}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rEp3p2}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rEp4p2}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rEp5p2}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.5rem"}}> {/* f11 */}
                                        {rGp1p2}
                                    </Grid>
                                    <Grid item> {/* f12 */}
                                        {rGp2p2}
                                    </Grid>
                                    <Grid item> {/* f13 */}
                                        {rGp3p2}
                                    </Grid>
                                    <Grid item> {/* f14 */}
                                        {rGp4p2}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {rGp5p2}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.5rem"}}> {/* f16 */}
                                        {rHp1p2}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>H2</Typography>
                                    </Grid> */}
                                </Grid> 
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 12 */}
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {rDp1p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rDp1p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>D1</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: "5.35rem"}}> {/* f4 */}
                                        {v22}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rEp1p3}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rEp2p3}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rEp3p3}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rEp4p3}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rEp5p3}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.5rem"}}> {/* f11 */}
                                        {rGp1p3}
                                    </Grid>
                                    <Grid item> {/* f12 */}
                                        {rGp2p3}
                                    </Grid>
                                    <Grid item> {/* f13 */}
                                        {rGp3p3}
                                    </Grid>
                                    <Grid item> {/* f14 */}
                                        {rGp4p3}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {rGp5p3}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.5rem"}}> {/* f16 */}
                                        {rHp1p3}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>H3</Typography>
                                    </Grid> */}
                                </Grid> 
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} sx={{height: "100%"}}>
                        <Grid container sx={{display: "flex", justifyContent: {xs: "start", sm: "start"}, alignItems: "start", width: "100%", height: "100%"}}>
                            <Grid item xs={1} sm={1} md={1} sx={{position: "relative"}}> {/* 1 */}
                                <Grid sx={{position: "absolute", top: "36rem", left:{xs: "-2rem", sm: "-3.5rem"}}}>
                                    <Grid item>
                                        <Typography sx={{color: "black", fontWeight: "bold", fontSize: {xs: "0.7rem", sm: "1.5rem"}}}>ENTRADA</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {rDp2p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rDp2p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>D2</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: {xs: "14.72rem", sm: "14.8rem"}}}> {/* f11 */}
                                        {rGp1p4}
                                    </Grid>
                                    <Grid item> {/* f12 */}
                                        {rGp2p4}
                                    </Grid>
                                    <Grid item> {/* f13 */}
                                        {rGp3p4}
                                    </Grid>
                                    <Grid item> {/* f14 */}
                                        {rGp4p4}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {rGp5p4}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.5rem"}}> {/* f16 */}
                                        {rHp1p4}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>H4</Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} sx={{position: "relative"}}> {/* 2 */}
                                <Grid sx={{position: "absolute", top: "32rem", left: {xs: "-1.5rem", sm: "-1rem"}}}>
                                    <Grid item>
                                        <StraightIcon sx={{fontSize: {xs: "4.5rem", sm: "6rem"}, color: "#e10000"}}/>
                                    </Grid>
                                </Grid>
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {rDp3p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rDp3p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>D3</Typography>
                                    </Grid> */}
                                    {/* <Grid item sx={{marginTop: "2.95rem"}}>
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>F4</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: "5.3rem"}}> {/* f4 */}
                                        {rFp4p1}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rFp4p2}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rFp4p3}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rFp4p4}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rFp4p5}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rFp4p6}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.53rem"}}> {/* f11 */}
                                        {rGp1p5}
                                    </Grid>
                                    <Grid item> {/* f12 */}
                                        {rGp2p5}
                                    </Grid>
                                    <Grid item> {/* f13 */}
                                        {rGp3p5}
                                    </Grid>
                                    <Grid item> {/* f14 */}
                                        {rGp4p5}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {rGp5p5}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.52rem"}}> {/* f16 */}
                                        {rHp1p5}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>H5</Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 3 */}
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {rDp4p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rDp4p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>D4</Typography>
                                    </Grid> */}
                                    {/* <Grid item sx={{marginTop: "2.95rem"}}>
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>F3</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: "5.3rem"}}> {/* f4 */}
                                        {rFp3p1}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rFp3p2}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rFp3p3}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rFp3p4}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rFp3p5}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rFp3p6}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.53rem"}}> {/* f11 */}
                                        {rGp1p6}
                                    </Grid>
                                    <Grid item> {/* f12 */}
                                        {rGp2p6}
                                    </Grid>
                                    <Grid item> {/* f13 */}
                                        {rGp3p6}
                                    </Grid>
                                    <Grid item> {/* f14 */}
                                        {rGp4p6}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {rGp5p6}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 4 */}
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {rDp5p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rDp5p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>D5</Typography>
                                    </Grid> */}
                                    {/* <Grid item sx={{marginTop: "2.95rem"}}> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>F2</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: "5.3rem"}}> {/* f4 */}
                                        {rFp2p1}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rFp2p2}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rFp2p3}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rFp2p4}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rFp2p5}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rFp2p6}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.53rem"}}> {/* f11 */}
                                        {rGp1p7}
                                    </Grid>
                                    <Grid item> {/* f12 */}
                                        {rGp2p7}
                                    </Grid>
                                    <Grid item> {/* f13 */}
                                        {rGp3p7}
                                    </Grid>
                                    <Grid item> {/* f14 */}
                                        {rGp4p7}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {rGp5p7}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.5rem"}}> {/* f16 */}
                                        {t1}
                                    </Grid>
                                    <Grid item> {/* f17 */}
                                        {t2}
                                    </Grid>
                                    <Grid item> {/* f18 */}
                                        {t3}
                                    </Grid>
                                    <Grid item> {/* f19 */}
                                        {t4}
                                    </Grid>
                                    <Grid item> {/* f20 */}
                                        {t5}
                                    </Grid>
                                    <Grid item> {/* f21 */}
                                        {t6}
                                    </Grid>
                                    <Grid item> {/* f22 */}
                                        {t7}
                                    </Grid>
                                    <Grid item> {/* f23 */}
                                        {t8}
                                    </Grid>
                                    <Grid item> {/* f24 */}
                                        {t9}
                                    </Grid>
                                    <Grid item> {/* f25 */}
                                        {t10}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}> {/* 5 */}
                                <Grid container direction="column">
                                    <Grid item> {/* f1 */}
                                        {rDp6p2}
                                    </Grid>
                                    <Grid item> {/* f2 */}
                                        {rDp6p1}
                                    </Grid>
                                    {/* <Grid item> 
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>D6</Typography>
                                    </Grid> */}
                                    {/* <Grid item sx={{marginTop: "2.95rem"}}>
                                        <Typography sx={{color: "blue", fontSize: "0.8rem"}}>F1</Typography>
                                    </Grid> */}
                                    <Grid item sx={{marginTop: "5.3rem"}}> {/* f4 */}
                                        {rFp1p1}
                                    </Grid>
                                    <Grid item> {/* f5 */}
                                        {rFp1p2}
                                    </Grid>
                                    <Grid item> {/* f6 */}
                                        {rFp1p3}
                                    </Grid>
                                    <Grid item> {/* f7 */}
                                        {rFp1p4}
                                    </Grid>
                                    <Grid item> {/* f8 */}
                                        {rFp1p5}
                                    </Grid>
                                    <Grid item> {/* f9 */}
                                        {rFp1p6}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.53rem"}}> {/* f11 */}
                                        {rGp1p8}
                                    </Grid>
                                    <Grid item> {/* f12 */}
                                        {rGp2p8}
                                    </Grid>
                                    <Grid item> {/* f13 */}
                                        {rGp3p8}
                                    </Grid>
                                    <Grid item> {/* f14 */}
                                        {rGp4p8}
                                    </Grid>
                                    <Grid item> {/* f15 */}
                                        {rGp5p8}
                                    </Grid>
                                    <Grid item sx={{marginTop: "1.5rem"}}> {/* f16 */}
                                        {t11}
                                    </Grid>
                                    <Grid item> {/* f17 */}
                                        {t12}
                                    </Grid>
                                    <Grid item> {/* f18 */}
                                        {t13}
                                    </Grid>
                                    <Grid item> {/* f19 */}
                                        {t14}
                                    </Grid>
                                    <Grid item> {/* f20 */}
                                        {t15}
                                    </Grid>
                                    <Grid item> {/* f21 */}
                                        {t16}
                                    </Grid>
                                    <Grid item> {/* f22 */}
                                        {t17}
                                    </Grid>
                                    <Grid item> {/* f23 */}
                                        {t18}
                                    </Grid>
                                    <Grid item> {/* f24 */}
                                        {t19}
                                    </Grid>
                                    <Grid item> {/* f25 */}
                                        {t20}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                
                            </Grid>
                            <Grid item xs={1} sm={1} md={1}>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Box>
        }
            <Dialog open={levelBox} onClose={closeLevelBox}
                sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "40%"},
                                // height: "20rem",
                            },
                        },
                    }}
            >
                <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                        <Typography variant="h4">{"Nivel Estiba"}</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Grid item xs={12} sm={12} md={12}>
                        {levelsRender}   
                    </Grid>              
                </Grid>
                <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2rem", marginBottom: "1rem"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button onClick={closeLevelBox} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Atrás
                        </Button>  
                    </Grid>              
                </Grid>
            </Dialog>

            <Dialog open={floorBox} onClose={closeFloorBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "40%", md: "30%"},
                                // height: "20%"
                            },
                        },
                    }}
                >
                    <List sx={{width: "100%",}}>
                        <ListItem disablePadding divider sx={{marginBottom: "0.2rem", marginTop: "0.5rem"}}>
                            <ListItemButton  onClick={handleFloorPosition}>
                                <Typography variant="h6">Descargar en piso</Typography>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{marginBottom: "0.2rem", marginTop: "0.5rem"}}>
                            <ListItemButton  onClick={BatchesInFloor}>
                                <Typography variant="h6">Estibas en piso</Typography>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Dialog>
        </Box>
    </Box>
    )
}
