// const RippleAPI = require("ripple-lib").RippleAPI;

// const api = new RippleAPI({
//   server: "wss://s.altnet.rippletest.net:51233", // Public rippled server hosted by Ripple, Inc.
// });

const api = "";

// const wupoServerUri = {
//   mainnet: "http://wupoapi.ngrok.io",
//   testnet: "http://wupoback.ngrok.io",
//   // devnet: "https://wupoback.ngrok.io", //Changed for testing purposes
//   // devnet: "https://localhost:8080",
//   // devnet: "https://wupotestmiddle.ngrok.io",
//   // devnet: "http://54.166.234.69:5000"
//   devnet: "https://awseb-e-p-awsebloa-m3ljpwrvikxh.wupealo.com"

// }

const wupoServerUri = {
  devnet: process.env.REACT_APP_BACK_URI,
}


const stockOrderSymbol = [
  {
    currency: "APL",
    stockSymbol: "AAPL"
  },
  {
    currency: "GOG",
    stockSymbol: "GOOG"
  },
  {
    currency: "FCB",
    stockSymbol: "FB"
  },
  {
    currency: "AMZ",
    stockSymbol: "AMZN"
  },
  {
    currency: "TSL",
    stockSymbol: "TSLA"
  },
  {
    currency: "GLD",
    stockSymbol: "GOLD"
  },
]

const exchangeCurrencies = [
  {
    currency: "USD",
    exchangeCurrency: "USDT",
  },
  {
    currency: "EUR",
    exchangeCurrency: "EUR",
  },
  {
    currency: "BTC",
    exchangeCurrency: "BTC",
  },
  {
    currency: "ETH",
    exchangeCurrency: "ETH",
  }
];

const retailerTokens = [
  {
    label: "Rappi",
    asset: "RPI",
    value: "COP",
  },
  {
    label: "Merqueo",
    asset: "MQO",
    value: "COP",
  },
  {
    label: "Laika",
    asset: "LKA",
    value: "COP",
  },
  {
    label: "Uber",
    asset: "UBR",
    value: "COP",
  },
  {
    label: "Gastronomy Market",
    asset: "GMK",
    value: "COP",
  },
  {
    label: "Carulla",
    asset: "CAL",
    value: "COP",
  },
  {
    label: "WUPOS",
    asset: "WUP",
    value: "COP",
  }

];

const retailersTokensReferenceCurrency = "COP"

const retailOperation ={
  retailLoginEnable: process.env.REACT_APP_ENABLE_RETAIL_LOGIN,
  retailerJwtEndpoint: process.env.REACT_APP_RETAILER_JWT_ENDPOINT
};

// const creditAsset = "WUP" //currently WUPOS, but exploring if other assets loans make sense.
const creditAsset = "COP";

const creditTerm = {
  day: "day",
  week: "week",
  month: "month",
};

const bankAccountTypes = [
  {code: 7, type: "Cuenta de Ahorros"},
  {code: 1, type: "Cuenta Corriente"},
  {code: 9, type: "Depósito Electrónico"},
]

const bankCodes = [

  {bank: "ITAU", code: "1014"},
  {bank: "BANCOLDEX S.A.S", code: "1031"},
  {bank: "BANCO AGRARIO", code: "1040"},
  {bank: "BANCO MUNDO MUJER", code: "1047"},
  {bank: "BANCO W S.A.", code: "1053"},
  {bank: "BANCO DE LAS MICROFINANZAS - BANCAMIA S.A.", code: "1059"},
  {bank: "BANCO PICHINCHA", code: "1060"},
  {bank: "BANCOOMEVA", code: "1061"},
  {bank: "BANCO FALABELLA S.A.", code: "1062"},
  {bank: "BANCO FINANDINA S.A.", code: "1063"},
  {bank: "BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A", code: "1065"},
  {bank: "BANCO COOPERATIVO COOPCENTRAL", code: "1066"},
  {bank: "MIBANCO S.A.", code: "1067"},
  {bank: "BANCO SERFINANZA S.A", code: "1069"},
  {bank: "BANCO J.P. MORGAN COLOMBIA S.A.", code: "1071"},
  {bank: "ASOPAGOS S.A.S", code: "1086"},
  {bank: "FINANCIERA JURISCOOP S.A. COMPANIA DE FINANCIAMIENTO", code: "1121"},
  {bank: "RAPPIPAY DAVIPLATA", code: "1151"},
  {bank: "COOPERATIVA FINANCIERA DE ANTIOQUIA", code: "1283"},
  {bank: "COOTRAFA COOPERATIVA FINANCIERA", code: "1289"},
  {bank: "COOFINEP COOPERATIVA FINANCIERA", code: "1291"},
  {bank: "CONFIAR COOPERATIVA FINANCIERA", code: "1292"},
  {bank: "BANCO UNION S.A", code: "1303"},
  {bank: "COLTEFINANCIERA S.A", code: "1370"},
  {bank: "NEQUI", code: "1507"},
  {bank: "DAVIPLATA", code: "1551"},
  {bank: "BANCO CREDIFINANCIERA SA.", code: "1558"},
  {bank: "IRIS", code: "1637"},
  {bank: "JFK COOPERATIVA FINANCIERA", code: "1286"},
  {bank: "MOVII", code: "1801"},
  {bank: "DING TECNIPAGOS SA", code: "1802"},
  {bank: "UALA", code: "1804"},
  {bank: "BANCO BTG PACTUAL", code: "1805"},
  {bank: "LULO BANK S.A.", code: "1070"},
  {bank: "RAPPIPAY", code: "1811"},
  {bank: "BANCO DE BOGOTA", code: "5600010"},
  {bank: "BANCO POPULAR", code: "5600023"},
  {bank: "ITAU ANTES CORPBANCA", code: "5600065"},
  {bank: "BANCOLOMBIA", code: "5600078"},
  {bank: "CITIBANK", code: "5600094"},
  {bank: "BANCO GNB SUDAMERIS", code: "5600120"},
  {bank: "BBVA COLOMBIA", code: "5600133"},
  {bank: "SCOTIABANK COLPATRIA S.A", code: "5600191"},
  {bank: "BANCO DE OCCIDENTE", code: "5600230"},
  {bank: "BANCO CAJA SOCIAL BCSC SA", code: "5600829"},
  {bank: "BANCO DAVIVIENDA SA", code: "5895142"},
  {bank: "BANCO AV VILLAS", code: "6013677"},
]

const municipalities = [

  {municipality: "EL ENCANTO", code: "91263"},
  {municipality: "LA CHORRERA", code: "91405"},
  {municipality: "LA PEDRERA", code: "91407"},
  {municipality: "LA VICTORIA", code: "91430"},
  {municipality: "LETICIA", code: "91001"},
  {municipality: "MIRITI - PARANA", code: "91460"},
  {municipality: "PUERTO ALEGRIA", code: "91530"},
  {municipality: "PUERTO ARICA", code: "91536"},
  {municipality: "PUERTO NARINO", code: "91540"},
  {municipality: "PUERTO SANTANDER", code: "91669"},
  {municipality: "TARAPACA", code: "91798"},
  {municipality: "CACERES", code: "05120"},
  {municipality: "CAUCASIA", code: "05154"},
  {municipality: "EL BAGRE", code: "05250"},
  {municipality: "NECHI", code: "05495"},
  {municipality: "TARAZA", code: "05790"},
  {municipality: "ZARAGOZA", code: "05895"},
  {municipality: "CARACOLI", code: "05142"},
  {municipality: "MACEO", code: "05425"},
  {municipality: "PUERTO BERRiO", code: "05579"},
  {municipality: "PUERTO NARE", code: "05585"},
  {municipality: "PUERTO TRIUNFO", code: "05591"},
  {municipality: "YONDO", code: "05893"},
  {municipality: "AMALFI", code: "05031"},
  {municipality: "ANORI", code: "05040"},
  {municipality: "CISNEROS", code: "05190"},
  {municipality: "REMEDIOS", code: "05604"},
  {municipality: "SAN ROQUE", code: "05670"},
  {municipality: "SANTO DOMINGO", code: "05690"},
  {municipality: "SEGOVIA", code: "05736"},
  {municipality: "VEGACHI", code: "05858"},
  {municipality: "YALI", code: "05885"},
  {municipality: "YOLOMBO", code: "05890"},
  {municipality: "ANGOSTURA", code: "05038"},
  {municipality: "BELMIRA", code: "05086"},
  {municipality: "BRICENO", code: "05107"},
  {municipality: "CAMPAMENTO", code: "05134"},
  {municipality: "CAROLINA", code: "05150"},
  {municipality: "DON MATiAS", code: "05237"},
  {municipality: "ENTRERRIOS", code: "05264"},
  {municipality: "GOMEZ PLATA", code: "05310"},
  {municipality: "GUADALUPE", code: "05315"},
  {municipality: "ITUANGO", code: "05361"},
  {municipality: "SAN ANDRES", code: "05647"},
  {municipality: "SAN JOSE DE LA MONTANA", code: "05658"},
  {municipality: "SAN PEDRO", code: "05664"},
  {municipality: "SANTA ROSA de osos", code: "05686"},
  {municipality: "TOLEDO", code: "05819"},
  {municipality: "VALDIVIA", code: "05854"},
  {municipality: "YARUMAL", code: "05887"},
  {municipality: "ABRIAQUI", code: "05004"},
  {municipality: "ANZA", code: "05044"},
  {municipality: "ARMENIA", code: "05059"},
  {municipality: "BURITICA", code: "05113"},
  {municipality: "CANASGORDAS", code: "05138"},
  {municipality: "DABEIBA", code: "05234"},
  {municipality: "EBEJICO", code: "05240"},
  {municipality: "FRONTINO", code: "05284"},
  {municipality: "GIRALDO", code: "05306"},
  {municipality: "HELICONIA", code: "05347"},
  {municipality: "LIBORINA", code: "05411"},
  {municipality: "OLAYA", code: "05501"},
  {municipality: "PEQUE", code: "05543"},
  {municipality: "SABANALARGA", code: "05628"},
  {municipality: "SAN JERONIMO", code: "05656"},
  {municipality: "SANTAFE DE ANTIOQUIA", code: "05042"},
  {municipality: "SOPETRaN", code: "05761"},
  {municipality: "URAMITA", code: "05842"},
  {municipality: "ABEJORRAL", code: "05002"},
  {municipality: "ALEJANDRIA", code: "05021"},
  {municipality: "ARGELIA", code: "05055"},
  {municipality: "CARMEN DE VIBORAL", code: "05148"},
  {municipality: "COCORNA", code: "05197"},
  {municipality: "CONCEPCION", code: "05206"},
  {municipality: "GRANADA", code: "05313"},
  {municipality: "GUARNE", code: "05318"},
  {municipality: "GUATAPE", code: "05321"},
  {municipality: "LA CEJA", code: "05376"},
  {municipality: "LA UNION", code: "05400"},
  {municipality: "MARINILLA", code: "05440"},
  {municipality: "NARINO", code: "05483"},
  {municipality: "PENOL", code: "05541"},
  {municipality: "RETIRO", code: "05607"},
  {municipality: "RIONEGRO", code: "05615"},
  {municipality: "SAN CARLOS", code: "05649"},
  {municipality: "SAN FRANCISCO", code: "05652"},
  {municipality: "SAN LUIS", code: "05660"},
  {municipality: "SAN RAFAEL", code: "05667"},
  {municipality: "SAN VICENTE", code: "05674"},
  {municipality: "SANTUARIO", code: "05697"},
  {municipality: "SONSON", code: "05756"},
  {municipality: "AMAGa", code: "05030"},
  {municipality: "ANDES", code: "05034"},
  {municipality: "ANGELOPOLIS", code: "05036"},
  {municipality: "BETANIA", code: "05091"},
  {municipality: "BETULIA", code: "05093"},
  {municipality: "CAICEDO", code: "05125"},
  {municipality: "CARAMANTA", code: "05145"},
  {municipality: "CIUDAD BOLIVAR", code: "05101"},
  {municipality: "CONCORDIA", code: "05209"},
  {municipality: "FREDONIA", code: "05282"},
  {municipality: "HISPANIA", code: "05353"},
  {municipality: "JARDIN", code: "05364"},
  {municipality: "JERICO", code: "05368"},
  {municipality: "LA PINTADA", code: "05390"},
  {municipality: "MONTEBELLO", code: "05467"},
  {municipality: "PUEBLORRICO", code: "05576"},
  {municipality: "SALGAR", code: "05642"},
  {municipality: "SANTA BaRBARA", code: "05679"},
  {municipality: "TAMESIS", code: "05789"},
  {municipality: "TARSO", code: "05792"},
  {municipality: "TITIRIBI", code: "05809"},
  {municipality: "URRAO", code: "05847"},
  {municipality: "VALPARAISO", code: "05856"},
  {municipality: "VENECIA", code: "05861"},
  {municipality: "APARTADO", code: "05045"},
  {municipality: "ARBOLETES", code: "05051"},
  {municipality: "CAREPA", code: "05147"},
  {municipality: "CHIGORODO", code: "05172"},
  {municipality: "MURINDO", code: "05475"},
  {municipality: "MUTATA", code: "05480"},
  {municipality: "NECOCLI", code: "05490"},
  {municipality: "SAN JUAN DE URABA", code: "05659"},
  {municipality: "SAN PEDRO DE URABA", code: "05665"},
  {municipality: "TURBO", code: "05837"},
  {municipality: "VIGIA DEL FUERTE", code: "05873"},
  {municipality: "BARBOSA", code: "05079"},
  {municipality: "BELLO", code: "05088"},
  {municipality: "CALDAS", code: "05129"},
  {municipality: "COPACABANA", code: "05212"},
  {municipality: "ENVIGADO", code: "05266"},
  {municipality: "GIRARDOTA", code: "05308"},
  {municipality: "ITAGUI", code: "05360"},
  {municipality: "LA ESTRELLA", code: "05380"},
  {municipality: "MEDELLIN", code: "05001"},
  {municipality: "SABANETA", code: "05631"},
  {municipality: "ARAUCA", code: "81001"},
  {municipality: "ARAUQUITA", code: "81065"},
  {municipality: "CRAVO NORTE", code: "81220"},
  {municipality: "FORTUL", code: "81300"},
  {municipality: "PUERTO RONDON", code: "81591"},
  {municipality: "SARAVENA", code: "81736"},
  {municipality: "TAME", code: "81794"},
  {municipality: "PROVIDENCIA Y SANTA CATALINA", code: "88564"},
  {municipality: "SAN ANDReS", code: "88001"},
  {municipality: "BARRANQUILLA", code: "08001"},
  {municipality: "GALAPA", code: "08296"},
  {municipality: "MALAMBO", code: "08433"},
  {municipality: "PUERTO COLOMBIA", code: "08573"},
  {municipality: "SOLEDAD", code: "08758"},
  {municipality: "CAMPO DE LA CRUZ", code: "08137"},
  {municipality: "CANDELARIA", code: "08141"},
  {municipality: "LURUACO", code: "08421"},
  {municipality: "MANATi", code: "08436"},
  {municipality: "REPELON", code: "08606"},
  {municipality: "SANTA LUCiA", code: "08675"},
  {municipality: "SUAN", code: "08770"},
  {municipality: "BARANOA", code: "08078"},
  {municipality: "PALMAR DE VARELA", code: "08520"},
  {municipality: "POLONUEVO", code: "08558"},
  {municipality: "PONEDERA", code: "08560"},
  {municipality: "Sabanagrande", code: "08634"},
  {municipality: "SABANALARGA", code: "08638"},
  {municipality: "Santo Tomas", code: "08685"},
  {municipality: "JUAN DE ACOSTA", code: "08372"},
  {municipality: "PIOJO", code: "08549"},
  {municipality: "TUBARA", code: "08832"},
  {municipality: "USIACURi", code: "08849"},
  {municipality: "BOGOTA D.C.", code: "11001"},
  {municipality: "CICUCO", code: "13188"},
  {municipality: "HATILLO DE LOBA", code: "13300"},
  {municipality: "MARGARITA", code: "13440"},
  {municipality: "MOMPOS", code: "13468"},
  {municipality: "SAN FERNANDO", code: "13650"},
  {municipality: "TALAIGUA NUEVO", code: "13780"},
  {municipality: "ARJONA", code: "13052"},
  {municipality: "ARROYOHONDO", code: "13062"},
  {municipality: "CALAMAR", code: "13140"},
  {municipality: "CARTAGENA", code: "13001"},
  {municipality: "CLEMENCIA", code: "13222"},
  {municipality: "MAHATES", code: "13433"},
  {municipality: "SAN CRISTOBAL", code: "13620"},
  {municipality: "SAN ESTANISLAO", code: "13647"},
  {municipality: "SANTA CATALINA", code: "13673"},
  {municipality: "SANTA ROSA DE LIMA", code: "13683"},
  {municipality: "SOPLAVIENTO", code: "13760"},
  {municipality: "TURBACO", code: "13836"},
  {municipality: "TURBANA", code: "13838"},
  {municipality: "VILLANUEVA", code: "13873"},
  {municipality: "ALTOS DEL ROSARIO", code: "13030"},
  {municipality: "BARRANCO DE LOBA", code: "13074"},
  {municipality: "EL PENON", code: "13268"},
  {municipality: "REGIDOR", code: "13580"},
  {municipality: "RIO VIEJO", code: "13600"},
  {municipality: "SAN MARTIN DE LOBA", code: "13667"},
  {municipality: "ARENAL", code: "13042"},
  {municipality: "CANTAGALLO", code: "13160"},
  {municipality: "MORALES", code: "13473"},
  {municipality: "SAN PABLO", code: "13670"},
  {municipality: "SANTA ROSA DEL SUR", code: "13688"},
  {municipality: "SIMITI", code: "13744"},
  {municipality: "ACHI", code: "13006"},
  {municipality: "MAGANGUE", code: "13430"},
  {municipality: "MONTECRISTO", code: "13458"},
  {municipality: "PINILLOS", code: "13549"},
  {municipality: "SAN JACINTO DEL CAUCA", code: "13655"},
  {municipality: "TIQUISIO", code: "13810"},
  {municipality: "CARMEN DE BOLIVAR", code: "13244"},
  {municipality: "CORDOBA", code: "13212"},
  {municipality: "EL GUAMO", code: "13248"},
  {municipality: "MARIA LA BAJA", code: "13442"},
  {municipality: "SAN JACINTO", code: "13654"},
  {municipality: "SAN JUAN NEPOMUCENO", code: "13657"},
  {municipality: "ZAMBRANO", code: "13894"},
  {municipality: "CHIQUIZA", code: "15232"},
  {municipality: "CHIVATA", code: "15187"},
  {municipality: "COMBITA", code: "15204"},
  {municipality: "CUCAITA", code: "15224"},
  {municipality: "MOTAVITA", code: "15476"},
  {municipality: "OICATA", code: "15500"},
  {municipality: "SAMACA", code: "15646"},
  {municipality: "SIACHOQUE", code: "15740"},
  {municipality: "SORA", code: "15762"},
  {municipality: "SORACA", code: "15764"},
  {municipality: "SOTAQUIRA", code: "15763"},
  {municipality: "TOCA", code: "15814"},
  {municipality: "TUNJA", code: "15001"},
  {municipality: "TUTA", code: "15837"},
  {municipality: "VENTAQUEMADA", code: "15861"},
  {municipality: "CHISCAS", code: "15180"},
  {municipality: "CUBARA", code: "15223"},
  {municipality: "EL COCUY", code: "15244"},
  {municipality: "EL ESPINO", code: "15248"},
  {municipality: "GUACAMAYAS", code: "15317"},
  {municipality: "GÜICAN", code: "15332"},
  {municipality: "PANQUEBA", code: "15522"},
  {municipality: "LABRANZAGRANDE", code: "15377"},
  {municipality: "PAJARITO", code: "15518"},
  {municipality: "PAYA", code: "15533"},
  {municipality: "PISBA", code: "15550"},
  {municipality: "BERBEO", code: "15090"},
  {municipality: "CAMPOHERMOSO", code: "15135"},
  {municipality: "MIRAFLORES", code: "15455"},
  {municipality: "PAEZ", code: "15514"},
  {municipality: "SAN EDUARDO", code: "15660"},
  {municipality: "ZETAQUIRA", code: "15897"},
  {municipality: "BOYACA", code: "15104"},
  {municipality: "CIENEGA", code: "15189"},
  {municipality: "JENESANO", code: "15367"},
  {municipality: "NUEVO COLON", code: "15494"},
  {municipality: "RAMIRIQUI", code: "15599"},
  {municipality: "RONDON", code: "15621"},
  {municipality: "TIBANA", code: "15804"},
  {municipality: "TURMEQUE", code: "15835"},
  {municipality: "UMBITA", code: "15842"},
  {municipality: "VIRACACHA", code: "15879"},
  {municipality: "CHINAVITA", code: "15172"},
  {municipality: "GARAGOA", code: "15299"},
  {municipality: "MACANAL", code: "15425"},
  {municipality: "PACHAVITA", code: "15511"},
  {municipality: "SAN LUIS DE GACENO", code: "15667"},
  {municipality: "SANTA MARIA", code: "15690"},
  {municipality: "BOAVITA", code: "15097"},
  {municipality: "COVARACHIA", code: "15218"},
  {municipality: "LA UVITA", code: "15403"},
  {municipality: "SAN MATEO", code: "15673"},
  {municipality: "SATIVANORTE", code: "15720"},
  {municipality: "SATIVASUR", code: "15723"},
  {municipality: "SOATA", code: "15753"},
  {municipality: "SUSACON", code: "15774"},
  {municipality: "TIPACOQUE", code: "15810"},
  {municipality: "BRICENO", code: "15106"},
  {municipality: "BUENAVISTA", code: "15109"},
  {municipality: "CALDAS", code: "15131"},
  {municipality: "CHIQUINQUIRA", code: "15176"},
  {municipality: "COPER", code: "15212"},
  {municipality: "LA VICTORIA", code: "15401"},
  {municipality: "MARIPI", code: "15442"},
  {municipality: "MUZO", code: "15480"},
  {municipality: "OTANCHE", code: "15507"},
  {municipality: "PAUNA", code: "15531"},
  {municipality: "PUERTO BOYACa", code: "15572"},
  {municipality: "QUIPAMA", code: "15580"},
  {municipality: "SABOYA", code: "15632"},
  {municipality: "SAN MIGUEL DE SEMA", code: "15676"},
  {municipality: "SAN PABLO BORBUR", code: "15681"},
  {municipality: "TUNUNGUA", code: "15832"},
  {municipality: "ALMEIDA", code: "15022"},
  {municipality: "CHIVOR", code: "15236"},
  {municipality: "GUATEQUE", code: "15322"},
  {municipality: "GUAYATA", code: "15325"},
  {municipality: "LA CAPILLA", code: "15380"},
  {municipality: "SOMONDOCO", code: "15761"},
  {municipality: "SUTATENZA", code: "15778"},
  {municipality: "TENZA", code: "15798"},
  {municipality: "ARCABUCO", code: "15051"},
  {municipality: "CHITARAQUE", code: "15185"},
  {municipality: "GACHANTIVA", code: "15293"},
  {municipality: "MONIQUIRA", code: "15469"},
  {municipality: "RAQUIRA", code: "15600"},
  {municipality: "SACHICA", code: "15638"},
  {municipality: "SAN JOSE DE PARE", code: "15664"},
  {municipality: "SANTA SOFIA", code: "15696"},
  {municipality: "SANTANA", code: "15686"},
  {municipality: "SUTAMARCHAN", code: "15776"},
  {municipality: "TINJACA", code: "15808"},
  {municipality: "TOGÜI", code: "15816"},
  {municipality: "VILLA DE LEYVA", code: "15407"},
  {municipality: "AQUITANIA", code: "15047"},
  {municipality: "CUITIVA", code: "15226"},
  {municipality: "FIRAVITOBA", code: "15272"},
  {municipality: "GAMEZA", code: "15296"},
  {municipality: "IZA", code: "15362"},
  {municipality: "MONGUA", code: "15464"},
  {municipality: "MONGUI", code: "15466"},
  {municipality: "NOBSA", code: "15491"},
  {municipality: "PESCA", code: "15542"},
  {municipality: "SOGAMOSO", code: "15759"},
  {municipality: "TIBASOSA", code: "15806"},
  {municipality: "TOPAGA", code: "15820"},
  {municipality: "TOTA", code: "15822"},
  {municipality: "BELEN", code: "15087"},
  {municipality: "BUSBANZA", code: "15114"},
  {municipality: "CERINZA", code: "15162"},
  {municipality: "CORRALES", code: "15215"},
  {municipality: "DUITAMA", code: "15238"},
  {municipality: "FLORESTA", code: "15276"},
  {municipality: "PAIPA", code: "15516"},
  {municipality: "SAN ROSA VITERBO", code: "15693"},
  {municipality: "TUTAZA", code: "15839"},
  {municipality: "BETEITIVA", code: "15092"},
  {municipality: "CHITA", code: "15183"},
  {municipality: "JERICO", code: "15368"},
  {municipality: "PAZ DE RIO", code: "15537"},
  {municipality: "SOCHA", code: "15757"},
  {municipality: "SOCOTA", code: "15755"},
  {municipality: "TASCO", code: "15790"},
  {municipality: "FILADELFIA", code: "17272"},
  {municipality: "LA MERCED", code: "17388"},
  {municipality: "MARMATO", code: "17442"},
  {municipality: "RIOSUCIO", code: "17614"},
  {municipality: "SUPIA", code: "17777"},
  {municipality: "MANZANARES", code: "17433"},
  {municipality: "MARQUETALIA", code: "17444"},
  {municipality: "MARULANDA", code: "17446"},
  {municipality: "PENSILVANIA", code: "17541"},
  {municipality: "ANSERMA", code: "17042"},
  {municipality: "BELALCAZAR", code: "17088"},
  {municipality: "RISARALDA", code: "17616"},
  {municipality: "SAN JOSE", code: "17665"},
  {municipality: "VITERBO", code: "17877"},
  {municipality: "CHINCHINa", code: "17174"},
  {municipality: "MANIZALES", code: "17001"},
  {municipality: "NEIRA", code: "17486"},
  {municipality: "PALESTINA", code: "17524"},
  {municipality: "VILLAMARiA", code: "17873"},
  {municipality: "AGUADAS", code: "17013"},
  {municipality: "ARANZAZU", code: "17050"},
  {municipality: "PACORA", code: "17513"},
  {municipality: "SALAMINA", code: "17653"},
  {municipality: "LA DORADA", code: "17380"},
  {municipality: "NORCASIA", code: "17495"},
  {municipality: "SAMANA", code: "17662"},
  {municipality: "VICTORIA", code: "17867"},
  {municipality: "ALBANIA", code: "18029"},
  {municipality: "BELEN DE LOS ANDAQUIES", code: "18094"},
  {municipality: "CARTAGENA DEL CHAIRA", code: "18150"},
  {municipality: "CURRILLO", code: "18205"},
  {municipality: "EL DONCELLO", code: "18247"},
  {municipality: "EL PAUJIL", code: "18256"},
  {municipality: "FLORENCIA", code: "18001"},
  {municipality: "LA MONTANITA", code: "18410"},
  {municipality: "MILaN", code: "18460"},
  {municipality: "MORELIA", code: "18479"},
  {municipality: "PUERTO RICO", code: "18592"},
  {municipality: "SAN JOSE DEL FRAGUA", code: "18610"},
  {municipality: "SAN VICENTE DEL CAGUAN", code: "18753"},
  {municipality: "SOLANO", code: "18756"},
  {municipality: "SOLITA", code: "18785"},
  {municipality: "VALPARAISO", code: "18860"},
  {municipality: "AGUAZUL", code: "85010"},
  {municipality: "CHAMEZA", code: "85015"},
  {municipality: "HATO COROZAL", code: "85125"},
  {municipality: "LA SALINA", code: "85136"},
  {municipality: "MANI", code: "85139"},
  {municipality: "MONTERREY", code: "85162"},
  {municipality: "NUNCHIA", code: "85225"},
  {municipality: "OROCUE", code: "85230"},
  {municipality: "PAZ DE ARIPORO", code: "85250"},
  {municipality: "PORE", code: "85263"},
  {municipality: "RECETOR", code: "85279"},
  {municipality: "SABANALARGA", code: "85300"},
  {municipality: "SACAMA", code: "85315"},
  {municipality: "SAN LUIS DE PALENQUE", code: "85325"},
  {municipality: "TAMARA", code: "85400"},
  {municipality: "TAURAMENA", code: "85410"},
  {municipality: "TRINIDAD", code: "85430"},
  {municipality: "VILLANUEVA", code: "85440"},
  {municipality: "YOPAL", code: "85001"},
  {municipality: "CAJIBIO", code: "19130"},
  {municipality: "EL TAMBO", code: "19256"},
  {municipality: "LA SIERRA", code: "19392"},
  {municipality: "MORALES", code: "19473"},
  {municipality: "PIENDAMO", code: "19548"},
  {municipality: "POPAYAN", code: "19001"},
  {municipality: "ROSAS", code: "19622"},
  {municipality: "SOTARA", code: "19760"},
  {municipality: "TIMBIO", code: "19807"},
  {municipality: "BUENOS AIRES", code: "19110"},
  {municipality: "CALOTO", code: "19142"},
  {municipality: "CORINTO", code: "19212"},
  {municipality: "MIRANDA", code: "19455"},
  {municipality: "PADILLA", code: "19513"},
  {municipality: "PUERTO TEJADA", code: "19573"},
  {municipality: "SANTANDER DE QUILICHAO", code: "19698"},
  {municipality: "SUAREZ", code: "19780"},
  {municipality: "VILLA RICA", code: "19845"},
  {municipality: "GUAPI", code: "19318"},
  {municipality: "LOPEZ", code: "19418"},
  {municipality: "TIMBIQUI", code: "19809"},
  {municipality: "CALDONO", code: "19137"},
  {municipality: "INZA", code: "19355"},
  {municipality: "JAMBALO", code: "19364"},
  {municipality: "PAEZ", code: "19517"},
  {municipality: "PURACE", code: "19585"},
  {municipality: "Silvia", code: "19743"},
  {municipality: "TORIBIO", code: "19821"},
  {municipality: "TOTORO", code: "19824"},
  {municipality: "ALMAGUER", code: "19022"},
  {municipality: "ARGELIA", code: "19050"},
  {municipality: "BALBOA", code: "19075"},
  {municipality: "BOLIVAR", code: "19100"},
  {municipality: "FLORENCIA", code: "19290"},
  {municipality: "LA VEGA", code: "19397"},
  {municipality: "MERCADERES", code: "19450"},
  {municipality: "PATIA", code: "19532"},
  {municipality: "PIAMONTE", code: "19533"},
  {municipality: "SAN SEBASTIAN", code: "19693"},
  {municipality: "SANTA ROSA", code: "19701"},
  {municipality: "SUCRE", code: "19785"},
  {municipality: "BECERRIL", code: "20045"},
  {municipality: "CHIMICHAGUA", code: "20175"},
  {municipality: "CHIRIGUANA", code: "20178"},
  {municipality: "CURUMANI", code: "20228"},
  {municipality: "LA JAGUA DE IBIRICO", code: "20400"},
  {municipality: "PAILITAS", code: "20517"},
  {municipality: "TAMALAMEQUE", code: "20787"},
  {municipality: "ASTREA", code: "20032"},
  {municipality: "BOSCONIA", code: "20060"},
  {municipality: "EL COPEY", code: "20238"},
  {municipality: "EL PASO", code: "20250"},
  {municipality: "AGUSTIN CODAZZI", code: "20013"},
  {municipality: "LA PAZ", code: "20621"},
  {municipality: "MANAURE", code: "20443"},
  {municipality: "PUEBLO BELLO", code: "20570"},
  {municipality: "SAN DIEGO", code: "20750"},
  {municipality: "VALLEDUPAR", code: "20001"},
  {municipality: "AGUACHICA", code: "20011"},
  {municipality: "GAMARRA", code: "20295"},
  {municipality: "GONZALEZ", code: "20310"},
  {municipality: "LA GLORIA", code: "20383"},
  {municipality: "PELAYA", code: "20550"},
  {municipality: "RIO DE ORO", code: "20614"},
  {municipality: "SAN ALBERTO", code: "20710"},
  {municipality: "SAN MARTIN", code: "20770"},
  {municipality: "ATRATO", code: "27050"},
  {municipality: "BAGADO", code: "27073"},
  {municipality: "BOJAYA", code: "27099"},
  {municipality: "EL CARMEN DE ATRATO", code: "27245"},
  {municipality: "LLORO", code: "27413"},
  {municipality: "MEDIO ATRATO", code: "27425"},
  {municipality: "QUIBDO", code: "27001"},
  {municipality: "RIO QUITO", code: "27600"},
  {municipality: "ACANDI", code: "27006"},
  {municipality: "BELEN DE BAJIRA", code: "27086"},
  {municipality: "CARMEN DEL DARIEN", code: "27150"},
  {municipality: "RIOSUCIO", code: "27615"},
  {municipality: "UNGUIA", code: "27800"},
  {municipality: "BAHIA SOLANO", code: "27075"},
  {municipality: "JURADO", code: "27372"},
  {municipality: "NUQUI", code: "27495"},
  {municipality: "ALTO BAUDO", code: "27025"},
  {municipality: "BAJO BAUDO", code: "27077"},
  {municipality: "El Litoral del San Juan", code: "27250"},
  {municipality: "MEDIO BAUDO", code: "27430"},
  {municipality: "CANTON DE SAN PABLO", code: "27135"},
  {municipality: "CERTEGUI", code: "27160"},
  {municipality: "CONDOTO", code: "27205"},
  {municipality: "ITSMINA", code: "27361"},
  {municipality: "MEDIO SAN JUAN", code: "27450"},
  {municipality: "NOVITA", code: "27491"},
  {municipality: "RIO FRIO", code: "27580"},
  {municipality: "SAN JOSE DEL PALMAR", code: "27660"},
  {municipality: "SIPI", code: "27745"},
  {municipality: "TADO", code: "27787"},
  {municipality: "UNION PANAMERICANA", code: "27810"},
  {municipality: "TIERRALTA", code: "23807"},
  {municipality: "VALENCIA", code: "23855"},
  {municipality: "CHIMA", code: "23168"},
  {municipality: "COTORRA", code: "23300"},
  {municipality: "LORICA", code: "23417"},
  {municipality: "MOMIL", code: "23464"},
  {municipality: "PURISIMA", code: "23586"},
  {municipality: "MONTERIA", code: "23001"},
  {municipality: "CANALETE", code: "23090"},
  {municipality: "LOS CORDOBAS", code: "23419"},
  {municipality: "MONITOS", code: "23500"},
  {municipality: "PUERTO ESCONDIDO", code: "23574"},
  {municipality: "SAN ANTERO", code: "23672"},
  {municipality: "SAN BERNARDO DEL VIENTO", code: "23675"},
  {municipality: "CHINU", code: "23182"},
  {municipality: "SAHAGUN", code: "23660"},
  {municipality: "SAN ANDRES SOTAVENTO", code: "23670"},
  {municipality: "AYAPEL", code: "23068"},
  {municipality: "BUENAVISTA", code: "23079"},
  {municipality: "LA APARTADA", code: "23350"},
  {municipality: "MONTELIBANO", code: "23466"},
  {municipality: "PLANETA RICA", code: "23555"},
  {municipality: "PUEBLO NUEVO", code: "23570"},
  {municipality: "PUERTO LIBERTADOR", code: "23580"},
  {municipality: "CERETE", code: "23162"},
  {municipality: "CIENAGA DE ORO", code: "23189"},
  {municipality: "SAN CARLOS", code: "23678"},
  {municipality: "SAN PELAYO", code: "23686"},
  {municipality: "CHOCONTA", code: "25183"},
  {municipality: "MACHETA", code: "25426"},
  {municipality: "MANTA", code: "25436"},
  {municipality: "SESQUILE", code: "25736"},
  {municipality: "SUESCA", code: "25772"},
  {municipality: "TIBIRITA", code: "25807"},
  {municipality: "VILLAPINZON", code: "25873"},
  {municipality: "AGUA DE DIOS", code: "25001"},
  {municipality: "GIRARDOT", code: "25307"},
  {municipality: "GUATAQUI", code: "25324"},
  {municipality: "JERUSALEN", code: "25368"},
  {municipality: "NARINO", code: "25483"},
  {municipality: "NILO", code: "25488"},
  {municipality: "RICAURTE", code: "25612"},
  {municipality: "TOCAIMA", code: "25815"},
  {municipality: "CAPARRAPI", code: "25148"},
  {municipality: "GUADUAS", code: "25320"},
  {municipality: "PUERTO SALGAR", code: "25572"},
  {municipality: "ALBAN", code: "25019"},
  {municipality: "LA PENA", code: "25398"},
  {municipality: "LA VEGA", code: "25402"},
  {municipality: "NIMAIMA", code: "25489"},
  {municipality: "NOCAIMA", code: "25491"},
  {municipality: "QUEBRADANEGRA", code: "25592"},
  {municipality: "SAN FRANCISCO", code: "25658"},
  {municipality: "SASAIMA", code: "25718"},
  {municipality: "SUPATA", code: "25777"},
  {municipality: "UTICA", code: "25851"},
  {municipality: "VERGARA", code: "25862"},
  {municipality: "VILLETA", code: "25875"},
  {municipality: "GACHALA", code: "25293"},
  {municipality: "GACHETA", code: "25297"},
  {municipality: "GAMA", code: "25299"},
  {municipality: "GUASCA", code: "25322"},
  {municipality: "GUATAVITA", code: "25326"},
  {municipality: "JUNIN", code: "25372"},
  {municipality: "LA CALERA", code: "25377"},
  {municipality: "UBALA", code: "25839"},
  {municipality: "BELTRAN", code: "25086"},
  {municipality: "BITUIMA", code: "25095"},
  {municipality: "CHAGUANI", code: "25168"},
  {municipality: "GUAYABAL DE SIQUIMA", code: "25328"},
  {municipality: "PULI", code: "25580"},
  {municipality: "SAN JUAN DE RIO SECO", code: "25662"},
  {municipality: "VIANI", code: "25867"},
  {municipality: "MEDINA", code: "25438"},
  {municipality: "PARATEBUENO", code: "25530"},
  {municipality: "CAQUEZA", code: "25151"},
  {municipality: "CHIPAQUE", code: "25178"},
  {municipality: "CHOACHI", code: "25181"},
  {municipality: "FOMEQUE", code: "25279"},
  {municipality: "FOSCA", code: "25281"},
  {municipality: "GUAYABETAL", code: "25335"},
  {municipality: "GUTIERREZ", code: "25339"},
  {municipality: "QUETAME", code: "25594"},
  {municipality: "UBAQUE", code: "25841"},
  {municipality: "UNE", code: "25845"},
  {municipality: "EL PENON", code: "25258"},
  {municipality: "LA PALMA", code: "25394"},
  {municipality: "PACHO", code: "25513"},
  {municipality: "PAIME", code: "25518"},
  {municipality: "SAN CAYETANO", code: "25653"},
  {municipality: "TOPAIPI", code: "25823"},
  {municipality: "VILLAGOMEZ", code: "25871"},
  {municipality: "YACOPI", code: "25885"},
  {municipality: "CAJICA", code: "25126"},
  {municipality: "CHIA", code: "25175"},
  {municipality: "COGUA", code: "25200"},
  {municipality: "GACHANCIPA", code: "25295"},
  {municipality: "NEMOCoN", code: "25486"},
  {municipality: "SOPO", code: "25758"},
  {municipality: "TABIO", code: "25785"},
  {municipality: "TOCANCIPA", code: "25817"},
  {municipality: "ZIPAQUIRA", code: "25899"},
  {municipality: "BOJACA", code: "25099"},
  {municipality: "COTA", code: "25214"},
  {municipality: "EL ROSAL", code: "25260"},
  {municipality: "FACATATIVA", code: "25269"},
  {municipality: "FUNZA", code: "25286"},
  {municipality: "MADRID", code: "25430"},
  {municipality: "MOSQUERA", code: "25473"},
  {municipality: "SUBACHOQUE", code: "25769"},
  {municipality: "TENJO", code: "25799"},
  {municipality: "ZIPACoN", code: "25898"},
  {municipality: "SIBATE", code: "25740"},
  {municipality: "SOACHA", code: "25754"},
  {municipality: "ARBELAEZ", code: "25053"},
  {municipality: "CABRERA", code: "25120"},
  {municipality: "FUSAGASUGA", code: "25290"},
  {municipality: "GRANADA", code: "25312"},
  {municipality: "PANDI", code: "25524"},
  {municipality: "PASCA", code: "25535"},
  {municipality: "SAN BERNARDO", code: "25649"},
  {municipality: "SILVANIA", code: "25743"},
  {municipality: "TIBACUY", code: "25805"},
  {municipality: "VENECIA", code: "25506"},
  {municipality: "ANAPOIMA", code: "25035"},
  {municipality: "ANOLAIMA", code: "25040"},
  {municipality: "APULO", code: "25599"},
  {municipality: "CACHIPAY", code: "25123"},
  {municipality: "EL COLEGIO", code: "25245"},
  {municipality: "LA MESA", code: "25386"},
  {municipality: "QUIPILE", code: "25596"},
  {municipality: "SAN ANTONIO DE TEQUENDAMA", code: "25645"},
  {municipality: "TENA", code: "25797"},
  {municipality: "VIOTA", code: "25878"},
  {municipality: "CARMEN DE CARUPA", code: "25154"},
  {municipality: "CUCUNUBA", code: "25224"},
  {municipality: "FUQUENE", code: "25288"},
  {municipality: "GUACHETA", code: "25317"},
  {municipality: "LENGUAZAQUE", code: "25407"},
  {municipality: "SIMIJACA", code: "25745"},
  {municipality: "SUSA", code: "25779"},
  {municipality: "SUTATAUSA", code: "25781"},
  {municipality: "TAUSA", code: "25793"},
  {municipality: "UBATE", code: "25843"},
  {municipality: "BARRANCO MINA", code: "94343"},
  {municipality: "CACAHUAL", code: "94886"},
  {municipality: "INIRIDA", code: "94001"},
  {municipality: "LA GUADALUPE", code: "94885"},
  {municipality: "MAPIRIPaN", code: "94663"},
  {municipality: "MORICHAL", code: "94888"},
  {municipality: "PANA PANA", code: "94887"},
  {municipality: "PUERTO COLOMBIA", code: "94884"},
  {municipality: "SAN FELIPE", code: "94883"},
  {municipality: "CALAMAR", code: "95015"},
  {municipality: "EL RETORNO", code: "95025"},
  {municipality: "MIRAFLORES", code: "95200"},
  {municipality: "SAN JOSE DEL GUAVIARE", code: "95001"},
  {municipality: "AGRADO", code: "41013"},
  {municipality: "ALTAMIRA", code: "41026"},
  {municipality: "GARZON", code: "41298"},
  {municipality: "GIGANTE", code: "41306"},
  {municipality: "GUADALUPE", code: "41319"},
  {municipality: "PITAL", code: "41548"},
  {municipality: "SUAZA", code: "41770"},
  {municipality: "TARQUI", code: "41791"},
  {municipality: "AIPE", code: "41016"},
  {municipality: "ALGECIRAS", code: "41020"},
  {municipality: "BARAYA", code: "41078"},
  {municipality: "CAMPOALEGRE", code: "41132"},
  {municipality: "COLOMBIA", code: "41206"},
  {municipality: "HOBO", code: "41349"},
  {municipality: "IQUIRA", code: "41357"},
  {municipality: "NEIVA", code: "41001"},
  {municipality: "PALERMO", code: "41524"},
  {municipality: "RIVERA", code: "41615"},
  {municipality: "SANTA MARIA", code: "41676"},
  {municipality: "TELLO", code: "41799"},
  {municipality: "TERUEL", code: "41801"},
  {municipality: "VILLAVIEJA", code: "41872"},
  {municipality: "YAGUARA", code: "41885"},
  {municipality: "LA ARGENTINA", code: "41378"},
  {municipality: "LA PLATA", code: "41396"},
  {municipality: "NATAGA", code: "41483"},
  {municipality: "PAICOL", code: "41518"},
  {municipality: "TESALIA", code: "41797"},
  {municipality: "ACEVEDO", code: "41006"},
  {municipality: "ELIAS", code: "41244"},
  {municipality: "ISNOS", code: "41359"},
  {municipality: "OPORAPA", code: "41503"},
  {municipality: "PALESTINA", code: "41530"},
  {municipality: "PITALITO", code: "41551"},
  {municipality: "SALADOBLANCO", code: "41660"},
  {municipality: "SAN AGUSTIN", code: "41668"},
  {municipality: "TIMANA", code: "41807"},
  {municipality: "ALBANIA", code: "44035"},
  {municipality: "DIBULLA", code: "44090"},
  {municipality: "MAICAO", code: "44430"},
  {municipality: "MANAURE", code: "44560"},
  {municipality: "RIOHACHA", code: "44001"},
  {municipality: "URIBIA", code: "44847"},
  {municipality: "BARRANCAS", code: "44078"},
  {municipality: "DISTRACCION", code: "44098"},
  {municipality: "EL MOLINO", code: "44110"},
  {municipality: "FONSECA", code: "44279"},
  {municipality: "HATONUEVO", code: "44378"},
  {municipality: "LA JAGUA DEL PILAR", code: "44420"},
  {municipality: "SAN JUAN DEL CESAR", code: "44650"},
  {municipality: "URUMITA", code: "44855"},
  {municipality: "VILLANUEVA", code: "44874"},
  {municipality: "ARIGUANI", code: "47058"},
  {municipality: "CHIBOLO", code: "47170"},
  {municipality: "NUEVA GRANADA", code: "47460"},
  {municipality: "PLATO", code: "47555"},
  {municipality: "SABANAS DE SAN ANGEL", code: "47660"},
  {municipality: "TENERIFE", code: "47798"},
  {municipality: "ALGARROBO", code: "47030"},
  {municipality: "ARACATACA", code: "47053"},
  {municipality: "CIENAGA", code: "47189"},
  {municipality: "EL RETEN", code: "47268"},
  {municipality: "FUNDACION", code: "47288"},
  {municipality: "PUEBLO VIEJO", code: "47570"},
  {municipality: "ZONA BANANERA", code: "47980"},
  {municipality: "CERRO SAN ANTONIO", code: "47161"},
  {municipality: "CONCORDIA", code: "47205"},
  {municipality: "EL PINON", code: "47258"},
  {municipality: "PEDRAZA", code: "47541"},
  {municipality: "PIVIJAY", code: "47551"},
  {municipality: "REMOLINO", code: "47605"},
  {municipality: "SALAMINA", code: "47675"},
  {municipality: "SITIONUEVO", code: "47745"},
  {municipality: "ZAPAYAN", code: "47960"},
  {municipality: "SANTA MARTA", code: "47001"},
  {municipality: "EL BANCO", code: "47245"},
  {municipality: "GUAMAL", code: "47318"},
  {municipality: "PIJINO DEL CARMEN", code: "47545"},
  {municipality: "SAN SEBASTIAN DE BUENAVISTA", code: "47692"},
  {municipality: "SAN ZENON", code: "47703"},
  {municipality: "SANTA ANA", code: "47707"},
  {municipality: "SANTA BARBARA DE PINTO", code: "47720"},
  {municipality: "EL CASTILLO", code: "50251"},
  {municipality: "EL DORADO", code: "50270"},
  {municipality: "FUENTE DE ORO", code: "50287"},
  {municipality: "GRANADA", code: "50313"},
  {municipality: "LA MACARENA", code: "50350"},
  {municipality: "LA URIBE", code: "50370"},
  {municipality: "LEJANIAS", code: "50400"},
  {municipality: "MAPIRIPaN", code: "50325"},
  {municipality: "MESETAS", code: "50330"},
  {municipality: "PUERTO CONCORDIA", code: "50450"},
  {municipality: "PUERTO LLERAS", code: "50577"},
  {municipality: "PUERTO RICO", code: "50590"},
  {municipality: "SAN JUAN DE ARAMA", code: "50683"},
  {municipality: "VISTA HERMOSA", code: "50711"},
  {municipality: "VILLAVICENCIO", code: "50001"},
  {municipality: "ACACiAS", code: "50006"},
  {municipality: "BARRANCA DE UPIA", code: "50110"},
  {municipality: "CASTILLA LA NUEVA", code: "50150"},
  {municipality: "CUMARAL", code: "50226"},
  {municipality: "EL CALVARIO", code: "50245"},
  {municipality: "GUAMAL", code: "50318"},
  {municipality: "RESTREPO", code: "50606"},
  {municipality: "SAN CARLOS GUAROA", code: "50680"},
  {municipality: "SAN JUANITO", code: "50686"},
  {municipality: "SAN LUIS DE CUBARRAL", code: "50223"},
  {municipality: "SAN MARTIN", code: "50689"},
  {municipality: "CABUYARO", code: "50124"},
  {municipality: "PUERTO GAITAN", code: "50568"},
  {municipality: "PUERTO LoPEZ", code: "50573"},
  {municipality: "CHACHAGUI", code: "52240"},
  {municipality: "CONSACA", code: "52207"},
  {municipality: "EL PENOL", code: "52254"},
  {municipality: "EL TAMBO", code: "52260"},
  {municipality: "LA FLORIDA", code: "52381"},
  {municipality: "NARINO", code: "52480"},
  {municipality: "PASTO", code: "52001"},
  {municipality: "SANDONA", code: "52683"},
  {municipality: "TANGUA", code: "52788"},
  {municipality: "YACUANQUER", code: "52885"},
  {municipality: "ANCUYA", code: "52036"},
  {municipality: "GUAITARILLA", code: "52320"},
  {municipality: "LA LLANADA", code: "52385"},
  {municipality: "LINARES", code: "52411"},
  {municipality: "LOS ANDES", code: "52418"},
  {municipality: "MALLAMA", code: "52435"},
  {municipality: "OSPINA", code: "52506"},
  {municipality: "PROVIDENCIA", code: "52565"},
  {municipality: "RICAURTE", code: "52612"},
  {municipality: "SAMANIEGO", code: "52678"},
  {municipality: "SANTA CRUZ", code: "52699"},
  {municipality: "SAPUYES", code: "52720"},
  {municipality: "TUQUERRES", code: "52838"},
  {municipality: "BARBACOAS", code: "52079"},
  {municipality: "EL CHARCO", code: "52250"},
  {municipality: "FRANCISCO PIZARRO", code: "52520"},
  {municipality: "LA TOLA", code: "52390"},
  {municipality: "Magui", code: "52427"},
  {municipality: "MOSQUERA", code: "52473"},
  {municipality: "OLAYA HERRERA", code: "52490"},
  {municipality: "ROBERTO PAYAN", code: "52621"},
  {municipality: "SANTA BaRBARA", code: "52696"},
  {municipality: "TUMACO", code: "52835"},
  {municipality: "ALBAN", code: "52019"},
  {municipality: "ARBOLEDA", code: "52051"},
  {municipality: "BELEN", code: "52083"},
  {municipality: "BUESACO", code: "52110"},
  {municipality: "COLON", code: "52203"},
  {municipality: "CUMBITARA", code: "52233"},
  {municipality: "EL ROSARIO", code: "52256"},
  {municipality: "El Tablon de Gomez", code: "52258"},
  {municipality: "LA CRUZ", code: "52378"},
  {municipality: "LA UNION", code: "52399"},
  {municipality: "LEIVA", code: "52405"},
  {municipality: "POLICARPA", code: "52540"},
  {municipality: "SAN BERNARDO", code: "52685"},
  {municipality: "SAN LORENZO", code: "52687"},
  {municipality: "SAN PABLO", code: "52693"},
  {municipality: "SAN PEDRO DE CARTAGO", code: "52694"},
  {municipality: "TAMINANGO", code: "52786"},
  {municipality: "ALDANA", code: "52022"},
  {municipality: "CONTADERO", code: "52210"},
  {municipality: "CORDOBA", code: "52215"},
  {municipality: "CUASPUD", code: "52224"},
  {municipality: "CUMBAL", code: "52227"},
  {municipality: "FUNES", code: "52287"},
  {municipality: "GUACHUCAL", code: "52317"},
  {municipality: "GUALMATAN", code: "52323"},
  {municipality: "ILES", code: "52352"},
  {municipality: "IMUES", code: "52354"},
  {municipality: "IPIALES", code: "52356"},
  {municipality: "POTOSI", code: "52560"},
  {municipality: "PUERRES", code: "52573"},
  {municipality: "PUPIALES", code: "52585"},
  {municipality: "ARBOLEDAS", code: "54051"},
  {municipality: "CUCUTILLA", code: "54223"},
  {municipality: "GRAMALOTE", code: "54313"},
  {municipality: "LOURDES", code: "54418"},
  {municipality: "SALAZAR", code: "54660"},
  {municipality: "SANTIAGO", code: "54680"},
  {municipality: "VILLA CARO", code: "54871"},
  {municipality: "BUCARASICA", code: "54109"},
  {municipality: "EL TARRA", code: "54250"},
  {municipality: "SARDINATA", code: "54720"},
  {municipality: "TIBU", code: "54810"},
  {municipality: "ABREGO", code: "54003"},
  {municipality: "CACHIRA", code: "54128"},
  {municipality: "CONVENCION", code: "54206"},
  {municipality: "EL CARMEN", code: "54245"},
  {municipality: "HACARI", code: "54344"},
  {municipality: "LA ESPERANZA", code: "54385"},
  {municipality: "LA PLAYA", code: "54398"},
  {municipality: "OCANA", code: "54498"},
  {municipality: "SAN CALIXTO", code: "54670"},
  {municipality: "TEORAMA", code: "54800"},
  {municipality: "CUCUTA", code: "54001"},
  {municipality: "EL ZULIA", code: "54261"},
  {municipality: "LOS PATIOS", code: "54405"},
  {municipality: "PUERTO SANTANDER", code: "54553"},
  {municipality: "SAN CAYETANO", code: "54673"},
  {municipality: "VILLA DEL ROSARIO", code: "54874"},
  {municipality: "CACOTA", code: "54125"},
  {municipality: "CHITAGA", code: "54174"},
  {municipality: "MUTISCUA", code: "54480"},
  {municipality: "PAMPLONA", code: "54518"},
  {municipality: "PAMPLONITA", code: "54520"},
  {municipality: "SILOS", code: "54743"},
  {municipality: "BOCHALEMA", code: "54099"},
  {municipality: "CHINACOTA", code: "54172"},
  {municipality: "DURANIA", code: "54239"},
  {municipality: "HERRAN", code: "54347"},
  {municipality: "LABATECA", code: "54377"},
  {municipality: "RAGONVALIA", code: "54599"},
  {municipality: "TOLEDO", code: "54820"},
  {municipality: "COLON", code: "86219"},
  {municipality: "MOCOA", code: "86001"},
  {municipality: "ORITO", code: "86320"},
  {municipality: "PUERTO ASIS", code: "86568"},
  {municipality: "PUERTO CAICEDO", code: "86569"},
  {municipality: "PUERTO GUZMAN", code: "86571"},
  {municipality: "PUERTO LEGUIZAMO", code: "86573"},
  {municipality: "SAN FRANCISCO", code: "86755"},
  {municipality: "SAN MIGUEL", code: "86757"},
  {municipality: "SANTIAGO", code: "86760"},
  {municipality: "SIBUNDOY", code: "86749"},
  {municipality: "VALLE DEL GUAMUEZ", code: "86865"},
  {municipality: "VILLA GARZON", code: "86885"},
  {municipality: "ARMENIA", code: "63001"},
  {municipality: "BUENAVISTA", code: "63111"},
  {municipality: "CALARCA", code: "63130"},
  {municipality: "CoRDOBA", code: "63212"},
  {municipality: "GeNOVA", code: "63302"},
  {municipality: "PIJAO", code: "63548"},
  {municipality: "FILANDIA", code: "63272"},
  {municipality: "SALENTO", code: "63690"},
  {municipality: "CIRCASIA", code: "63190"},
  {municipality: "LA TEBAIDA", code: "63401"},
  {municipality: "Montengro", code: "63470"},
  {municipality: "QUIMBAYA", code: "63594"},
  {municipality: "DOSQUEBRADAS", code: "66170"},
  {municipality: "LA VIRGINIA", code: "66400"},
  {municipality: "MARSELLA", code: "66440"},
  {municipality: "PEREIRA", code: "66001"},
  {municipality: "SANTA ROSA DE CABAL", code: "66682"},
  {municipality: "APIA", code: "66045"},
  {municipality: "BALBOA", code: "66075"},
  {municipality: "BELEN DE UMBRIA", code: "66088"},
  {municipality: "GUATICA", code: "66318"},
  {municipality: "LA CELIA", code: "66383"},
  {municipality: "QUINCHiA", code: "66594"},
  {municipality: "SANTUARIO", code: "66687"},
  {municipality: "MISTRATO", code: "66456"},
  {municipality: "PUEBLO RICO", code: "66572"},
  {municipality: "CHIMA", code: "68176"},
  {municipality: "CONFINES", code: "68209"},
  {municipality: "CONTRATACION", code: "68211"},
  {municipality: "EL GUACAMAYO", code: "68245"},
  {municipality: "GALAN", code: "68296"},
  {municipality: "GAMBITA", code: "68298"},
  {municipality: "GUADALUPE", code: "68320"},
  {municipality: "GUAPOTA", code: "68322"},
  {municipality: "HATO", code: "68344"},
  {municipality: "OIBA", code: "68500"},
  {municipality: "PALMAR", code: "68522"},
  {municipality: "PALMAS DEL SOCORRO", code: "68524"},
  {municipality: "SANTA HELENA DEL OPON", code: "68720"},
  {municipality: "SIMACOTA", code: "68745"},
  {municipality: "SOCORRO", code: "68755"},
  {municipality: "SUAITA", code: "68770"},
  {municipality: "CAPITANEJO", code: "68147"},
  {municipality: "CARCASI", code: "68152"},
  {municipality: "CEPITA", code: "68160"},
  {municipality: "CERRITO", code: "68162"},
  {municipality: "CONCEPCION", code: "68207"},
  {municipality: "ENCISO", code: "68266"},
  {municipality: "GUACA", code: "68318"},
  {municipality: "MACARAVITA", code: "68425"},
  {municipality: "MALAGA", code: "68432"},
  {municipality: "MOLAGAVITA", code: "68468"},
  {municipality: "SAN ANDRES", code: "68669"},
  {municipality: "SAN JOSE DE MIRANDA", code: "68684"},
  {municipality: "SAN MIGUEL", code: "68686"},
  {municipality: "ARATOCA", code: "68051"},
  {municipality: "BARICHARA", code: "68079"},
  {municipality: "CABRERA", code: "68121"},
  {municipality: "CHARALA", code: "68167"},
  {municipality: "COROMORO", code: "68217"},
  {municipality: "CURITI", code: "68229"},
  {municipality: "ENCINO", code: "68264"},
  {municipality: "JORDAN", code: "68370"},
  {municipality: "MOGOTES", code: "68464"},
  {municipality: "OCAMONTE", code: "68498"},
  {municipality: "ONZAGA", code: "68502"},
  {municipality: "PARAMO", code: "68533"},
  {municipality: "PINCHOTE", code: "68549"},
  {municipality: "SAN GIL", code: "68679"},
  {municipality: "SAN JOAQUIN", code: "68682"},
  {municipality: "VALLE DE SAN JOSE", code: "68855"},
  {municipality: "VILLANUEVA", code: "68872"},
  {municipality: "BARRANCABERMEJA", code: "68081"},
  {municipality: "BETULIA", code: "68092"},
  {municipality: "EL CARMEN DE CHUCURI", code: "68235"},
  {municipality: "PUERTO WILCHES", code: "68575"},
  {municipality: "SABANA DE TORRES", code: "68655"},
  {municipality: "SAN VICENTE DE CHUCURI", code: "68689"},
  {municipality: "ZAPATOCA", code: "68895"},
  {municipality: "BUCARAMANGA", code: "68001"},
  {municipality: "CALIFORNIA", code: "68132"},
  {municipality: "CHARTA", code: "68169"},
  {municipality: "EL PLAYON", code: "68255"},
  {municipality: "FLORIDABLANCA", code: "68276"},
  {municipality: "GIRON", code: "68307"},
  {municipality: "LEBRIJA", code: "68406"},
  {municipality: "LOS SANTOS", code: "68418"},
  {municipality: "MATANZA", code: "68444"},
  {municipality: "PIEDECUESTA", code: "68547"},
  {municipality: "RIONEGRO", code: "68615"},
  {municipality: "SANTA BARBARA", code: "68705"},
  {municipality: "SURATA", code: "68780"},
  {municipality: "TONA", code: "68820"},
  {municipality: "VETAS", code: "68867"},
  {municipality: "AGUADA", code: "68013"},
  {municipality: "ALBANIA", code: "68020"},
  {municipality: "BARBOSA", code: "68077"},
  {municipality: "BOLIVAR", code: "68101"},
  {municipality: "CHIPATA", code: "68179"},
  {municipality: "CIMITARRA", code: "68190"},
  {municipality: "EL PENON", code: "68250"},
  {municipality: "FLORIAN", code: "68271"},
  {municipality: "GUAVATA", code: "68324"},
  {municipality: "GuEPSA", code: "68327"},
  {municipality: "JESUS MARIA", code: "68368"},
  {municipality: "LA BELLEZA", code: "68377"},
  {municipality: "LA PAZ", code: "68397"},
  {municipality: "LANDAZURI", code: "68385"},
  {municipality: "PUENTE NACIONAL", code: "68572"},
  {municipality: "PUERTO PARRA", code: "68573"},
  {municipality: "SAN BENITO", code: "68673"},
  {municipality: "SUCRE", code: "68773"},
  {municipality: "VELEZ", code: "68861"},
  {municipality: "GUARANDA", code: "70265"},
  {municipality: "MAJAGUAL", code: "70429"},
  {municipality: "SUCRE", code: "70771"},
  {municipality: "CHALAN", code: "70230"},
  {municipality: "COLOSO", code: "70204"},
  {municipality: "MORROA", code: "70473"},
  {municipality: "OVEJAS", code: "70508"},
  {municipality: "SINCELEJO", code: "70001"},
  {municipality: "COVENAS", code: "70221"},
  {municipality: "PALMITO", code: "70523"},
  {municipality: "SAN ONOFRE", code: "70713"},
  {municipality: "SANTIAGO DE TOLU", code: "70820"},
  {municipality: "TOLU VIEJO", code: "70823"},
  {municipality: "BUENAVISTA", code: "70110"},
  {municipality: "COROZAL", code: "70215"},
  {municipality: "EL ROBLE", code: "70233"},
  {municipality: "GALERAS", code: "70235"},
  {municipality: "LOS PALMITOS", code: "70418"},
  {municipality: "SAMPUES", code: "70670"},
  {municipality: "SAN JUAN BETULIA", code: "70702"},
  {municipality: "SAN PEDRO", code: "70717"},
  {municipality: "SINCE", code: "70742"},
  {municipality: "CAIMITO", code: "70124"},
  {municipality: "LA UNION", code: "70400"},
  {municipality: "SAN BENITO ABAD", code: "70678"},
  {municipality: "SAN MARCOS", code: "70708"},
  {municipality: "AMBALEMA", code: "73030"},
  {municipality: "ARMERO", code: "73055"},
  {municipality: "FALAN", code: "73270"},
  {municipality: "FRESNO", code: "73283"},
  {municipality: "HONDA", code: "73349"},
  {municipality: "MARIQUITA", code: "73443"},
  {municipality: "PALOCABILDO", code: "73520"},
  {municipality: "CARMEN DE APICALA", code: "73148"},
  {municipality: "CUNDAY", code: "73226"},
  {municipality: "ICONONZO", code: "73352"},
  {municipality: "MELGAR", code: "73449"},
  {municipality: "VILLARRICA", code: "73873"},
  {municipality: "ATACO", code: "73067"},
  {municipality: "CHAPARRAL", code: "73168"},
  {municipality: "COYAIMA", code: "73217"},
  {municipality: "NATAGAIMA", code: "73483"},
  {municipality: "ORTEGA", code: "73504"},
  {municipality: "PLANADAS", code: "73555"},
  {municipality: "RIOBLANCO", code: "73616"},
  {municipality: "RONCESVALLES", code: "73622"},
  {municipality: "SAN ANTONIO", code: "73675"},
  {municipality: "ALVARADO", code: "73026"},
  {municipality: "ANZOATEGUI", code: "73043"},
  {municipality: "CAJAMARCA", code: "73124"},
  {municipality: "COELLO", code: "73200"},
  {municipality: "ESPINAL", code: "73268"},
  {municipality: "FLANDES", code: "73275"},
  {municipality: "IBAGUe", code: "73001"},
  {municipality: "PIEDRAS", code: "73547"},
  {municipality: "ROVIRA", code: "73624"},
  {municipality: "SAN LUIS", code: "73678"},
  {municipality: "VALLE DE SAN JUAN", code: "73854"},
  {municipality: "ALPUJARRA", code: "73024"},
  {municipality: "DOLORES", code: "73236"},
  {municipality: "GUAMO", code: "73319"},
  {municipality: "PRADO", code: "73563"},
  {municipality: "PURIFICACION", code: "73585"},
  {municipality: "SALDANA", code: "73671"},
  {municipality: "SUAREZ", code: "73770"},
  {municipality: "CASABIANCA", code: "73152"},
  {municipality: "HERVEO", code: "73347"},
  {municipality: "LeRIDA", code: "73408"},
  {municipality: "LiBANO", code: "73411"},
  {municipality: "MURILLO", code: "73461"},
  {municipality: "SANTA ISABEL", code: "73686"},
  {municipality: "VENADILLO", code: "73861"},
  {municipality: "VILLAHERMOSA", code: "73870"},
  {municipality: "ANDALUCIA", code: "76036"},
  {municipality: "BUGA", code: "76111"},
  {municipality: "BUGALAGRANDE", code: "76113"},
  {municipality: "CALIMA", code: "76126"},
  {municipality: "EL CERRITO", code: "76248"},
  {municipality: "GINEBRA", code: "76306"},
  {municipality: "GUACARI", code: "76318"},
  {municipality: "RESTREPO", code: "76606"},
  {municipality: "RIOFRIO", code: "76616"},
  {municipality: "SAN PEDRO", code: "76670"},
  {municipality: "TRUJILLO", code: "76828"},
  {municipality: "TULUA", code: "76834"},
  {municipality: "YOTOCO", code: "76890"},
  {municipality: "ALCALa", code: "76020"},
  {municipality: "ANSERMANUEVO", code: "76041"},
  {municipality: "ARGELIA", code: "76054"},
  {municipality: "BOLIVAR", code: "76100"},
  {municipality: "CARTAGO", code: "76147"},
  {municipality: "EL AGUILA", code: "76243"},
  {municipality: "EL CAIRO", code: "76246"},
  {municipality: "EL DOVIO", code: "76250"},
  {municipality: "LA UNION", code: "76400"},
  {municipality: "LA VICTORIA", code: "76403"},
  {municipality: "OBANDO", code: "76497"},
  {municipality: "ROLDANILLO", code: "76622"},
  {municipality: "TORO", code: "76823"},
  {municipality: "ULLOA", code: "76845"},
  {municipality: "VERSALLES", code: "76863"},
  {municipality: "ZARZAL", code: "76895"},
  {municipality: "BUENAVENTURA", code: "76109"},
  {municipality: "CAICEDONIA", code: "76122"},
  {municipality: "SEVILLA", code: "76736"},
  {municipality: "CALI", code: "76001"},
  {municipality: "CANDELARIA", code: "76130"},
  {municipality: "DAGUA", code: "76233"},
  {municipality: "FLORIDA", code: "76275"},
  {municipality: "JAMUNDI", code: "76364"},
  {municipality: "LA CUMBRE", code: "76377"},
  {municipality: "PALMIRA", code: "76520"},
  {municipality: "PRADERA", code: "76563"},
  {municipality: "VIJES", code: "76869"},
  {municipality: "YUMBO", code: "76892"},
  {municipality: "CARURU", code: "97161"},
  {municipality: "MITU", code: "97001"},
  {municipality: "PACOA", code: "97511"},
  {municipality: "PAPUNAHUA", code: "97777"},
  {municipality: "TARAIRA", code: "97666"},
  {municipality: "YAVARATE", code: "97889"},
  {municipality: "CUMARIBO", code: "99773"},
  {municipality: "LA PRIMAVERA", code: "99524"},
  {municipality: "PUERTO CARRENO", code: "99001"},
  {municipality: "SANTA ROSALIA", code: "99624"},

]

const transportArray = [
  { name: "CARGA SAS" },
  { name: "CCM" },
  { name: "CLIENTE" },
  { name: "FABIO MONTOYA" },
  { name: "GINA RENGIFO" },
  { name: "HUGO COTAMO" },
  { name: "IMPOCOMA" },
  { name: "INTERANDINOS" },
  { name: "JOALCO" },
  { name: "LA MACARENA" },
  { name: "LOGINALTRANS" },
  { name: "LOPERTRANS" },
  { name: "MAESTRI ON TRACK" },
  { name: "MARTHA PEÑA" },
  { name: "NUTRION" },
  { name: "PABLO HERNANDEZ" },
  { name: "PEDRO VARELA" },
  { name: "RUTHBEL MONTERO" },
  { name: "RYR" },
  { name: "TRANSER" },
  { name: "TRASLOD" }
];

const destinationNutrion = [
  { Destino: "itagui", TIPO: "Nacional", REGIONAL: "Antioquia" },
  { Destino: "medellin", TIPO: "Nacional", REGIONAL: "Antioquia" },
  { Destino: "rionegro", TIPO: "Nacional", REGIONAL: "Antioquia" },
  { Destino: "Boyaca", TIPO: "Nacional", REGIONAL: "Boyaca" },
  { Destino: "tunja", TIPO: "Nacional", REGIONAL: "Boyaca" },
  { Destino: "cauca", TIPO: "Nacional", REGIONAL: "Cauca" },
  { Destino: "popayan", TIPO: "Nacional", REGIONAL: "Cauca" },
  { Destino: "Barranquilla", TIPO: "Nacional", REGIONAL: "Costa" },
  { Destino: "cartagena", TIPO: "Nacional", REGIONAL: "Costa" },
  { Destino: "Monteria", TIPO: "Nacional", REGIONAL: "Costa" },
  { Destino: "Valledupar", TIPO: "Nacional", REGIONAL: "Costa" },
  { Destino: "la vega", TIPO: "Nacional", REGIONAL: "Cundinamarca" },
  { Destino: "aranzazu", TIPO: "Nacional", REGIONAL: "Eje Cafetero" },
  { Destino: "Armenia", TIPO: "Nacional", REGIONAL: "Eje Cafetero" },
  { Destino: "calarca", TIPO: "Nacional", REGIONAL: "Eje Cafetero" },
  { Destino: "caldas", TIPO: "Nacional", REGIONAL: "Eje Cafetero" },
  { Destino: "cartago", TIPO: "Nacional", REGIONAL: "Eje Cafetero" },
  { Destino: "chinchina", TIPO: "Nacional", REGIONAL: "Eje Cafetero" },
  { Destino: "manizales", TIPO: "Nacional", REGIONAL: "Eje Cafetero" },
  { Destino: "pereira", TIPO: "Nacional", REGIONAL: "Eje Cafetero" },
  { Destino: "salamina", TIPO: "Nacional", REGIONAL: "Eje Cafetero" },
  { Destino: "neiva", TIPO: "Nacional", REGIONAL: "Huila" },
  { Destino: "villavicencio", TIPO: "Nacional", REGIONAL: "Meta" },
  { Destino: "pasto", TIPO: "Nacional", REGIONAL: "Pasto" },
  { Destino: "bucaramanga", TIPO: "Nacional", REGIONAL: "Santander" },
  { Destino: "cucuta", TIPO: "Nacional", REGIONAL: "Santander" },
  { Destino: "giron", TIPO: "Nacional", REGIONAL: "Santander" },
  { Destino: "Santander", TIPO: "Nacional", REGIONAL: "Santander" },
  { Destino: "girardot", TIPO: "Nacional", REGIONAL: "Tolima" },
  { Destino: "ibague", TIPO: "Nacional", REGIONAL: "Tolima" },
  { Destino: "cali", TIPO: "Nacional", REGIONAL: "Valle" },
  { Destino: "tulua", TIPO: "Nacional", REGIONAL: "Valle" },
  { Destino: "valle", TIPO: "Nacional", REGIONAL: "Valle" },
  { Destino: "yopal", TIPO: "Nacional", REGIONAL: "Yopal" },
  { Destino: "Bogota", TIPO: "Urbano", REGIONAL: "Bogota" },
  { Destino: "Cajica", TIPO: "Urbano", REGIONAL: "Funza" },
  { Destino: "funza", TIPO: "Urbano", REGIONAL: "Funza" },
  { Destino: "siberia", TIPO: "Urbano", REGIONAL: "Funza" },
  { Destino: "chia", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "choachi", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "cota", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "facatativa", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "Gachancipa", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "guasca", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "guatavita", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "soacha", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "subachoque", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "subia", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "tenjo", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "tocancipa", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "FUSAGASUGA", TIPO: "Nacional", REGIONAL: "Cundinamarca" },
  { Destino: "Malambo", TIPO: "Nacional", REGIONAL: "Costa" },
  { Destino: "sogamoso", TIPO: "Nacional", REGIONAL: "Boyaca" },
  { Destino: "SIBATE", TIPO: "Urbano", REGIONAL: "Tocancipa" },
  { Destino: "IPIALES", TIPO: "Nacional", REGIONAL: "Pasto" },
  { Destino: "Turbaco", TIPO: "Nacional", REGIONAL: "Costa" },
  { Destino: "Villeta", TIPO: "Nacional", REGIONAL: "Cundinamarca" },
  { Destino: "Mosquera", TIPO: "Nacional", REGIONAL: "Cundinamarca" },
  { Destino: "Maicao", TIPO: "Nacional", REGIONAL: "Costa" },
  { Destino: "Magdalena", TIPO: "Nacional", REGIONAL: "Costa" },
  { Destino: "Fonseca", TIPO: "Nacional", REGIONAL: "Costa" },
  { Destino: "Palmira", TIPO: "Nacional", REGIONAL: "Valle" },
  { Destino: "Barrancabermeja", TIPO: "Nacional", REGIONAL: "Santander" },
  { Destino: "Montenegro", TIPO: "Nacional", REGIONAL: "Eje Cafetero" },
  { Destino: "Piedecuesta", TIPO: "Nacional", REGIONAL: "Santander" }
];


const loanMaxLimit = 100000; 

const bridgeAsset = process.env.REACT_APP_BRIDGE_ASSET;

const enableAccountAsset = process.env.REACT_APP_ENABLE_ACCOUNT_ASSET;

const enablePaymentValue = process.env.REACT_APP_ENABLE_ACCOUNT_PAYMENT_VALUE;

const environmentReferenceFundingAsset = process.env.REACT_APP_REFERENCE_FUNDING_ASSET;

const trustlineLimitValue = process.env.REACT_APP_TRUSTLINE_LIMIT_VALUE;

const wupoHotAddress = process.env.REACT_APP_WUPO_HOT;

const renderFirstView = process.env.REACT_APP_FIRST_VIEW_FOR_USERS;

const loanPaymentProvider = process.env.REACT_APP_PAYMENT_PROVIDER;

const xrplEnabled = process.env.REACT_APP_XRPL_ENABLED;

const b2bClient = process.env.REACT_APP_CLIENT

const isUsernamePhone = process.env.REACT_APP_IS_USERNAME_PHONE;

const isPhoneVerifyEnabled = process.env.REACT_APP_IS_VERIFY_PHONE;

export { exchangeCurrencies, retailerTokens, wupoServerUri, retailOperation, 
  stockOrderSymbol, bridgeAsset, enableAccountAsset, enablePaymentValue, environmentReferenceFundingAsset, 
  trustlineLimitValue, api, retailersTokensReferenceCurrency, wupoHotAddress, creditAsset, creditTerm, 
  loanMaxLimit, renderFirstView, loanPaymentProvider, xrplEnabled, b2bClient, isUsernamePhone, municipalities,
  bankCodes, bankAccountTypes, isPhoneVerifyEnabled, transportArray, destinationNutrion };

export default api;
