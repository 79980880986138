import {wupoServerUri} from '../Api';

const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    let bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
  }

function changeSecondFactor(secondFactorObject, jwtToken){
    console.log(secondFactorObject);
    let enableTOTP = false;
    return(
        fetch(`${wupoServerUri.devnet}/api/secure/handlesecondfactor`, {
            method: "POST",
            body: JSON.stringify(secondFactorObject),
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${jwtToken}`, 
                "Content-Type": "application/json",
            },
        }).then((response) =>{
            console.log(response.status)
            if(response.status === 200){
            //TODO action if totp enabled
            enableTOTP = true;
            return response.arrayBuffer();
            }
        }).then((body) => {
            if(enableTOTP){
                let imgBase64 = arrayBufferToBase64(body);
                let base64Flag = "data:image/jpeg;base64,"
                const img = base64Flag + imgBase64;

                return(img);
            }
            else{
                return(false);
            }
        })
        .catch((error) => {
            console.log("Error msg: " + error);
        })
    )
}

const loginAuth = (loginRequest) => {
    return(
        fetch(`${wupoServerUri.devnet}/api/auth/login`, {
            method: "POST",
            body: JSON.stringify(loginRequest),
            credentials: 'include',
            headers: { 
                "Content-Type": "application/json",
            },
        }).then((response) =>{
            console.log(response.status)
            if(response.status === 200){
                return response.json();
            }
            else{
                return "error"
            }
        }).then((result) => {
            console.log(result);
            return result;
        })
        .catch((error) => {
            console.log("Error msg: " + error);
        })
    )
}


export {changeSecondFactor, loginAuth}