import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {getInventory} from "../../utilityFunctions/WarehouseUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function Inventory(props) {

    const {loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        renderInventory();
    }, []);

    const initialValues = async () => {
        const products = await getInventory(loginUser.jwtToken);

        return products;
    };

    const [inventory, setInventory] = React.useState("");

    const renderInventory = async() => {
        setLoading(true);
        const productsInventory = await initialValues();
        setLoading(false);

        if(!productsInventory){
            return;
        }

        productsInventory.sort((a, b) => {
            const nameA = a.description.toLowerCase();
            const nameB = b.description.toLowerCase();
            
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });

        setInventory(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {productsInventory.map((product) => {
                    return(
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton key={generateRandomKey()} onClick={() => dailyInventory(product)}>
                                <Grid key={generateRandomKey()}  container spacing={1}>
                                    <Grid key={generateRandomKey()} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{product.barCode}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{product.description}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{new Intl.NumberFormat('es-CO').format(product.totalInventory)}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        );
    };

    const dailyInventory = (product) => {
        if(!product){
            return;
        }

        history.push({
            pathname: "/warehouse/daily-inventory",
            state: {
                product: product,
            }
        })
    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container sx={{marginTop: "1rem"}}>
                        <Grid key={generateRandomKey()} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                            <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Código de Barras</Typography>
                        </Grid>
                        <Grid key={generateRandomKey()} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                            <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Producto</Typography>
                        </Grid>
                        <Grid key={generateRandomKey()} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                            <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Cantidad</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {inventory}
                </Grid>
            </Grid>
            }
            </Box>
        </Box>
    )
}
