import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Colors from "../../components/ui/Colors";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory} from "react-router-dom";

export default function ActiveBatches(props) {

    React.useEffect(() => {
        renderBatches();
    }, []);

    const initialValues = async () => {

        if(!props.location.state || !props.location.state.order || !props.location.state.order.deliveryOrderResponse){
            
            return;
        }
        
        const orders = props.location.state.order.deliveryOrderResponse;

        // console.log(orders);

        return orders;
    };

    const [batchesList, setBatchesList] = React.useState("");

    const renderBatches = async () => {

        const orders = await initialValues();

        if(!orders){
            return;
        }

        setBatchesList(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {orders.flatMap((order) => {
                    if(order.productBatchesResponse){
                        return(
                            order.productBatchesResponse.map((batch) => {
                                return(
                                    <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                        <Grid key={generateRandomKey()}  container spacing={1}>
                                            <Grid key={generateRandomKey()} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                                <Typography key={generateRandomKey()} variant="body2">{batch.warehouseProduct.description}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                                <Typography key={generateRandomKey()} variant="body2">{batch.position}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                                <Typography key={generateRandomKey()} variant="body2">{batch.inWarehouse === false ? "Entregada" : "Pendiente"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                )
                            })
                        )
                    }
                    return []; // Return an empty array if there are no batches
                })

                }
            </List>
        );

    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
                <Grid container>
                    {props.location.state && props.location.state.order &&
                    <>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container direction="column">
                            <Grid item sx={{textAlign: "center"}}>
                                <Typography variant="body" sx={{color: "gray",}}>Placa Transporte</Typography>
                            </Grid>
                            <Grid item sx={{textAlign: "center"}}>
                                <Typography variant="h6" sx={{fontWeight: "bold", marginBottom: "0.5rem"}}>{props.location.state.order.carPlate}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "0.5rem", marginBottom: "1rem"}}>
                        <Divider />
                    </Grid>
                    </>
                    }
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid  container spacing={1}>
                            <Grid  item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Producto</Typography>
                            </Grid>
                            <Grid  item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Ubicación</Typography>
                            </Grid> 
                            <Grid  item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Estado</Typography>
                            </Grid> 
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {batchesList}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
