import React from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import Colors from "../../components/ui/Colors";
import MenuItem from '@mui/material/MenuItem';
import { qryproductBatch, qryTotalInventory, qryDailyInventory, qryproductBatchCedi} from "../../utilityFunctions/WarehouseUtil";
import swal from "sweetalert";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const reports = ["Estibas", "Inventario", "Inventario diario"];

const RequestObject = {
    qryType: "",
};

export default function WarehouseCSV(props) {

    const{loginUser} = props;

    const[loading, setLoading] = React.useState(false);

    const[report, setReport] = React.useState("");

    const reportSelection = (e) => {
        setReport(e.target.value);
        RequestObject.qryType=e.target.value;
    }

    const BatchesCsvRequest = async () => {
        setLoading(true);
        // const settlements = await qryproductBatch(loginUser.jwtToken);
        const settlements = await qryproductBatchCedi(loginUser.jwtToken);
        setLoading(false);

        if(!settlements){
            swal({
                title: "Error",
                text: "Error de descarga. Vuelve a intentarlo",
                icon: "info",
                button: "ok",
            });
            return; 
        }

        swal({
            title: "Descarga Exitosa",
            icon: "success",
            button: "ok",
        });
        return; 
    };

    const inventoryCsvRequest = async () => {
        setLoading(true);
        const settlements = await qryTotalInventory(loginUser.jwtToken);
        setLoading(false);

        if(!settlements){
            swal({
                title: "Error",
                text: "Error de descarga. Vuelve a intentarlo",
                icon: "info",
                button: "ok",
            });
            return; 
        }

        swal({
            title: "Descarga Exitosa",
            icon: "success",
            button: "ok",
        });
        return; 
    };

    const dailyCsvRequest = async () => {
        setLoading(true);
        const settlements = await qryDailyInventory(loginUser.jwtToken);
        setLoading(false);

        if(!settlements){
            swal({
                title: "Error",
                text: "Error de descarga. Vuelve a intentarlo",
                icon: "info",
                button: "ok",
            });
            return; 
        }

        swal({
            title: "Descarga Exitosa",
            icon: "success",
            button: "ok",
        });
        return; 
    };

    const handleCsvRequest = () => {
        if(RequestObject.qryType === "Estibas"){
            BatchesCsvRequest();
            return;
        }
        else if(RequestObject.qryType === "Inventario"){
            inventoryCsvRequest();
            return;
        }else if(RequestObject.qryType === "Inventario diario"){
            dailyCsvRequest();
            return;
        }

    }

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
                height: "100%",
                textAlign: "center",
                marginTop: {xs: "1.3rem", sm: 0},
            }}
        >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2} sx={{width: { xs: "95%", sm: "80%", md: "40%"}}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            sx={{textAlign: "start"}}
                            variant="standard"
                            fullWidth={true}
                            select
                            label={<Typography variant="body2">Reporte</Typography>}
                            value={report}
                            onChange={(e)=>reportSelection(e)}
                            autoComplete="off"
                        >
                            {reports.map((el) => (
                                <MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </TextField> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={handleCsvRequest} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                            Descargar
                        </Button>
                    </Grid>
                </Grid>
            
            }
        </Box>
    </Box>
    )
}
