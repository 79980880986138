import {wupoServerUri, retailerTokens, retailersTokensReferenceCurrency } from "../Api";
import swal from "sweetalert";
import operationUnsuccesful from "../Texts/OperationResult.js";


function ilpAccountFetcherUtil(jwtToken){
    return new Promise((resolve, reject)=>{
        fetch(`${wupoServerUri.devnet}/api/secure/fetchaccount`, {
            method: 'GET',
            //body: JSON.stringify(accountFetchInfo),
            headers: {
                'Authorization': `Bearer ${jwtToken}`, 
                'Content-Type': 'application/json'                   
            },
          
        }).then(response=>{ 
        return response.text();

        }).then(body=>{
            console.log("account Fetch response text: " + body)
            resolve(
                body
            );
        }).catch(error=>{
            console.log("Error msg: "+ error);
            reject(
                error
            );
        });

    })
}

// const RippleAPI = require('ripple-lib').RippleAPI;

// const api = new RippleAPI({
//     server: process.env.REACT_APP_XRPL_SERVER // Public rippled server hosted by Ripple, Inc.
// }); 

const api = "";

let retryCounter = 0;

function fetchXrpl(fetchType, fetchedAddress){
    console.log(fetchType)
    console.log(fetchedAddress)
    return new Promise((resolve, reject)=>{
        try{
            api.on('error', (errorCode, errorMessage) => {
                console.log(errorCode + ': ' + errorMessage);
            });
        
            api.connect().then(() => {
                // console.log("fetch type: " + fetchType); // DEBUG PRINTING
                if(fetchType==="BalanceSheet"){
                    const address = fetchedAddress;
                    api.getBalanceSheet(address).then(info =>{
                        console.log(JSON.stringify(info)) // DEBUG PRINTING
                        resolve(
                            info
                        );
                    });
                }

                else if(fetchType==="Balance"){
                    const address = fetchedAddress;
                    api.getBalances(address).then((balance) => {
                        resolve(
                            balance
                        );
                    });
                }

            }).catch(err=>{
                
                retryCounter++;
              if(retryCounter <= 20){
                fetchXrpl(fetchType, fetchedAddress);
              }else{
                retryCounter = 0;
                console.log(err)
                reject(err);
                swal({
                  title: "NO PUDIMOS HACER TU PAGO",
                  text: operationUnsuccesful,
                  icon: "error",
                  button: "Entiendo",
                });
              }

            });
        }catch(err){
            reject();
        }
    })    
}

function assetRates(fromAsset, toAsset, jwtToken){
    return new Promise((resolve, reject)=>{
        const assetPairs = {
            finalFromAsset: fromAsset,
            finalFromAssetQuote: 1,
            finalToAsset: toAsset,
            finalToAssetQuote: 1
        }
            stockRates(fromAsset, jwtToken)
            .then(fromResult=>{
                // console.log("From result: " + JSON.s    (fromResult))
                assetPairs.finalFromAsset = fromResult.asset;
                assetPairs.finalFromAssetQuote = fromResult.assetQuote
                stockRates(toAsset, jwtToken)
                .then(toResult=>{
                    // console.log("To result: " + JSON.stringify(toResult))
                    assetPairs.finalToAsset = toResult.asset;
                    assetPairs.finalToAssetQuote = toResult.assetQuote
    
                    fetch(`https://min-api.cryptocompare.com/data/price?fsym=${assetPairs.finalFromAsset}&tsyms=${assetPairs.finalToAsset}`, {
    
                        method: 'GET',
              
                    }).then(function (response){ 
                        return response.json();
    
                    }).then(function(body){
                        
                        // console.log("Cryptocompare response: " + JSON.stringify(body)) // DEBUG PRINTING
                        // console.log("to Asset: " + toAsset)
                        // console.log("convertion rate: " + body[toAsset])
                        // const assetTemp=toAsset
                        const currencyRate=body[assetPairs.finalToAsset]
                        const quotesRates= (assetPairs.finalFromAssetQuote/assetPairs.finalToAssetQuote)
                        // console.log("Quotes rates: " + quotesRates) // DEBUG PRINTING
                        // console.log("currencyRate: " + currencyRate) // DEBUG PRINTING
                        resolve(quotesRates*currencyRate);
                        // return String(currencyRate)
                        
                    }).catch(function(error){
                        console.log("Error msg: "+ error);
                        reject(error);
                    })
    
                })
    
            })
    })

    
}

function stockRates(asset, jwtToken){
    return new Promise((resolve, reject)=>{
        const stockAssets=["AAPL", "GOOG"]; 
        let isStock=false;
        const referenceAsset="USD";
        const finalAssets={
            asset: asset,
            assetQuote: 1,
        }      
        const CONTROLTOKEN={
        token: "" //Not used for security
        };

        stockAssets.forEach(element=>{
            if(asset===element){
                isStock=true;
            }
        })

        if(isStock){
            finalAssets.asset=referenceAsset;
            fetch(`${wupoServerUri.devnet}/api/secure/stockFxAccessToken`, {

            method: 'POST',
            body: JSON.stringify(CONTROLTOKEN),
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'                     
            }
                
            }).then(function (response){ 
                return response.text();
        
            }).then(function(body){
                
                // console.log("Ameritrade token: " + body)
                
                //Fetch Ameritrade with the access token obtained from Oauth2 by the back-end
                fetch(`https://api.tdameritrade.com/v1/marketdata/${asset}/quotes`, {

                method: 'GET',
                headers: {
                'Authorization': `Bearer ${body}`
                // 'Content-Type': 'application/json'                     
                }
                
                }).then(function (response){ 
                    return response.json();
            
                }).then(function(body){
                    
                    // console.log("Ameritrade response: " + JSON.stringify(body))
                    finalAssets.assetQuote = body[asset].closePrice;
                    // console.log("stock rate: " + finalAssets.assetQuote) // DEBUG PRINTING
                    resolve(finalAssets);
                    
                }).catch(function(error){
                    console.log("Error msg: "+ error);
                    reject(error);
                })
                
            }).catch(function(error){
                console.log("Error msg: "+ error);
                reject(error);
            })
        }else{
            resolve(finalAssets);
        }
    })
}

const RETAILERSREFERENCECURRENCY = retailersTokensReferenceCurrency;
function fetchAssetRates(assetsToFetch, jwtToken){
    // console.log(retailerTokens); // DEBUG PRINTING
    // retailerTokens.forEach(element=>{
    //     if(element.asset===assetsToFetch.fromAsset || element.label===assetsToFetch.fromAsset){
    //         assetsToFetch.fromAsset=RETAILERSREFERENCECURRENCY;
    //     } 
        
    //     if(element.asset===assetsToFetch.toAsset || element.label===assetsToFetch.toAsset){
    //         assetsToFetch.toAsset=RETAILERSREFERENCECURRENCY;
    //     }
    // });
    // console.log(assetsToFetch) // DEBUG PRINTING
    // console.log("url: " + wupoServerUri.devnet)
    // console.log(serverWupo)
    return(
        fetch(`${wupoServerUri.devnet}/api/secure/rate`, {

            method: 'POST',
            body: JSON.stringify(assetsToFetch),
            headers: {
                "Authorization": `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'                   
            },

        }).then(function (response){
            return response.json();

        }).then(function(body){
            // console.log(JSON.stringify(body)) // DEBUG PRINTING
            // console.log("fetched rate " + body.rate)
            return body;
            
        }).catch(function(error){
            console.log("Error msg: "+ error);
        })
    )

}

async function cryptoQuote(from, to){
   return(
    fetch(`https://min-api.cryptocompare.com/data/price?fsym=${from}&tsyms=${to}`, {
    
        method: 'GET',

    }).then(function (response){ 
        return response.json();

    }).then(function(body){
        
        // console.log("Cryptocompare response: " + JSON.stringify(body)) // DEBUG PRINTING
        // console.log("crytp rate: " + body[to]) // DEBUG PRINTING
        return body[to]
        
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
   )
}

function xrplSecretFetch(secret, jwtToken){
    const clientSecret = {
        xrplSecret: secret
    }
    return(
        fetch(`${wupoServerUri.devnet}/api/secure/ledger/accounts/xrplsecret`, {

            method: 'POST',
            body: JSON.stringify(clientSecret),
            headers: {
                "Authorization": `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'                   
            },

        }).then(function (response){
            
            return response.text();

        }).then(function(body){
            // console.log(body);
            return body;
            
        }).catch(function(error){
            console.log("Error msg: "+ error);
        })
    )
}

function loadXrpAddressCreds (jwtToken) {
    return(
        fetch(`${wupoServerUri.devnet}/api/secure/xrpwupocreds`, {
            method: "GET",
            headers: {
              "Authorization": `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
        })
        .then((response) => {
            return response.json(); 
        })
        .then((data) => {
            // console.log(data); // DEBUG PRINTING
            return data;
        })
        .catch((error) => {
            console.log("Error: " + error);
        })
    )

  };

function fetchStatement(statementAccountId, jwtToken){
    // console.log(statementAccountId) // DEBUG PRINTING

    return(
        fetch(`${wupoServerUri.devnet}/api/secure/statements`, {
            method: "POST",
            body: JSON.stringify(statementAccountId),
            headers: {
              "Authorization": `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
        })
        .then((response) => {
            return response.json(); 
        })
        .then((data) => {
            console.log(data); // DEBUG PRINTING
            
            return data;
        })
        .catch((error) => {
            console.log("Error: " + error);
        })
    )
}

function fetchDevicesUsers(FPVerification){
    console.log(FPVerification) //DEBUG
    console.log(wupoServerUri.devnet) //DEBUG
    return(
        fetch(`${wupoServerUri.devnet}/api/auth/autocompleteLogin`, {
            method: "POST",
            body: JSON.stringify(FPVerification),
            credentials: 'include',
            headers: {
              "Content-Type": "application/json",
            },
        })
        .then((response) => {
            console.log(response.status);
            if(response.status === 200){
                return response.json();
            }
            else{
                return "Not found";
            }
        })
        .then((userLogin) => {
            // console.log(userLogin); // DEBUG PRINTING
            
            return userLogin;
        })
        .catch((error) => {
            console.log("Error: " + error);
        })
    )
}

function fetchVisitorAccounts(visitorUsername, jwtToken){
    
    const visitorAccountsRequest = {
        username: visitorUsername,
        password: "notValid",
    }
    // console.log(visitorAccountsRequest) //LOANS DEBUG PRINTING

    return(
        fetch(`${wupoServerUri.devnet}/api/secure/clientaccounts`, {
            method: "POST",
            body: JSON.stringify(visitorAccountsRequest),
            credentials: 'include',
            headers: {
              "Authorization": `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
        })
        .then((response) => {
            console.log(response.status);
            if(response.status === 302){
                return response.json();
            }
            else{
                return "Not found";
            }
        })
        .then((visitorAccounts) => {
            console.log(visitorAccounts); // //LOANS DEBUG PRINTING
            
            return visitorAccounts;
        })
        .catch((error) => {
            console.log("Error: " + error);
        })
    )
}

function phoneValidation(verificationObj, jwtToken){
    console.log(verificationObj) //LOANS DEBUG PRINTING

    return(
        fetch(`${wupoServerUri.devnet}/api/secure/phoneverify`, {
            method: "POST",
            body: JSON.stringify(verificationObj),
            credentials: 'include',
            headers: {
              "Authorization": `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
        })
        .then((response) => {
            console.log(response.status);
            return response.json();
            // if(response.status === 200){
            //     return response.json();
            // }
            // else{
            //     return "Not found";
            // }
        })
        .then((verification) => {
            console.log(verification); // //LOANS DEBUG PRINTING
            
            return verification;
        })
        .catch((error) => {
            console.log("Error: " + error);
        })
    )
}

function phoneCode(visitorphone, jwtToken){
    console.log(visitorphone) //LOANS DEBUG PRINTING

    return(
        fetch(`${wupoServerUri.devnet}/api/secure/phonecode`, {
            method: "POST",
            body: JSON.stringify(visitorphone),
            credentials: 'include',
            headers: {
              "Authorization": `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
        })
        .then((response) => {
            console.log(response.status);
            return response.json();
            // if(response.status === 200){
            //     return response.json();
            // }
            // else{
            //     return "Not found";
            // }
        })
        .then((verification) => {
            console.log(verification); // //LOANS DEBUG PRINTING
            
            return verification;
        })
        .catch((error) => {
            console.log("Error: " + error);
        })
    )
}

const fetchWupoAssets = (jwtToken)=> {
    
    return(
        fetch(`${wupoServerUri.devnet}/api/secure/availabletokens`, {
            method: "GET",
            credentials: 'include',
            headers: {
              "Authorization": `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
        })
        .then((response) => {
            console.log(response.status);
            if(response.status === 200){
                return response.json();
            }
            else{
                return "Not found";
            }
        })
        .then((availableAssets) => {
            console.log(availableAssets); // DEBUG PRINTING
            
            return availableAssets;
        })
        .catch((error) => {
            console.log("Error: " + error);
        })
    )

}

const fetchSingleClientsInformation = (clientAccount, jwtToken) => {
    console.log(clientAccount);
    const clietnRequest={
        account: clientAccount,
    }
    return(
      fetch(`${wupoServerUri.devnet}/api/auth/getclientprofile`, {
          
          method: 'POST',
          body: JSON.stringify(clietnRequest),
          headers: {
            'Authorization': `Bearer ${jwtToken}`, 
            'Content-Type': 'application/json'                     
          }
          
        }).then((response) => { 
          // console.log(response.status) // DEBUG PRINTING
  
          if(response.status === 200){
            console.log(response.status);
            return response.json();
          }
          else{
              return "Error";
          }
        
        }).then((clientInfo) => {
            console.log(clientInfo)  ; 
            return(clientInfo);
            
      }).catch(function(error){
          console.log("Error msg: "+ error);
      })
    );
  
  };

export{ilpAccountFetcherUtil, fetchXrpl, assetRates, stockRates, fetchAssetRates, 
    cryptoQuote, xrplSecretFetch, loadXrpAddressCreds, fetchStatement, fetchDevicesUsers, 
    fetchVisitorAccounts, phoneValidation, phoneCode, fetchWupoAssets,fetchSingleClientsInformation }