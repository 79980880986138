import React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../components/ui/Colors";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {newMasterProduct} from "../../utilityFunctions/WarehouseUtil.js";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let productRequest = {
    barCode: "",
    itemNumber: "",
    description: "",
    content: "",
    unit: "",
    unitsPerBale: "",
    balesPerLevel: "",
    internalCode: "",
};

export default function NewWarehouseProduct(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        productRequest = {
            barCode: "",
            itemNumber: "",
            description: "",
            content: "",
            unit: "",
            unitsPerBale: "",
            balesPerLevel: "",
            internalCode: "",
        };
    }, []);

    const[barCode, setBarCode] = React.useState("");
    const[itemNumber, setItemNumber] = React.useState("");
    const[description, setDescription] = React.useState("");
    const[content, setContent] = React.useState("");
    const[unit, setUnit] = React.useState("");
    const[unitsPerBale, setUnitsPerBale] = React.useState("");
    const[balesPerLevel, setBalesPerLevel] = React.useState("");
    const[internalCode, setInternalCode] = React.useState("");

    const barCodeChange = (e) => {
        setBarCode(e.target.value);
        productRequest.barCode = e.target.value;
    };

    const itemNumberChange = (e) => {
        setItemNumber(e.target.value);
        productRequest.itemNumber = e.target.value;
    };

    const descriptionChange = (e) => {
        setDescription(e.target.value);
        productRequest.description = e.target.value;
    };

    const contentChange = (e) => {
        // console.log(e.floatValue);
        setContent(e.floatValue);
        productRequest.content = e.floatValue;
    };

    const unitChange = (e) => {
        setUnit(e.target.value);
        productRequest.unit = e.target.value;
    };

    const unitsPerBaleChange = (e) => {
        // console.log(e.floatValue);
        setUnitsPerBale(e.floatValue);
        productRequest.unitsPerBale = e.floatValue;
    };

    const balesPerLevelChange = (e) => {
        // console.log(e.floatValue);
        setBalesPerLevel(e.floatValue);
        productRequest.balesPerLevel = e.floatValue;
    };

    const internalCodeChange = (e) => {
        setInternalCode(e.target.value);
        productRequest.internalCode = e.target.value;
    };

    const submitProduct = async() => {

        setLoading(true);
        const receivedProduct = await newMasterProduct(productRequest, loginUser.jwtToken);
        setLoading(false);

        if(!receivedProduct){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        swal({
            title: "Actualización exitosa",
            icon: "success",
            buttons: "OK"
        });
    };


    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="barCode"
                            name="barCode"
                            label={<Typography variant="body2">Código de Barras</Typography>}
                            value={barCode}
                            onChange={(e)=> barCodeChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="itemNumber"
                            name="itemNumber"
                            label={<Typography variant="body2">Número de Artículo</Typography>}
                            value={itemNumber}
                            onChange={(e)=> itemNumberChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="description"
                            name="description"
                            label={<Typography variant="body2">Descripción</Typography>}
                            value={description}
                            onChange={(e)=> descriptionChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "90%"}}
                            name="content"
                            customInput={TextField}
                            // prefix={'$ '}
                            // suffix={' meses'}
                            type="content"
                            label={<Typography variant="body2">Contenido</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={content}
                            onValueChange={(values) => {
                                contentChange(values);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="unit"
                            name="unit"
                            label={<Typography variant="body2">Unidad Medida</Typography>}
                            value={unit}
                            onChange={(e)=> unitChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "90%"}}
                            name="unitsPerBale"
                            customInput={TextField}
                            // prefix={'$ '}
                            // suffix={' meses'}
                            type="unitsPerBale"
                            label={<Typography variant="body2">Unidades Paca</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={unitsPerBale}
                            onValueChange={(values) => {
                                unitsPerBaleChange(values);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "90%"}}
                            name="balesPerLevel"
                            customInput={TextField}
                            // prefix={'$ '}
                            // suffix={' meses'}
                            type="balesPerLevel"
                            label={<Typography variant="body2">Pacas por Nivel</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={balesPerLevel}
                            onValueChange={(values) => {
                                balesPerLevelChange(values);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="internalCode"
                            name="internalCode"
                            label={<Typography variant="body2">Código Interno</Typography>}
                            value={internalCode}
                            onChange={(e)=> internalCodeChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={submitProduct} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                            Cargar
                        </Button>
                    </Grid>
                </Grid>
            }
            </Box>
        </Box>
    )
}
