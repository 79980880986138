import React from 'react';
import {getProductByDescription, enableBatchesForPickup, disableForPickup, getTransportProductByDescription,
    manualEnableForPickup} from "../../utilityFunctions/WarehouseUtil";
import swal from "sweetalert";
import FormControlLabel from '@mui/material/FormControlLabel';
import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Colors from "../../components/ui/Colors";
import Divider from '@mui/material/Divider';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

let batchesArray;

let areInitialBatches = false;

let initialChecksExecuting = true;

export default function BatchesForOrders(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const [batchList, setBatchList] = React.useState(null);
    const [selectedBatch, setSelectedBatch] = React.useState([]);
    const [totalOrderAmount, setTotalOrderAmount] = React.useState(0);

    React.useEffect(()=> {
        setLoading(true);
        areInitialBatches = false;
        batchesArray=null;
        initialValues();
        setLoading(false);
    }, [])

    const initialValues = async () => {
        if(!props.location.state.batchesRequest.order){
            return;
        }

        const potentialproductsReq = {
            id: props.location.state.batchesRequest.order.id,
            description: props.location.state.batchesRequest.description,
            itemNumber: props.location.state.batchesRequest.itemNumber,
        };

        const receivedBatches = await getTransportProductByDescription(potentialproductsReq, loginUser.jwtToken);

        if(!receivedBatches){
            return;
        }

        // const filterBatches = receivedBatches.filter((batch) => !batch.deliveryOrder || (batch.deliveryOrder && batch.deliveryOrder.id === props.location.state.order.id));
        
        batchesArray = receivedBatches;
        
        await initialChecks(receivedBatches);
        // setSelectedBatch([...selectedBatch, ...batchesArray]);
        
        if(areInitialBatches === false){
            renderBatchesList();
        }

        // console.log(batchesArray);

        return receivedBatches;
    };

    // Function to handle order checkbox change
    const batchChecked = (batch) => {
        // Toggle the checked state of the order
        const isChecked = selectedBatch.includes(batch);
        if (isChecked) {
            // If already selected, unselect the order
            setSelectedBatch(selectedBatch.filter((batchEl) => batchEl !== batch));
            // Subtract the order amount from the total
            setTotalOrderAmount(totalOrderAmount - batch.productAmount);
        } else {
            // console.log(batch.id)
            // console.log(batch.productAmount)
            // If not selected, select the order
            setSelectedBatch([...selectedBatch, batch]);
            // Add the order amount to the total
            setTotalOrderAmount(totalOrderAmount + batch.productAmount);
        }
    };

    const initialChecks = (initialBatches) => {

        const activeArray = initialBatches.filter((el) => el.deliveryOrder);
        
        return new Promise((resolve) => {
            if(activeArray){
                areInitialBatches=true;
                activeArray.forEach((batch) => {
                    // batchChecked(batch);
                    setTotalOrderAmount((prevTotalOrderAmount) => prevTotalOrderAmount + batch.productAmount);
                })
                
                setSelectedBatch(activeArray);
                resolve(totalOrderAmount);
            }
            else{
                resolve(totalOrderAmount); 
            }
        })
    };

    // Function to handle selecting an order when clicking on the list item
    const selectBatch = (batch) => {

        if(batch.deliveryOrder && selectedBatch.includes(batch)){
            swal({
                title: "confirmación",
                text: "Confirmación desactivación estiba.",
                icon: "info",
                buttons: ["Atrás", "Confirmo"]
            }).then((value)=>{
                if(value === true){
                    executeDisable(batch);
                    return;
                } 
                
            });
        }

        else{
            batchChecked(batch);
        }

        
    };

    const executeDisable = async (batch) => {

        const disableObject = {
            id: batch.id,
        };

        setLoading(true);
        const receivedBatch = await disableForPickup(disableObject, loginUser.jwtToken);
        setLoading(false);

        if(!receivedBatch){
            return;
        }
        
        batchChecked(batch);
    };

    React.useEffect(()=> {
        renderBatchesList();
    }, [selectedBatch]);


    const renderBatchesList = async () => {
        
        if(!batchesArray){
            return;
        }

        batchesArray.sort((a, b) => {

            const codeA = a.expirationDate;
            const codeB = b.expirationDate;

            if (codeA < codeB) {
                return -1;
            } else if (codeA > codeB) {
                return 1;
            } else {
                return 0;
            }
        });

        setBatchList(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {batchesArray.map((batch)=> {
                    const isChecked = selectedBatch.includes(batch);
                    return(
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton key={generateRandomKey()} onClick={() => selectBatch(batch)}>
                                <Grid key={generateRandomKey()}  container spacing={1}>
                                    <Grid item xs={2} sm={2} md={2} style={{textAlign: "center"}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="secondary" name="order" value="yes" 
                                                    checked={isChecked}
                                                    onChange={() => selectBatch(batch)}
                                                />
                                                
                                            }
                                        />
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.expirationDate))}`}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{batch.warehouseProduct.description}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{batch.warehouseProduct.itemNumber}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{new Intl.NumberFormat('es-CO').format(batch.productAmount)}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{`${batch.position}${batch.positionLevel}`}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    )
                })

                }
            </List>
        );
    }

    const enableSelectedBatches = async () => {
        const enableObject = {
            orderId: props.location.state.batchesRequest.order.id,
            batches: selectedBatch,
            description: props.location.state.batchesRequest.description,
            itemNumber: props.location.state.batchesRequest.itemNumber,
        };

        const orderEnabled = await manualEnableForPickup(enableObject, loginUser.jwtToken);

        if(!orderEnabled){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        swal({
            title: "Actualización exitosa",
            icon: "success",
            buttons: "OK"
        });
    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : 
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            
                <Grid container>
                    <Grid item xs={4} sm={4} md={4} sx={{textAlign: "center", marginTop:"1.5rem"}}>
                        <Typography variant="body1" sx={{color: "black",}}>Cantidad Seleccionada</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} sx={{textAlign: "center", marginTop:"1.5rem"}}>
                        <Typography variant="body1" sx={{color: "black",}}>Cantidad Necesaria</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} sx={{textAlign: "center", marginTop:"1.5rem"}}>
                        <Typography variant="body1" sx={{color: "black",}}>Neto</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} sx={{textAlign: "center", marginTop: "0.5rem"}}>
                        <Typography variant="h5" sx={{color: "black", fontWeight: "bold"}}>{new Intl.NumberFormat('es-CO').format(totalOrderAmount)}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} sx={{textAlign: "center", marginTop: "0.5rem"}}>
                        <Typography variant="h5" sx={{color: "black", fontWeight: "bold"}}>{new Intl.NumberFormat('es-CO').format(props.location.state.batchesRequest.amount)}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} sx={{textAlign: "center", marginTop: "0.5rem"}}>
                        <Typography variant="h5" sx={{color: "black", fontWeight: "bold"}}>{new Intl.NumberFormat('es-CO').format(props.location.state.batchesRequest.amount - totalOrderAmount)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "1rem"}}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {batchList}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={enableSelectedBatches} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem", marginBottom: "2rem"}}>
                            Activar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            }
        </Box>
    )
}
