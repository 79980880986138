import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import { b2bClient, municipalities} from "../../Api";
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
      marginRight:0
    },
    dialogStyle:{
      whiteSpace: "pre-line"
    }, 
    link: {
      color: "blue",
      cursor: "pointer",
    }
}));

const clientTypes = [
  {
    type: "person",
    label: "Persona Natural"
  },
  {
    type: "company",
    label: "Persona Jurídica"
  },
];

const clientIdTypes = [
  {
    type: "nationalId",
    label: "Cédula"
  },
  {
    type: "passport",
    label: "Pasaporte"
  },
  {
    type: "foreignId",
    label: "Cédula extranjería"
  },
];

const representativeIdTypes =[
  {
    type: "nationalId",
    label: "Cédula"
  },
  {
    type: "passport",
    label: "Pasaporte"
  },
  {
    type: "foreignId",
    label: "Cédula extranjería"
  },
];

export default function IDInformation(props) {
    const{
        dataForParent,
        setMunicipality
    }=props
    const classes = useStyles();

    React.useEffect(() => {
      sortMunicipalities();
    }, []);

    const[renderMunicipalities, setRenderMunicipalities]= React.useState("");

    const sortMunicipalities = () => {
      municipalities.sort((a, b) => {

        const codeA = a.municipality.toUpperCase();
        const codeB = b.municipality.toUpperCase();

        if (codeA < codeB) {
            return -1;
        } else if (codeA > codeB) {
            return 1;
        } else {
            return 0;
        }
      });

      setRenderMunicipalities(
        {
          options: municipalities,
          getOptionLabel: (option) => option.municipality,
  
        }
      );
    };

    const [id, setID] = React.useState('');
  
    const handleChange = (event) => {
        dataForParent(event);
    }; 

    const [idType, setIdType] = React.useState("");

    const idTypeChange = (e) => {
      setIdType(e.target.value);
      dataForParent(e);
    };
    
    const handleSelectChange=(event) => {
        setID(event.target.value);
        dataForParent(event);

    }

    const onChangeAutocomplete = (event, value) => {
      // console.log(value);
      if(!value){
        return;
      }
      
      setMunicipality(value);
    };

    const[clientType, setClientType] = React.useState("");

    const clientTypeChange = (e) => {
      setClientType(e.target.value);
      dataForParent(e);
    };

    const[representativeIdType, setRepresentativeIdType] = React.useState("");

    const representativeIdTypeChange = (e) => {
      setRepresentativeIdType(e.target.value);
      dataForParent(e);
    };


    return (
      <Box
        sx={{
          display: "flex",
          // flexGrow: "1",
          width: "100%",
          // height: "80%",
          justifyContent: "center",
          alignContent:"center",
          // marginLeft: "1.5rem",
        }}
      >
        
        <Grid container spacing={3} sx={{justifyContent: "center", width: "95%"}}>
          <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
              <TextField
                  sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                  variant="standard"
                  fullWidth={true}
                  id="clientType"
                  name="clientType"
                  select
                  label={<Typography variant="body2">Tipo de persona</Typography>}
                  value={clientType}
                  onChange={(e)=>clientTypeChange(e)}
                  autoComplete="off"
              >
                  {clientTypes.map((typeEl) => (
                      <MenuItem key={typeEl.type} value={typeEl.type}>
                          {typeEl.label}
                      </MenuItem>
                  ))}
              </TextField> 
          </Grid>

          {clientType && clientType==="person" &&
            <>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="firstName"
                name="firstName"
                label="Nombres"
                fullWidth
                autoComplete="given-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="lastName"
                name="lastName"
                label="Apellidos"
                fullWidth
                autoComplete="family-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                  sx={{textAlign: "start"}}
                  variant="standard"
                  fullWidth={true}
                  id="idType"
                  name="idType"
                  select
                  label={<Typography variant="body2">Tipo de identificación</Typography>}
                  value={idType}
                  onChange={(e)=>idTypeChange(e)}
                  autoComplete="off"
              >
                  {clientIdTypes.map((typeEl) => (
                      <MenuItem key={typeEl.type} value={typeEl.type}>
                          {typeEl.label}
                      </MenuItem>
                  ))}
              </TextField> 
          </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="idNumber"
                name="idNumber"
                label="Número id"
                fullWidth
                autoComplete="idNumber"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="Address"
                name="Address"
                label="Dirección"
                fullWidth
                autoComplete="given-address"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete sx={{ width: "100%"}}
                {...renderMunicipalities}
                
                id="Municipality"
                name="Municipality"
                clearOnEscape
                autoComplete = {true}
                noOptionsText = {"Municipio no encontrado"}
                fullWidth
                onChange={onChangeAutocomplete}
                // onInputChange={(event, value) => {
                //   const filteredOptions = municipalities.filter((option) =>
                //     option.municipality.toUpperCase().includes(value.toUpperCase())
                //   );
                //   updateMunicipalities(filteredOptions);
                // }}
                renderInput={(params) => (
                  <TextField  {...params} label="Municipio" variant="standard" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    variant="standard"
                    // required
                    id="phone"
                    type="tel"
                    name="phone"
                    label="Número de teléfono"
                    fullWidth
                    // value={value}
                    autoComplete="given-phone"
                    onChange={handleChange}
                    inputProps={{ maxLength: 10, minLength: 10}}
                  />
            </Grid>
            </>
          }
          {clientType && clientType==="company" &&
            <>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="firstName"
                name="firstName"
                label="Razón Social"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="idNumber"
                name="idNumber"
                label="NIT"
                fullWidth
                autoComplete="idNumber"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="Address"
                name="Address"
                label="Dirección"
                fullWidth
                autoComplete="given-address"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete sx={{ width: "100%"}}
                {...renderMunicipalities}
                
                id="Municipality"
                name="Municipality"
                clearOnEscape
                autoComplete = {true}
                noOptionsText = {"Municipio no encontrado"}
                fullWidth
                onChange={onChangeAutocomplete}
                // onInputChange={onChange}
                renderInput={(params) => (
                  <TextField {...params} label="Municipio" variant="standard" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    variant="standard"
                    // required
                    id="phone"
                    type="tel"
                    name="phone"
                    label="Número de teléfono"
                    fullWidth
                    // value={value}
                    autoComplete="given-phone"
                    onChange={handleChange}
                    inputProps={{ maxLength: 10, minLength: 10}}
                  />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "1rem", marginBottom: "0.1rem"}}>
                  <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                  <Typography variant="body1" sx={{color: "black", fontWeight: "bold"}}> Información Representante Legal</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="representativeName"
                name="representativeName"
                label="Nombres"
                fullWidth
                autoComplete="given-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="representativeLastName"
                name="representativeLastName"
                label="Apellidos"
                fullWidth
                autoComplete="family-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                  sx={{textAlign: "start"}}
                  variant="standard"
                  fullWidth={true}
                  id="representativeIdType"
                  name="representativeIdType"
                  select
                  label={<Typography variant="body2">Tipo de identificación</Typography>}
                  value={representativeIdType}
                  onChange={(e)=>representativeIdTypeChange(e)}
                  autoComplete="off"
              >
                  {representativeIdTypes.map((typeEl) => (
                      <MenuItem key={typeEl.type} value={typeEl.type}>
                          {typeEl.label}
                      </MenuItem>
                  ))}
              </TextField> 
          </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="representativeIdNumber"
                name="representativeIdNumber"
                label="Número identificación"
                fullWidth
                autoComplete="idNumber"
                onChange={handleChange}
              />
            </Grid>
            </>
          }
        </Grid>
      </Box>
    );
}
