import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import Button from '@mui/material/Button';
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

export default function TransportOrderBatches(props) {

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        renderBatchesList();
        renderIndividualsList();
    }, []);

    const initialValues = async () => {

        if(!props.location.state || !props.location.state.order || !props.location.state.order.deliveryOrderResponse){
            return null;            
        }

        return props.location.state.order.deliveryOrderResponse;
    };

    const [batchesList, setBatchesList] = React.useState("");

    const renderBatchesList = async () => {
        setLoading(true);

        const deliveryOrders = await initialValues();

        if(!deliveryOrders){
            return;
        }

        // console.log(deliveryOrders);

        deliveryOrders.sort((a, b) => a.description.localeCompare(b.description));
        

        setBatchesList(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {deliveryOrders.map((order) => {
                    return order.productBatchesResponse !== null ? (
                        order.productBatchesResponse.map((batch) => {
                            return batch.inWarehouse === false ? (
                                <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                    <Grid key={generateRandomKey()}  container spacing={1}>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2">{batch.batchCode}</Typography>        
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.date))}`}
                                            </Typography>
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.expirationDate))}`}
                                            </Typography>
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2">{batch.warehouseProduct.description}</Typography>        
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {`${new Intl.NumberFormat('es-CO').format(batch.productAmount)}`}
                                            </Typography>        
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2">{batch.id}</Typography>        
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ):(null)
                        })
                    )
                    : (null)   
                })}
            </List>
        );

        setLoading(false);

    };

    const [individualsList, setIndividualsList] = React.useState("");

    const renderIndividualsList = async () => {
        setLoading(true);

        const deliveryOrders = await initialValues();

        if(!deliveryOrders){
            return;
        }

        // console.log(deliveryOrders)

        setIndividualsList(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {deliveryOrders.map((order) => {
                    return order.deliveryOrderIndividualsResponse !== null ? (
                        order.deliveryOrderIndividualsResponse.map((batch) => {
                            return (
                                <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                    <Grid key={generateRandomKey()}  container spacing={1}>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2">{batch.batchCode}</Typography>        
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.date))}`}
                                            </Typography>
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.expirationDate))}`}
                                            </Typography>
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2">{batch.description}</Typography>        
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {`${new Intl.NumberFormat('es-CO').format(batch.productAmount)}`}
                                            </Typography>        
                                        </Grid>
                                        <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography key={generateRandomKey()} variant="body2">{batch.batchId}</Typography>        
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            )
                        })
                    )
                    : (null)   
                })}
            </List>
        );

        setLoading(false);

    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container sx={{marginTop: "1rem"}}>
                                <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Lote</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Fecha Producción</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Fecha Vencimiento</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Descripción</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Cantidad</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Código Estiba</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            {batchesList}
                        </Grid>
                        {individualsList &&
                        <>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                            <Typography variant="h6" sx={{color: "black", fontWeight: "bold"}}>Paquetes Individuales</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            {individualsList}
                        </Grid>
                        </>
                        }
                    </Grid>
                }
            </Box>
        </Box>
    )
}
