import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import swal from "sweetalert"
import AppLabel from "../../components/ui/AppLabel"
import DefaultSection from "../../components/ui/DefaultSection"
import AppButton from "../../components/ui/AppButton"
import Content from "../../layout/Content"
import Colors from "../../components/ui/Colors"
// import CardAccount from "../../img/cardAccount.svg"

export default function WelcomeCardTx() {
  let history = useHistory()

  useEffect(() => {
    swal({
      title: "¿QUÉ HAGO?",
      text: `\n En WUPO puedes transferir o pagar usando tus cuentas con nostros o sin crear cuentas. Fácil no? \n \n
                Si quieres usar tus cuentas, escoge la opción Tus Cuentas. \n \n 
                Si quieres usar nuestra cuenta, escoge la opción Cuenta Wupo`,
      icon: "success",
      button: "Entiendo"
    });
  }, [])

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <AppLabel
        logoVisible
        bgColor={Colors.secondary}
        title="HOLA"
        subTitle="Transfiere fácil"
      />
      {/* <DefaultSection
        backImgage={CardAccount}
      /> */}
      <Content style={{ flex: 1 }}>
        <AppButton primary onClick={() => history.push("/transference/user")} style={{ marginBottom: "1rem" }}>Cuenta Propia</AppButton>
        <AppButton primary onClick={() => history.push("/transference/payment")}>Cuenta Wupo</AppButton>
      </Content>
    </div>
  )
}