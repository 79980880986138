import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import ConnectorSelect from "../../components/ConnectorSelect";
import swal from "sweetalert";
import Content from "../../layout/Content";
import AdminItem from "../../components/ui/AdminItem";
import Colors from "../../components/ui/Colors";
import DefaultSection from "../../components/ui/DefaultSection";
// import CardAccount from "../../img/cardAccount.svg";

const ItemContent = styled.div({
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: "10px 0",
  justifyContent: "space-around",
});

export default function AdminHandler() {
  const history = useHistory();
  const [activeItem, setActiveItem] = useState(null);

  const handleClickItem = (id) => {
    switch (id) {
      case "cashOut":
        swal({
          title: "FUNCIONALIDAD EN CONSTRUCCIÓN",
          text: `\n Las funcionalidades de cash in y cash out están en proceso de construcción.
                  \n Estas funcionalidades van a integrar las siguientes opciones:
                  \n 1. Integración con Exchanges a través de APIs.
                  2. Integración con gateways en el blockchain de XRP.
                  3. Integración con aplicaciones autónomas en el blockchain de Ethereum (Dapps).
                  4. Integración con pasarelas de pago que usen su infraestrucutra de pagos como puente para comprar activos inmateriales no financieros (cryptoactivos)
                  5. Integración con servicios web expuestos por los Bancos (aprovechando la mecanismos de banking as a service prestados por los Bancos actualmente)
                  6. Integración con plataformas de trading
                  \n PREPÁRATE, EN POCO ESTAMOS LISTOS!`,
          icon: "info",
          buttons: {
            cancel: "EMOCIONANTE",
          },
        });
        break;
      case "cashIn":
        swal({
          title: "FUNCIONALIDAD EN CONSTRUCCIÓN",
          text: `\n Las funcionalidades de cash in y cash out están en proceso de construcción.
                    \n Estas funcionalidades van a integrar las siguientes opciones:
                    \n 1. Integración con Exchanges a través de APIs.
                    2. Integración con gateways en el blockchain de XRP.
                    3. Integración con aplicaciones autónomas en el blockchain de Ethereum (Dapps).
                    4. Integración con pasarelas de pago que usen su infraestrucutra de pagos como puente para comprar activos inmateriales no financieros (cryptoactivos)
                    5. Integración con servicios web expuestos por los Bancos (aprovechando la mecanismos de banking as a service prestados por los Bancos actualmente)
                    6. Integración con plataformas de trading
                    \n PREPÁRATE, EN POCO ESTAMOS LISTOS!`,
          icon: "info",
          buttons: {
            cancel: "EMOCIONANTE",
          },
        });
        break;
      case "clientEthAccount":
        swal({
          title: "FUNCIONALIDAD EN CONSTRUCCIÓN",
          text: `\n Estamos en proceso de construcción de las funcionalidades sobre el blockchain de Ethereum.
                  \n WUPO sobre Ethereum va a funcionar así:
                  \n 1. Estamos buscando las mejores aplicaciones autónomas (Dapps) y usando las funcionalidad nativas de ETH para:
                  - Inversión en activos digitales, incluyendo representación de commodities como oro y plata, monedas estables, porción del blockchain entre otros.
                  - Préstamos a partir de los movimientos en WUPO. Una forma directa de conocer a nuestros clientes sin bases de terceros.
                  - Seguros que te hagan la vida más fácil, incluyendo seguros de arriendo y de inversión para que puedas vivir tu vida sin fiadores y favores.
                  \n PREPÁRATE, EN POCO ESTAMOS LISTOS!`,
          icon: "info",
          buttons: {
            cancel: "EMOCIONANTE",
          },
        });
        break;
      case "wupoEthAccount":
        swal({
          title: "FUNCIONALIDAD EN CONSTRUCCIÓN",
          text: `\n Estamos en proceso de construcción de las funcionalidades sobre el blockchain de Ethereum.
                    \n WUPO sobre Ethereum va a funcionar así:
                    \n 1. Estamos buscando las mejores aplicaciones autónomas (Dapps) y usando las funcionalidad nativas de ETH para:
                            - Inversión en activos digitales, incluyendo representación de commodities como oro y plata, monedas estables, porción del blockchain entre otros.
                            - Préstamos a partir de los movimientos en WUPO. Una forma directa de conocer a nuestros clientes sin bases de terceros.
                            - Seguros que te hagan la vida más fácil, incluyendo seguros de arriendo y de inversión para que puedas vivir tu vida sin fiadores y favores.
                    \n PREPÁRATE, EN POCO ESTAMOS LISTOS!`,
          icon: "info",
          buttons: {
            cancel: "EMOCIONANTE",
          },
        });
        break;
      case "wupoXrpAccount":
        history.push("/admin/xrp");
        break;
      case "wupoConnector":
        setActiveItem(id);
        break;
      // select connector
      case "Operacional":
        history.push("/admin/connect/operational");
        break;
      case "Ganancias":
        history.push("/admin/connect/profit");
        break;
      default:
        break;
    }
  };

  return (
    <Content>
      {activeItem === "wupoConnector" ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* <DefaultSection backImgage={CardAccount} /> */}
          <ConnectorSelect
            onChangeInfo={(e) => handleClickItem(e.target.value)}
          />
        </div>
      ) : (
        <>
          <ItemContent style={{ justifyContent: "center" }}>
            <AdminItem
              id="wupoConnector"
              btnColor={Colors.button2}
              title="CONNECTOR"
              btnName="WUPO"
              handleClick={handleClickItem}
            />
          </ItemContent>
          <ItemContent style={{ justifyContent: "space-between" }}>
            <AdminItem
              id="cashIn"
              btnColor={Colors.button2}
              title="CONNECTOR"
              btnName="CASH IN"
              handleClick={handleClickItem}
            />
            <AdminItem
              id="cashOut"
              btnColor={Colors.button2}
              title="CONNECTOR"
              btnName="CASH OUT"
              handleClick={handleClickItem}
            />
          </ItemContent>
          <ItemContent>
            <AdminItem
              id="xrpConnector"
              btnColor={Colors.button2}
              title="CONNECTOR"
              btnName="XRPL"
              handleClick={handleClickItem}
            />
            <AdminItem
              id="ethConnector"
              btnColor={Colors.button2}
              title="CONNECTOR"
              btnName="ETHL"
              handleClick={handleClickItem}
            />
          </ItemContent>
          <ItemContent>
            <AdminItem
              id="wupoXrpAccount"
              btnColor={Colors.button3}
              title="XRP LEDGER"
              btnName="WUPO ACCOUNT"
              handleClick={handleClickItem}
            />
            <AdminItem
              id="wupoEthAccount"
              btnColor={Colors.button3}
              title="ETH LEDGER"
              btnName="WUPO ACCOUNT"
              handleClick={handleClickItem}
            />
          </ItemContent>
          <ItemContent>
            <AdminItem
              id="clientXrpAccount"
              btnColor={Colors.button3}
              title="XRP LEDGER"
              btnName="CLIENTE ACCOUNT"
              handleClick={handleClickItem}
            />
            <AdminItem
              id="clientEthAccount"
              btnColor={Colors.button3}
              title="ETH LEDGER"
              btnName="CLIENT ACCOUNT"
              handleClick={handleClickItem}
            />
          </ItemContent>
        </>
      )}
    </Content>
  );
}
