import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

export default function DailyInventory(props) {

    React.useEffect(() => {
        renderInventory();
    }, []);

    const initialValues = () => {
        if(props.location.state && props.location.state.product){
          return props.location.state.product;
        }
    };

    const [inventory, setInventory] = React.useState("");

    const renderInventory = async() => {
        
        const product = await initialValues();

        if(!product.dailyProductsInventory){
            return;
        }

        const dailyInventory = product.dailyProductsInventory;

        dailyInventory.sort((a, b) => new Date(b.date) - new Date(a.date));

        setInventory(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {dailyInventory.map((dayEl) => {
                    return(
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <Grid key={generateRandomKey()}  container spacing={1}>
                                {/* <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body2">{product.description}</Typography>
                                </Grid> */}
                                <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                    {dayEl.instantDate?
                                        <Typography key={generateRandomKey()} variant="body2">{new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(dayEl.instantDate))}</Typography>
                                    :
                                        <Typography key={generateRandomKey()} variant="body2">{new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(dayEl.date))}</Typography>
                                    }
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body2">{Intl.NumberFormat('es-CO').format(dayEl.dailyAccumulated)}</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body2">{Intl.NumberFormat('es-CO').format(dayEl.input)}</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body2">{Intl.NumberFormat('es-CO').format(dayEl.output)}</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    )
                })}
            </List>
        );
    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container sx={{marginTop: "1rem"}}>
                        <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                            <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Fecha</Typography>
                        </Grid>
                        <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                            <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Acumulado Día</Typography>
                        </Grid>
                        <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                            <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Entradas</Typography>
                        </Grid>
                        <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                            <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Salidas</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {inventory}
                </Grid>
            </Grid>
            </Box>
        </Box>
    )
}
