import React from 'react';
import swal from "sweetalert";
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../components/ui/Colors";
import MenuItem from '@mui/material/MenuItem';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import{createUserByAdmin} from "../../utilityFunctions/AuthUtil.js"

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const signupRequest = {
    role:"",
    name:"",
    lastName:"",
    idNumber:"",
    password:"",
    email:"",
    phone:"",
};

const rolesArray = [
    {
        name: "Administrador",
        value: "ADMIN",
    },
    {
        name: "Montacargas",
        value: "TRANSPORTER",
    },
    {
        name: "Supervisor",
        value: "SUPERVISOR",
    },
    {
        name: "Producción",
        value: "PROD",
    },
];

export default function AdminRegister(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=> {
        cleanRequest();
    }, []);
    
    const cleanRequest = () => {
        for (const key in signupRequest) {
            if (signupRequest.hasOwnProperty(key)) {
                signupRequest[key] = null;
            }
          }
    }

    const[role, setRole] = React.useState("");
    const[name, setName] = React.useState("");
    const[lastName, setLastName] = React.useState("");
    const[idNumber, setIdNumber] = React.useState("");
    const[password, setPassword] = React.useState("");
    const[email, setEmail] = React.useState("");
    const[phone, setPhone] = React.useState("");
    
    

    const roleChange = (e) => {
        console.log(e.target.value)
        setRole(e.target.value);
        signupRequest.role = e.target.value;
    };

    const nameChange = (e) => {
        setName(e.target.value);
        signupRequest.name = e.target.value;
    };

    const lastNameChange = (e) => {
        setLastName(e.target.value);
        signupRequest.lastName = e.target.value;
    };

    const idNumberChange = (e) => {
        setIdNumber(e.target.value);
        signupRequest.idNumber = e.target.value;
    };

    const passwordChange = (e) => {
        setPassword(e.target.value);
        signupRequest.password = e.target.value;
    };

    const phoneChange = (e) => {
        setPhone(e.target.value);
        signupRequest.phone = e.target.value;
    };

    const emailChange = (e) => {
        setEmail(e.target.value);
        signupRequest.email = e.target.value;
    };

    const createUSer = async () => {
        setLoading(true);
        const userReceived = await createUserByAdmin(signupRequest, loginUser.jwtToken);
        setLoading(false);

        if(!userReceived){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        swal({
            title: "Creación exitosa",
            icon: "success",
            buttons: "OK"
        });
    }

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            sx={{textAlign: "start"}}
                            variant="standard"
                            fullWidth={true}
                            id="role"
                            name="role"
                            select
                            label={<Typography variant="body2">Perfil</Typography>}
                            value={role}
                            onChange={(e)=>roleChange(e)}
                            autoComplete="off"
                        >
                            {rolesArray.map((el) => (
                                <MenuItem key={el.value} value={el.value}>
                                    {el.name}
                                </MenuItem>
                            ))}
                        </TextField> 
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="name"
                            name="name"
                            label={<Typography variant="body2">Nombre</Typography>}
                            value={name}
                            onChange={(e)=> nameChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="lastName"
                            name="lastName"
                            label={<Typography variant="body2">Apellidos</Typography>}
                            value={lastName}
                            onChange={(e)=> lastNameChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="idNumber"
                            name="idNumber"
                            label={<Typography variant="body2">Número de Identificación</Typography>}
                            value={idNumber}
                            onChange={(e)=> idNumberChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="email"
                            name="email"
                            label={<Typography variant="body2">Correo Electrónico</Typography>}
                            value={email}
                            onChange={(e)=> emailChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="phone"
                            name="phone"
                            label={<Typography variant="body2">Celular</Typography>}
                            value={phone}
                            onChange={(e)=> phoneChange(e)}
                            autoComplete="off"
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="password"
                            name="password"
                            label={<Typography variant="body2">Contraseña</Typography>}
                            value={password}
                            onChange={(e)=> passwordChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={createUSer} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                            Crear
                        </Button>
                    </Grid>
                </Grid>
            }
            </Box>
        </Box>
    )
}
