import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Colors from "../../components/ui/Colors";
import { Button, DialogContent, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import NumberFormat from "react-number-format";
import {getProductBatch, updateCheckpoint, getActiveTransportOrdersIdentifiers,
    manualBatchToTransportOrder, manualAssignmentToOrder} from "../../utilityFunctions/WarehouseUtil";
import Timer from "./Timer.js"
import {QrReader} from 'react-qr-reader';
import swal from 'sweetalert';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { hi } from 'date-fns/locale';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

const checkpointObject = {
    username: "",
    id: "",
    processException: null,
};

const constraints = {
    facingMode: "environment",
};

let productResponse;

let isAdmin = false;
let isProd = false;
let isTransporter = false;
let isSupervisor = false;

const batchToTransportRequest = {
    orderId: "",
    batchId: "",
};

const jumpWarehouse = true;
const cediEnabled = true;

const changeRequest={};
const orderChangeRequest = {};

export default function LabelReader(props) {
    
    const{loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const cleanCheckpiont = () => {
        // checkpointObject.id="";
        // checkpointObject.username="";
        checkpointObject.processException=null;
    };

    React.useEffect(()=> {
        checkpointObject.id="";
        checkpointObject.username="";
        checkpointObject.processException=null;
        cleanCheckpiont();

        handleRole();
    }, []);

    const handleRole = () => {

        if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
            
            if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
                isAdmin=true;
            }

            else if(loginUser.roles.some((rol) => rol.authority === "PROD")){
                isProd=true;
            }
            else if(loginUser.roles.some((rol) => rol.authority === "SUPERVISOR")){
                isSupervisor=true;
            }
            else if(loginUser.roles.some((rol) => rol.authority === "TRANSPORTER")){
                isTransporter=true;
            }

            else{
                isTransporter=true;
            }
        }

    };

    const[description, setDescription] = React.useState("");
    const[amount, setAmount]= React.useState("");
    const[batchLevel, setBatchLevel]= React.useState("");
    const[batchId, setBatchId] = React.useState("");
    const[batchCode, setBatchCode] = React.useState("");
    const[expirationDate, setExpirationDate] = React.useState("");

    const[disableButton, setDisableButton] = React.useState(true);

    const [qrScanner, setQrScanner] = React.useState(false);

    const openQrScanner = () => {
        setQrScanner(true);
        setDisableButton(true);
    };

    const closeQrScanner = () => {
        setQrScanner(false);
    };

    const handleScan = async (data) => {
        
        if (data) {
            closeQrScanner();

            const jsonData = JSON.parse(data.text);

            if(!jsonData.label){
                return;
            }


            checkpointObject.id=jsonData.label;
            checkpointObject.username=loginUser.accountId;

            setLoading(true);
            productResponse = await getProductBatch(checkpointObject, loginUser.jwtToken);
            setLoading(false);

            if(!productResponse){
                return;
            }

            setDescription(productResponse.warehouseProduct.description);
            setAmount(productResponse.productAmount);
            setBatchLevel(productResponse.level);
            setBatchCode(productResponse.batchCode);
            setBatchId(productResponse.id);
            setExpirationDate(productResponse.expirationDate)

            if(productResponse.warehouseDeliveryCheck){
                // setOrderInfo(true);
            }

            setDisableButton(false);
        
        };
    };

    React.useEffect(() => {
        closeQrScanner();
        setDisableButton(false);
    }, [description]);
    
    const handleError = (error) => {
        console.error('Error reading QR code:', error);
        // Handle error as needed
    };

    const [qrInput, setQrInput] = React.useState("");

    const qrInputChange = async (e) => {
        
        const value = e.target.value.replace(/\D/g, '');
        console.log(value);
        setQrInput(value)
        checkpointObject.id= value;
    }

    const qrInputSubmit = async() => {
        
        checkpointObject.username=loginUser.accountId;
        
        setLoading(true);
        productResponse = await getProductBatch(checkpointObject, loginUser.jwtToken);
        setLoading(false);

        if(!productResponse){
            swal({
                title: "Código errado",
                text: "El código ingresado es incorrecto. Por favor verificar",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        setDescription(productResponse.warehouseProduct.description);
        setAmount(productResponse.productAmount);
        setBatchLevel(productResponse.level);
        setBatchCode(productResponse.batchCode);
        setBatchId(productResponse.id);
        setExpirationDate(productResponse.expirationDate)

        if(productResponse.warehouseDeliveryCheck){
            // setOrderInfo(true);
        }

        setDisableButton(false);
    };

    const actionByRole = () => {

        // if(isAdmin === true){
        //     if((productResponse.productionCheck && !productResponse.supervisorWarehouseCheck) || (productResponse.warehousePickupCheck && !productResponse.supervisorDeliveryCheck)){
        //         supervisorConfirmCheckpoint();
        //         return;
        //     }
        // }

        if(isTransporter === true || isAdmin === true){

            if(!productResponse.productionCheck){
                confirmCheckpoint();
                return;
            }
            else{
                exceptionsChecker();
                return;
            }

        }
        else if(isSupervisor){
            supervisorConfirmCheckpoint();
            return;
        }
        else{
            swal({
                title: "Funcionalidad no habilitada",
                text: "Esta opción no está habilitada para tu perfil",
                icon: "info",
                buttons: "OK"
            });
            return;
        }
        
    }

    const exceptionsChecker = () => {
        if(!productResponse.warehouseDeliveryCheck){
            setProcessExc(true);
            return;
        }

        if(productResponse.inWarehouse){
            setActionsBox(true);
            return;
        }

        confirmCheckpoint ();
    };

    const [actionsBox, setActionsBox] = React.useState(false);

    const changeSlot = () => {

        history.push({
            pathname: "/warehouse/structure",
            state: {
                batch: productResponse,
            }
        });
    };

    const closeActionBox = () => {
        setActionsBox(false);
    }

    const confirmCheckpoint = async () => {
        closeActionBox();

        setDescription("");
        setAmount("");
        setBatchLevel("");
        setBatchCode("");
        setBatchId("");
        setExpirationDate("");

        if(!productResponse){
            return;
        }

        if(productResponse.transporterDeliveryCheck){

            swal({
                title: "Instrucción",
                text: "La estiba ya fue entregada",
                icon: "success",
                buttons: "OK"
            });
            return;
        }

        if(productResponse.productionCheck && !productResponse.warehouseDeliveryCheck && checkpointObject.processException && checkpointObject.processException !== "noWarehouse"){

            cleanCheckpiont();

            history.push({
                pathname: "/warehouse/structure",
                state: {
                    batch: productResponse,
                }
            });

            return;
        }

        else{
            
            setLoading(true);
            const productBatch = await updateCheckpoint(checkpointObject, loginUser.jwtToken);
            setLoading(false);
            cleanCheckpiont();

            if(!productBatch){
                
                swal({
                    title: "Ocurrio un error",
                    text: "Inténtalo de nuevo.",
                    icon: "error",
                    buttons: "OK"
                });
                return;
            }

            else if(productBatch.productionCheck && !productBatch.warehouseDeliveryCheck && !productBatch.transporterDeliveryCheck){

                setStartTime(new Date(productBatch.productionCheck));
                setStartTimer(true);

                swal({
                    title: "Instrucción",
                    text: "Por favor llevar la estiba a bodega",
                    icon: "success",
                    buttons: "OK"
                });
                return;
            }

            else if(productBatch.productionCheck && productBatch.warehouseDeliveryCheck && !productBatch.warehousePickupCheck){
                
                setStartTime(null);
                setStartTimer(false);

                swal({
                    title: "Instrucción",
                    text: "la estiba fue ingresada al inventario. Terminó el proceso",
                    icon: "success",
                    buttons: "OK"
                });
                return;
            }

            else if(productBatch.productionCheck && productBatch.warehouseDeliveryCheck && productBatch.warehousePickupCheck && !productBatch.transporterDeliveryCheck){
                
                setStartTime(new Date(productBatch.warehousePickupCheck));
                setStartTimer(true);
                
                swal({
                    title: "Instrucción",
                    text: "levantó la estiba, por favor llevarla al vehículo",
                    icon: "success",
                    buttons: "OK"
                });
                return;
            }

            else{

                setStartTime(null);
                setStartTimer(false);

                swal({
                    title: "Operación exitosa",
                    icon: "success",
                    buttons: "OK"
                });
            }
        }

    };

    const supervisorConfirmCheckpoint = async () => {
        closeActionBox();

        if(!productResponse){
            return;
        }

        if(cediEnabled === true){
            history.push({
                pathname: "/warehouse/cedi-register",
                state: {
                    batch: productResponse,
                }
            });

            return;
        }

        if(jumpWarehouse === true && productResponse.supervisorWarehouseCheck && !productResponse.supervisorDeliveryCheck){
            renderOrderInput(productResponse);
            return;
        }

        if(jumpWarehouse === false && productResponse.inWarehouse === true && productResponse.warehouseDeliveryCheck){
            renderBatchToOrder(true);
            return;
        }

        setLoading(true);
        const productBatch = await updateCheckpoint(checkpointObject, loginUser.jwtToken);
        setLoading(false);

        setDescription("");
        setAmount("");
        setBatchLevel("");
        setBatchCode("");
        setBatchId("");
        setExpirationDate("");

        cleanCheckpiont();

        if(!productBatch){
            
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            return;
        }


        else if(productBatch.supervisorWarehouseCheck && !productBatch.supervisorDeliveryCheck){
            swal({
                title: "Instrucción",
                text: "La estiba fue ingresada a la bodega",
                icon: "success",
                buttons: "OK"
            });
            return;
        }

    };

    const supervisorOutOfWarehouseCheck = async () => {

        setLoading(true);
        const productBatch = await updateCheckpoint(checkpointObject, loginUser.jwtToken);
        setLoading(false);

        setDescription("");
        setAmount("");
        setBatchLevel("");
        setBatchCode("");
        setBatchId("");
        setExpirationDate("");

        cleanCheckpiont();

        if(!productBatch){
            
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        if(productBatch.orderResponse && productBatch.orderResponse.transportResponse){
            swal({
                title: "Estiba a Placa",
                text: `${productBatch.orderResponse.transportResponse.carPlate}`,
                icon: "success",
                buttons: "OK"
            });
        }
        else{
            swal({
                title: "Actualización exitosa",
                icon: "success",
                buttons: "OK"
            });
        }
    }

    const[processExc, setProcessExc] = React.useState(false);

    const closeProcessExc = () => {
        setProcessExc(false);
    };

    const warehouseDel = () => {
        closeProcessExc();

        checkpointObject.processException="warehouse";
        
        confirmCheckpoint();
    };

    const transportDel = () => {
        closeProcessExc();
        
        checkpointObject.processException="noWarehouse";

        confirmCheckpoint();
    };

    const[orderInfo, setOrderInfo] = React.useState(false);

    const closeOrderInfo = () => {
        setOrderInfo(false);
    };

    const [startTimer, setStartTimer] = React.useState(false);
    const [startTime, setStartTime] = React.useState("");

    const[batchAssignment, setBatchAssignment] = React.useState("");
    const[batchToOrderBox, setBatchToOrderBox] = React.useState(false);
    const[orderInput, setOrderInput] = React.useState("");
    const[orderInputBox, setOrderInputBox] = React.useState(false);

    const renderBatchToOrder = (productBatch) => {
        setBatchAssignment(
            <List sx={{width: "100%",}}>
                <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                    <ListItemButton  onClick={supervisorOutOfWarehouseCheck}>
                        <Typography variant="h6">Confirmar</Typography>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{marginBottom: "1.5rem"}}>
                    <ListItemButton  onClick={() => renderOrderInput(productBatch)} sx={{textAlign: "center"}}>
                        <Typography variant="h6">Asignar Estiba a Orden</Typography>
                    </ListItemButton>
                </ListItem>
            </List>
        );

        setBatchToOrderBox(true);
    };

    const closeBatchToOrderBox = () => {
        setBatchToOrderBox(false);
    };

    const closeOrderInputBox = () => {
        setOrderInputBox(false);
    }

    const[ordersList, setOrdersList] = React.useState("");

    const getordersIdentifiers = async () => {
        
        setLoading(true);
        const ordersArray = await getActiveTransportOrdersIdentifiers(loginUser.jwtToken);
        setLoading(false);

        console.log(ordersArray);

        if(ordersArray){
            setOrdersList(
                {
                    options: ordersArray,
                    getOptionLabel: (option) => `Placa: ${option.carPlate}, Fecha: ${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(option.date))}, # ${option.id}`,
                }
            );
            
            return ordersArray;
        }
        else{
            return null;
        }
        
        
    };

    const[batchToBeSet, setBatchToBeSet] = React.useState(null);
    const renderOrderInput = async(productBatch) => {
        
        setBatchToOrderBox(false);
        setBatchToBeSet(productBatch);

        const ordersIdentifiers = await getordersIdentifiers();

        if(!ordersIdentifiers){
            swal({
                title: "No hay órdenes activas",
                icon: "info",
                buttons: "OK"
            });
            return;
        };

    };

    React.useEffect(() => {
        if(!ordersList){
            return;
        }

        setOrderInput(
            <Grid container sx={{display: "flex", justifyContent: "center"}}>
                <Grid item xs={12} sm={12} md={12} sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                    {ordersList &&
                    <Autocomplete
                        sx={{width: "80%"}}
                        {...ordersList}
                        id="ordersList"
                        clearOnEscape
                        autoComplete = {true}
                        noOptionsText = {"Orden no existe"}
                        // sx={{ width: 600 }}
                        onChange={onChangeAutocomplete}
                        // onInputChange={onChange}
                        renderInput={(params) => (
                            <TextField {...params} label={<Typography variant="body2">Selección de Orden</Typography>} variant="standard" />
                        )}
                    /> 
                    }
                </Grid>
            </Grid>
        );

        setOrderInputBox(true);
        
    }, [ordersList]);

    const batchToOrder = async () => {
        
        setOrderInputBox(false);

        setLoading(true);
        const assignedBatch = await manualBatchToTransportOrder(batchToTransportRequest, loginUser.jwtToken);
        setLoading(false);

        if(!assignedBatch){
            swal({
                title: "Ocurrió un error",
                icon: "error",
                buttons: "OK"
            });
            return;
        };

        supervisorOutOfWarehouseCheck();

    };

    const concludeWarehouseCheck = (productBatch) => {

        swal({
            title: "Estiba a Placa",
            text: `${productBatch.orderResponse.transportResponse.carPlate}`,
            icon: "success",
            buttons: "OK"
        });
    };

    const[orderToSetBatch, setOrderToSetBatch] = React.useState("");

    const onChangeAutocomplete = (event, value) => {
        if(!value){
            return;
        }
        
        setOrderToSetBatch(value);
        batchToTransportRequest.orderId=value.id;
        
        batchToTransportRequest.batchId = productResponse.id;

        changeRequest.id = productResponse.id;
        orderChangeRequest.id = value.id;

        console.log(productResponse);
        
    };

    const[newAmount, setNewAmount] = React.useState(false);
    const[newAmountEnabled, setNewAmountEnabled] = React.useState(false);

    const openSetAmount = () =>{
        setNewAmountEnabled(true);
    };

    const closeSetAmount = () =>{
        setNewAmountEnabled(false);
        setNewAmount("");
    };

    const newAmountChange = (e) => {

        if(e.floatValue > productResponse.productAmount){
            swal({
                title: "Revisar cantidad",
                text: "La cantidad ingresada es mayor a la cantidad de la estiba",
                icon: "info",
                buttons: "OK"
            });
        }
        
        setNewAmount(e.floatValue);
        orderChangeRequest.individualsAmount = e.floatValue;
        changeRequest.newAmount = productResponse.productAmount - orderChangeRequest.individualsAmount;
        console.log(changeRequest);
        console.log(productResponse);
    };

    const assignOrder = async () => {

        changeRequest.username=loginUser.accountId;
        console.log(changeRequest);

        const ManualOrderAmountChangeReq = {
            orderAmountChangeRequest: orderChangeRequest,
            batchChangeRequest: changeRequest,
        }
        
        setLoading(true);
        const orderChanged = await manualAssignmentToOrder(ManualOrderAmountChangeReq, loginUser.jwtToken);
        setLoading(false);

        if(!orderChanged){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            cleanRequestObjects();
            return;
        }

        swal({
            title: "Actualización exitosa",
            icon: "success",
            buttons: "OK"
        }).then(()=> {
            history.push({pathname: "/handle_profile"})
            cleanRequestObjects();

        });

    }

    const cleanRequestObjects = () => {

        // changeRequest.id="";
        // changeRequest.username="";
        // changeRequest.newAmount="";

    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    }; 

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center", md: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :  
            <>
                <Grid container sx={{display: "flex", justifyContent: "center"}}>
                    {startTime && startTimer === true &&
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginBottom: "1rem"}}>
                            <Timer checkpiontTime={startTime}/>
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} md={12} sx={{width: "100%", height: "90%", display: "flex", justifyContent: "center"}}>
                        <Card sx={{maxWidth: "400px", minWidth: {xs: "90%", sm: "60%", md: "300px"}, minHeight: "200px", borderRadius: "15px",  marginTop: {xs: "20%", sm: "0%", md: "2rem"}, marginBottom: {xs: "1rem", sm: "1rem", md: "1rem"}}}> 
                            <Grid container spacing={1} sx={{display: "flex", justifyContent: {xs: "center", sm: "center"}, alignItems: {xs: "center", sm: "center", md: "center"}, width: "100%", marginLeft: "1%",}}>
                                {batchId &&
                                    <>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginTop: "1.3rem", marginLeft: "10%"}}>
                                        <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Número Estiba</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                        <Typography variant="body1">{`${batchId}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "0.6rem", marginLeft: "10%"}}>
                                        <Divider sx={{width: "90%"}} />
                                    </Grid>
                                    </>
                                }
                                {description &&
                                    <>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginTop: "0.1rem", marginLeft: "10%"}}>
                                        <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Descripción</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                        <Typography variant="body1">{`${description}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "0.6rem", marginLeft: "10%"}}>
                                        <Divider sx={{width: "90%"}} />
                                    </Grid>
                                    </>
                                }
                                {batchLevel &&
                                    <>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginTop: "0.1rem", marginLeft: "10%"}}>
                                        <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Niveles</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                        <Typography variant="body1">{`${batchLevel}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "0.6rem", marginLeft: "10%"}}>
                                        <Divider sx={{width: "90%"}} />
                                    </Grid>
                                    </>
                                }
                                {amount &&
                                    <>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                        <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Cantidad</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                        <Typography variant="body2">{`${new Intl.NumberFormat('es-CO').format(amount)}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{marginLeft: "10%"}}>
                                        <Divider sx={{width: "90%"}} />
                                    </Grid>
                                    </>
                                }
                                {batchCode &&
                                    <>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginTop: "0.1rem", marginLeft: "10%"}}>
                                        <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Lote</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                        <Typography variant="body1">{`${batchCode}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "0.6rem", marginLeft: "10%"}}>
                                        <Divider sx={{width: "90%"}} />
                                    </Grid>
                                    </>
                                }
                                {expirationDate &&
                                    <>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginTop: "0.1rem", marginLeft: "10%"}}>
                                        <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Fecha Vencimiento</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                        <Typography variant="body1">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(expirationDate))}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "0.6rem", marginLeft: "10%"}}>
                                        <Divider sx={{width: "90%"}} />
                                    </Grid>
                                    </>
                                }
                                {!description &&
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "4.5rem", marginBottom: "1rem"}}>
                                        <Button onClick={openQrScanner} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                            Escanear
                                        </Button>
                                    </Grid>
                                }
                                {description &&
                                    <>
                                    <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "end", marginTop: "1.8rem", marginBottom: "1rem"}}>
                                        <Button onClick={openQrScanner} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                            Escanear
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", marginTop: "1.8rem", marginBottom: "1rem"}}>
                                        <Button disabled={disableButton} onClick={actionByRole} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                            Confirmar
                                        </Button>
                                    </Grid>
                                    </>
                                }
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.2rem"}}>
                                    <TextField
                                        sx={{width: "80%"}}
                                        variant="standard"
                                        fullWidth={true}
                                        id="qrInput"
                                        name="qrInput"
                                        label={<Typography variant="body2">Código Estiba</Typography>}
                                        value={qrInput}
                                        onChange={(e)=> qrInputChange(e)}
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                    <Button onClick={qrInputSubmit} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        Buscar
                                    </Button>
                                </Grid>
                                
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                <Dialog open={qrScanner} onClose={closeQrScanner}
                    sx={{
                        // width: {xs: "100%", sm: "80%", md: "40%"}, 
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "80%", md: "60%"},
                            },
                        },
                    }}
                > 
                    <Box sx={{width: "100%", minWidth: "20rem", minHeight: "20rem"}}>
                        <QrReader
                            delay={300}
                            onError={handleError}
                            onResult=   {handleScan}
                            style={{ width: '100%', height: "100%"}}
                            containerStyle={{width: '100%'}}
                            videoContainerStyle={{width: '100%'}}
                            videoStyle={{width: '100%'}}
                            constraints={constraints}
                        />
                    </Box>
                    <DialogActions>
                        <Button onClick={closeQrScanner} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={processExc} onClose={closeProcessExc}
                    sx={{
                        // width: {xs: "100%", sm: "80%", md: "40%"}, 
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "40%", md: "30%"},
                                height: "20%"
                            },
                        },
                    }}
                > 
                    <DialogActions sx={{height: "100%"}}>
                        <Grid container spacing={1} sx={{height: "100%", alignItems: "center"}}>
                            <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                <Button onClick={warehouseDel} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                    Bodega
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                <Button onClick={transportDel} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                    Transporte
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>

                <Dialog open={actionsBox} onClose={closeActionBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "40%", md: "30%"},
                                height: "20%"
                            },
                        },
                    }}
                >
                    <List sx={{width: "100%",}}>
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={confirmCheckpoint}>
                                <Typography variant="h6">Recoger</Typography>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={changeSlot} sx={{textAlign: "center"}}>
                                <Typography variant="h6">Cambio Posición</Typography>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Dialog>

                <Dialog open={orderInfo} onClose={closeOrderInfo}
                    sx={{
                        // width: {xs: "100%", sm: "80%", md: "40%"}, 
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "40%", md: "30%"},
                                height: "50%"
                            },
                        },
                    }}
                > 
                    <DialogContent sx={{height: "100%"}}>
                        <Grid container spacing={1} sx={{height: "100%", alignItems: "center"}}>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="h6">Orden de Despacho</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "0.2rem"}}>
                            {productResponse && productResponse.deliveryOrder ?
                                <Typography variant="body1">{productResponse.deliveryOrder.orderCode}</Typography>
                                :
                                <Typography variant="body1">Sin Orden</Typography>
                            }
                            </Grid>
                            
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={1} sx={{height: "100%", alignItems: "center"}}>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                <Button onClick={closeOrderInfo} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                    Ok
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>

                <Dialog open={batchToOrderBox} onClose={closeBatchToOrderBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "40%", md: "30%"},
                                height: "20%"
                            },
                        },
                    }}
                >
                    {batchAssignment}
                    
                </Dialog>

                <Dialog open={orderInputBox} onClose={closeOrderInputBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "50%", md: "40%"},
                                height: "40%"
                            },
                        },
                    }}
                >
                    <Grid container spacing={1} sx={{width: "100%", height: "100%", alignItems: "center", justifyContent: "center"}}>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                            {orderInput}
                        </Grid>
                        {orderToSetBatch && batchToBeSet &&
                            <>
                            {newAmountEnabled === false &&
                                <>
                                <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={closeOrderInputBox} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        Atrás
                                    </Button>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={batchToOrder} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        Asignar
                                    </Button>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={openSetAmount} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        Individuales 
                                    </Button>
                                </Grid>
                                </>
                            }
                            {newAmountEnabled === true &&
                                <>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                    <NumberFormat
                                        variant="standard"
                                        style={{width: "80%", height: "90%"}}
                                        name="level"
                                        customInput={TextField}
                                        // prefix={'$ '}
                                        type="level"
                                        label={<Typography variant="body2">Cantidad Individuales</Typography>}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        autoComplete="off"
                                        value={newAmount}
                                        onValueChange={(values) => {
                                            newAmountChange(values);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={closeSetAmount} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        Atrás
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={assignOrder} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        Asignar 
                                    </Button>
                                </Grid>
                                </>
                            }
                            </>
                        }
                    </Grid>
                </Dialog>
            </>
            }
            </Box>
        </Box>
    )
}
