import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Colors from "../../components/ui/Colors";
import { Button, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { GiCow } from "react-icons/gi";
import { GiWeight } from "react-icons/gi";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import swal from "sweetalert";
import { useMediaQuery } from '@mui/material';
import {getInactiveProducts, getPic } from "../../utilityFunctions/MarketPlaceUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { makeStyles } from '@mui/styles';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/slickOverrides.css";
import { useHistory } from "react-router-dom";

const inlineSvg =  `<svg xmlns='http://www.w3.org/2000/svg'  width='120' height='120' viewBox='0 0 120 120'><rect fill='#ddffaa' width='120' height='120'/><polygon  fill='#AE9' fill-opacity='1' points='120 120 60 120 90 90 120 60 120 0 120 0 60 60 0 0 0 60 30 90 60 120 120 120 '/></svg>`;

const useStyles = makeStyles({
    svgBackground: {
      background: `url("data:image/svg+xml;utf8,${encodeURIComponent(inlineSvg)}") center center/cover no-repeat`,
    },
});

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let activeCattles;

export default function ProductsHistory(props) {
    const {loginUser} = props;

    let history = useHistory();

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    const [slidesToShow, setSlidesToShow] = React.useState(3.5); // Initial value

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: activeCattles ? Math.min(activeCattles.length, 3.5): 3.5, // Number of cards shown on each slide
        slidesToScroll: 1,
        swipeToSlide: true,
        draggable: true,
        // centerMode: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: activeCattles ? Math.min(activeCattles.length, 2.5): 2.5,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: activeCattles ? Math.min(activeCattles.length, 1.5): 1.5,
              arrows: false,
              dots: false,
            },
          },
        ],
    };

    const isLargeScreen = useMediaQuery('(min-width: 1024px)');
    const isMediumScreen = useMediaQuery('(max-width: 1023px) and (min-width: 600px)');
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
  
    React.useEffect(() => {

        if(!activeCattles){
            return;
        }
        
        if (isLargeScreen) {
            // setSlidesToShow(Math.min(activeCattles.length, 3.5));
            renderProducts();
        } else if (isMediumScreen) {
            // setSlidesToShow(Math.min(activeCattles.length, 2.5));
            // setMidSlides(Math.min(activeCattles.length, 2.5));
            renderProducts();
        } else if (isSmallScreen) {
            // setSlidesToShow(Math.min(activeCattles.length, 1.5));
            // setSmallSlides(Math.min(activeCattles.length, 1.5));
            renderProducts();
        }
    }, [isLargeScreen, isMediumScreen, isSmallScreen]);

    React.useEffect(() => {
        setLoading(true);
        callActiveCattles();
    }, []);

    const sliderRef = React.useRef(null);

    const slide = (x, y) => {
      if (Math.abs(x) > Math.abs(y)) {
        x > 0 ? sliderRef.current.slickNext() : sliderRef.current.slickPrev();
      } else {
        y > 0 ? sliderRef.current.slickNext() : sliderRef.current.slickPrev();
      }
    };
  
    React.useEffect(() => {
      const handleWheel = (e) => {
        slide(e.deltaX, e.deltaY);
      };
  
      window.addEventListener('wheel', handleWheel);
  
      return () => {
        window.removeEventListener('wheel', handleWheel);
      };
    }, []);

    React.useEffect(() => {
        setLoading(true);
        callActiveCattles();
    }, []);

    React.useEffect(() => {
        const updateSlides = () => {
          const screenWidth = window.innerWidth;
        //   if (screenWidth >= 1024) {
        //     settings.slidesToShow=3.5;
        //   } else if (screenWidth >= 900) {
        //     settings.slidesToShow=2.5;
        //   } else if (screenWidth >= 600) {
        //     settings.slidesToShow=1.5
        //   }
          console.log(settings.slidesToShow);
        };
    
        updateSlides();
    
        window.addEventListener('resize', updateSlides);
        return () => {
          window.removeEventListener('resize', updateSlides);
        };
      }, []);

    const [products, setProducts] = React.useState();


    const callActiveCattles = async () => {


        activeCattles = await getInactiveProducts(loginUser.jwtToken);

        if(!activeCattles){
            setLoading(false);
            swal({
                title: "Información",
                text: "En este momento no tenemos lotes en operación",
                icon: "info",
                button: "Entiendo",
            });

            return;
        }

        activeCattles = await sortCattle(activeCattles);
        
        settings.slidesToShow = Math.min(activeCattles.length, settings.slidesToShow);
        settings.responsive[0].settings.slidesToShow = Math.min(activeCattles.length, 2.5);
        settings.responsive[1].settings.slidesToShow = Math.min(activeCattles.length, 1.5);

        setLoading(false);

        renderProducts();

    };

    const renderProducts = () => {
        console.log(settings);
        setProducts(
            <Slider {...settings} ref={sliderRef} style={{width: "100%"}}>
            {
                activeCattles.map((cattle) => {
                    return(
                        <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Card key={generateRandomKey()} onClick={() => productDetail(cattle)} sx={{width: "90%", height: "90%", cursor: "pointer", maxWidth: "350px", borderRadius: "25px", marginBottom: "1rem"}}>
                                <Grid key={generateRandomKey()} container direction = "column">
                                    <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Box 
                                            component="img"
                                            sx={{
                                            height: "90%",
                                            width: "100%",
                                            justifyContent: "center",
                                            }}
                                            alt="Foto"
                                            src={URL.createObjectURL(cattle.cattleLotPic.cattlePic)}

                                        >
                                        </Box>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.5rem", marginBottom: "1rem"}}>
                                        <Grid container key={generateRandomKey()} sx={{width: "90%"}}>
                                            <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.5rem", marginBottom: "0.2rem"}}>
                                                <Typography key={generateRandomKey()}  variant="h6" sx={{fontWeight: "bold", width: "100%", color: Colors.primary}}>{cattle.code}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.2rem", marginBottom: "0.2rem"}}>
                                                <Typography key={generateRandomKey()}  variant="body2" sx={{fontWeight: "bold", width: "100%", color: "black"}}>
                                                    {cattle.isSettled === true ? "Cerrado" : "En Operación"}
                                                </Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <TrendingUpOutlinedIcon fontSize="small" sx={{color: "#25d366"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={7} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Rentabilidad Esperada:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%"}}>{`${cattle.expectedRevenue*100}% E.A.`}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <AccessTimeOutlinedIcon fontSize="small" sx={{color: "#25d366"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={7} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Duración:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%"}}>{`${cattle.duration} meses`}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <GiCow style={{fontSize: "18px", color: "#25d366"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={7} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Cabezas de Ganado:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%"}}>{`${new Intl.NumberFormat('es-CO').format(cattle.animalsAmount)}`}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <GiWeight style={{fontSize: "18px", color: "#25d366"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={7} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Peso Total:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%"}}>{`${new Intl.NumberFormat('es-CO').format(cattle.totalWeight)} Kg`}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <VerifiedOutlinedIcon fontSize="small" sx={{color: "#25d366"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={7} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Raza:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%"}}>{`${cattle.breed}`}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <LocationOnOutlinedIcon fontSize="small" sx={{color: "#25d366"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={7} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Ubicación:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%"}}>{`${cattle.location}`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>   
                        </Grid>
                        )
                })
            }
            </Slider>
        );
    };

    const sortCattle = async (cattleArray) => {
        cattleArray.sort((a,b) => {
            const statusValueA = sortingValue(a.isSettled);
            const statusValueB = sortingValue(b.isSettled);

            // First, compare based on "status" property
            if (statusValueA !== statusValueB) {
                return statusValueA - statusValueB;
            }

            // If "status" is the same, sort based on "name" property
            const codeA = a.code.toUpperCase();
            const codeB = b.code.toUpperCase();

            if (codeA < codeB) {
                return -1;
            } else if (codeA > codeB) {
                return 1;
            } else {
                return 0;
            }
        });

        return cattleArray;
    }

    const sortingValue = (condition) => {
        if(condition===false){
            return 1;
        }
        else{
            return 2;
        }
    };

    const productDetail = (cattle) => {
        console.log(cattle);

        // history.push({ 
        //     pathname: "/selling/productDetail",
        //     state:{
        //         product: cattle,
        //     }
        // });

    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return(
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%", backgroundColor: "#F0F0F0"}}>
            <Box
                sx={{
                    display: "flex",
                    flexGrow: "1",
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "100%",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container spacing={3} sx={{width: {sm: "95%", md: "90%"}, alignItems: "center", justifyContent: "center", marginTop:{xs: "10%", sm: "0.5rem", md: 0}, marginBottom: {xs: "3rem", sm: "2rem", md: "1.6rem"}, paddingLeft: {xs: "10%", sm: "0%"}}}>
                    {products}    
                </Grid>
            }
            </Box>
        </Box>
    )
}

