import {wupoServerUri} from "../Api";

const authRegister = async(registerInfo) => {
  
    console.log(registerInfo);

    const response = await fetch(`${wupoServerUri.devnet}/api/auth/completeregister`, {
        method: "POST",
        body: JSON.stringify(registerInfo),
        credentials: 'include',
        headers: {
        // "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
        },
    })


    console.log(response.status);
    const customizedResponse = {
        status: "",
        response: "",
    }

    if(response.status === 200){
        customizedResponse.status = 200;
        customizedResponse.response = await response.arrayBuffer();
        return customizedResponse;

    }

    else if(response.status === 201){
        customizedResponse.status = 201;
        customizedResponse.response = await response.json();
        return customizedResponse;
    }

    else if(response.status === 428){
        customizedResponse.status = 428;
        return customizedResponse;
    }

    else if(response.status === 409){
        customizedResponse.status = 409;
        return customizedResponse;
    }

    else if(response.status === 417){
        customizedResponse.status = 417;
        return customizedResponse;
    }
  
}

const registerUserPii = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/clientspost`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const bankAccount = await response.json();
      console.log(bankAccount);
  
      return bankAccount;
    }
  
    else {
      return null;
    }
  }

  const createUserByAdmin = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/registerByAdmin`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const user = await response.json();
      console.log(user);
  
      return user;
    }
  
    else {
        return null;
    }
  };
  

export {authRegister, createUserByAdmin}