import React from 'react';
import Card from '@mui/material/Card';
import NumberFormat from "react-number-format";
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Dialog from '@mui/material/Dialog';
import Colors from "../../components/ui/Colors";
import swal from 'sweetalert';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import QrScan from './QrScan';
import { getProductBatch, productBatchChange, changeIndividualsInOrder } from "../../utilityFunctions/WarehouseUtil";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const batchRequestObject = {
    id: "",
};

const changeRequest = {
    username: "",
    id: "",
    newAmount: "",
    changeComment: "",
}

let productResponse;

export default function BatchChange(props) {

    const {loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const[description, setDescription] = React.useState("");
    const[amount, setAmount]= React.useState("");
    const[openScan, setOpenScan] = React.useState(false);
    const[newAmount, setNewAmount] = React.useState(false);
    const [comment, setComment] = React.useState("");

    const openQrScanner = () => {
        setOpenScan(true);
    };

    const closeQrScanner = () => {
        setOpenScan(false);
    }

    const labelAssignment = (labelId) => {
        batchRequestObject.id=labelId;
        changeRequest.id=labelId;
        changeRequest.username=loginUser.accountId;

        fetchBatch();        
    }

    const fetchBatch = async() => {
        setLoading(true);
        productResponse = await getProductBatch(batchRequestObject, loginUser.jwtToken);
        setLoading(false);

        if(!productResponse){
            return;
        }

        setDescription(productResponse.warehouseProduct.description);
        setAmount(productResponse.productAmount);
    }

    React.useEffect(() => {
        closeQrScanner();
        setOpenScan(false);
    }, [amount]);

    const newAmountChange = (e) => {
        
        setNewAmount(e.floatValue);
        changeRequest.newAmount = e.floatValue;
    };

    const changeComment = (e) => {
        setComment(e.target.value);
        changeRequest.changeComment = e.target.value;
    };

    const handleAmountChange = async () => {
        setLoading(true);
        const batchChanged = await productBatchChange(changeRequest, loginUser.jwtToken);
        setLoading(false);

        if(!batchChanged){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            cleanRequestObjects();
            return;
        }

        swal({
            title: "Actualización exitosa",
            icon: "success",
            buttons: "OK"
        }).then(()=> {
            history.push({pathname: "/handle_profile"})
            cleanRequestObjects();

        });
        
    };

    const cleanRequestObjects = () => {
        batchRequestObject.id="";

        changeRequest.id="";
        changeRequest.username="";
        changeRequest.newAmount="";

        setQrInput("");
    };

    const [actionsBox, setActionsBox] = React.useState(false);

    const openActionBox = () => {
        setActionsBox(true);
    }
    
    const closeActionBox = () => {
        setActionsBox(false);
    }

    const goToParticularOrder = () => {
        
        history.push({
            pathname: "/warehouse/manual-order",
            state: {
                batch: productResponse,
                change: changeRequest,
            }
        });
    };

    const assignOrder = async () => {
        closeActionBox();

        if(!productResponse.deliveryOrder){
            swal({
                title: "Estiba sin orden",
                text: "Estiba no asociada a una orden de cliente.",
                icon: "info",
                buttons: "OK"
            });
            cleanRequestObjects();
            return;
        }

        const orderChangeRequest = {
            id: productResponse.deliveryOrder.id,
            individualsAmount: productResponse.productAmount - changeRequest.newAmount,
        }

        const ManualOrderAmountChangeReq = {
            orderAmountChangeRequest: orderChangeRequest,
            batchChangeRequest: changeRequest,
        }
        
        setLoading(true);
        const orderChanged = await changeIndividualsInOrder(ManualOrderAmountChangeReq, loginUser.jwtToken);
        setLoading(false);

        if(!orderChanged){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            cleanRequestObjects();
            return;
        }

        swal({
            title: "Actualización exitosa",
            icon: "success",
            buttons: "OK"
        }).then(()=> {
            history.push({pathname: "/handle_profile"})
            cleanRequestObjects();

        });

    }

    const [qrInput, setQrInput] = React.useState("");

    const qrInputChange = async (e) => {
        
        setQrInput(e.target.value)
        
        batchRequestObject.id=e.target.value;
        changeRequest.id=e.target.value;
        changeRequest.username=loginUser.accountId;

    }

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center", md: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                }}
            >

            {openScan===true &&
                <>
                <QrScan  labelAssignment={labelAssignment}/>
                </>
            }
            {openScan===false &&
                <Card sx={{maxWidth: "500px", minWidth: {xs: "90%", sm: "60%", md: "300px"}, minHeight: "200px", borderRadius: "15px",  marginTop: {xs: "20%", sm: "0%", md: "0%"}}}>
                    <Grid container spacing={1} sx={{display: "flex", justifyContent: {xs: "center", sm: "center"}, alignItems: {xs: "center", sm: "center", md: "center"}, width: "100%", marginLeft: "1%",}}>
                            {description && amount &&
                                <>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginTop: "1.3rem", marginLeft: "10%"}}>
                                    <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Producto</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography variant="body1">{`${description}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "1rem", marginLeft: "10%"}}>
                                    <Divider sx={{width: "90%"}} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Cantidad</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography variant="body2">{`${new Intl.NumberFormat('es-CO').format(amount)}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginLeft: "10%"}}>
                                    <Divider sx={{width: "90%"}} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                    <NumberFormat
                                        variant="standard"
                                        style={{width: "80%", height: "90%"}}
                                        name="level"
                                        customInput={TextField}
                                        // prefix={'$ '}
                                        type="level"
                                        label={<Typography variant="body2">Nueva Cantidad</Typography>}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        autoComplete="off"
                                        value={newAmount}
                                        onValueChange={(values) => {
                                            newAmountChange(values);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginTop: "1.2rem", marginBottom: "1.5rem"}}>
                                    <TextField
                                        sx={{width: "80%"}}
                                        variant="outlined"
                                        fullWidth={true}
                                        multiline
                                        rows={2}
                                        id="comment"
                                        name="comment"
                                        label={<Typography variant="body2">Comentario</Typography>}
                                        value={comment}
                                        onChange={(e)=> changeComment(e)}
                                        autoComplete="off"
                                    />
                                </Grid>
                                </>
                            }
                            {!description &&
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "4.5rem", marginBottom: "1rem"}}>
                                    <Button onClick={openQrScanner} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        Escanear
                                    </Button>
                                </Grid>
                            }
                            {description && (amount || amount === 0) && (newAmount || newAmount === 0) &&
                                <>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1.8rem", marginBottom: "1rem"}}>
                                    <Button onClick={openActionBox} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        Confirmar
                                    </Button>
                                </Grid>
                                </>
                            }
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.2rem"}}>
                                <TextField
                                    sx={{width: "80%"}}
                                    variant="standard"
                                    fullWidth={true}
                                    id="qrInput"
                                    name="qrInput"
                                    label={<Typography variant="body2">Código Estiba</Typography>}
                                    value={qrInput}
                                    onChange={(e)=> qrInputChange(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                <Button onClick={fetchBatch} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                    Buscar
                                </Button>
                            </Grid>
                    </Grid>
                </Card>
            }

            <Dialog open={actionsBox} onClose={closeActionBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "40%", md: "30%"},
                                height: "30%"
                            },
                        },
                    }}
                >
                    <List sx={{width: "100%",}}>
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={handleAmountChange}>
                                <Typography variant="h6">Cambio</Typography>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={goToParticularOrder} sx={{textAlign: "center"}}>
                                <Typography variant="h6">Remisión</Typography>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={assignOrder} sx={{textAlign: "center"}}>
                                <Typography variant="h6">Asignar a orden</Typography>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Dialog>

            </Box>
            }
        </Box>
    )
}
