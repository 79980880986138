import React from 'react';
import Box from '@mui/material/Box';
import {QrReader} from 'react-qr-reader';


const constraints = {
    facingMode: "environment",
};


export default function QrScan(props) {

    const{labelAssignment} = props;

    React.useEffect(()=>{
        openQrScanner();
    }, []);

    const[label, setLabel] = React.useState("");

    const [qrScanner, setQrScanner] = React.useState(true);

    const openQrScanner = () => {
        setQrScanner(true);
    };

    const closeQrScanner = () => {
        setQrScanner(false);
    };

    const handleScan = async (data) => {
        
        if (data) {
            closeQrScanner();

            const jsonData = JSON.parse(data.text);

            if(!jsonData.label){
                return;
            }

            setLabel(jsonData.label);

            labelAssignment(jsonData.label)
        
        };
    };

    React.useEffect(() => {
        // console.log(qrScanner);
    },[qrScanner]);

    React.useEffect(() => {
        closeQrScanner();
    }, [label]);
    
    const handleError = (error) => {
        console.error('Error reading QR code:', error);
        // Handle error as needed
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "start",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                }}
            >
                <Box sx={{width: {xs: "100%", sm: "80%", md: "45%"}}}>
                    <QrReader
                        delay={200}
                        onError={handleError}
                        onResult={handleScan}
                        style={{ width: '100%', height: "100%"}}
                        containerStyle={{width: '100%'}}
                        videoContainerStyle={{width: '100%'}}
                        videoStyle={{width: '100%'}}
                        constraints={constraints}
                    />
                </Box>
                {/* <Button onClick={closeQrScanner} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                    Atrás
                </Button> */}
            </Box>
        </Box>
    )
}
