import React from 'react';
import Grid from '@mui/material/Grid';
import {Typography, Button, DialogContent} from '@mui/material';
import Colors from "../../components/ui/Colors";
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

const positionSelected = {
    positionName: "",
    level: "",
};

let isNotNull = false;

export default function Slot(props) {
    const {productBatch, used, positionName, levelBox, handleNewPosition, deliveryOrder, busyLevels} = props;

    const[positionBox, setPositionBox]= React.useState(false);

    React.useEffect(()=> {
        // console.log("entering use effect...");
        // console.log(used);
        // console.log(productBatch);
        // console.log(positionName);
        // console.log(deliveryOrder);

        isNotNull = false;

        // if(productBatch){
        //     isNotNull = productBatch.some(obj => obj["deliveryOrder"] !== null);
        //     console.log(isNotNull);
        // }
        
    }, []);

    // const handleNewPosition = (newPosition) => {

    //     console.log(newPosition);

    //     setPositionBox(true);
    // };

    const openPositionBox = () => {
        setPositionBox(true);
    };

    const closePositionBox = () => {
        setPositionBox(false);
    }; 

    const handleLevel = (level) => {
        positionSelected.positionName=positionName;
        positionSelected.level=level;

        handleNewPosition(positionSelected);
    };

    const handleBusyLevel = (level) => {
        positionSelected.positionName=positionName;
        positionSelected.level=level;

        levelBox(positionSelected)
    };

    const[productDetail, setProductDetail] = React.useState(null);
    const[productDetailBox, setProductDetailBox] = React.useState(false);

    const closeProductDetailBox = () => {
        setProductDetailBox(false);
    };

    const handleProductDetail = (selectedProduct) => {
        

        setProductDetail(
            <List sx={{width: "80%", marginTop: "5%"}}>
                <Grid container spacing={2}>
                    <ListItem disablePadding divider sx={{marginBottom: "1.1rem"}}>
                        <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                            <Typography variant="body2">Código Estiba:</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                            <Typography variant="body2">{`${selectedProduct.id}`}</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem disablePadding divider sx={{marginBottom: "1.1rem"}}>
                        <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                            <Typography variant="body2">Fecha de Producción:</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                            <Typography variant="body2">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(selectedProduct.date))}`}</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem disablePadding divider sx={{marginBottom: "1.1rem"}}>
                        <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                            <Typography variant="body2">Fecha de Vencimiento:</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                            <Typography variant="body2">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(selectedProduct.expirationDate))}`}</Typography>
                        </Grid>
                    </ListItem>
                    <ListItem disablePadding divider sx={{marginBottom: "1.1rem"}}>
                        <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                            <Typography variant="body2">Cantidad Producto:</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                            <Typography variant="body2">{`${selectedProduct.productAmount}`}</Typography>
                        </Grid>
                    </ListItem>
                    
                </Grid>
            </List> 
        );
        
        closePositionDetail(true);
        setProductDetailBox(true);
    };

    const [positionDetail, setPositionDetail] = React.useState(false);

    const closePositionDetail = () => {
        setPositionDetail(false);
    };

    const [levelsBusy, setLevelsBusy] = React.useState("");

    const handlePositionDetail = (levelsArray) => {
        // console.log(productBatch);
        // console.log(levelsArray);

        if(!levelsArray){
            return;
        }

        setLevelsBusy(
            <List sx={{width: "100%",}}>
                <Grid container spacing={2}>
                    {levelsArray.l1 ?
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={() => handleProductDetail(levelsArray.l1)}>
                                <Grid item xs={4} sm={4} md={4} lg={4} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                    <Typography variant="body2">Nivel 1</Typography>
                                </Grid>
                                
                                <Grid item xs={4} sm={4} md={4} lg={4} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                    <Typography variant="body2">{`${levelsArray.l1.warehouseProduct.description}`}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                {levelsArray.l1.deliveryOrder ?
                                    <Typography variant="body2">{`${levelsArray.l1.deliveryOrder.orderCode}`}</Typography>
                                    :
                                    <Typography variant="body2">Sin Orden</Typography>
                                }
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    :
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={() => handleBusyLevel("l1")}>
                                <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                    <Typography variant="body2">Nivel 1</Typography>
                                </Grid>
                            </ListItemButton>
                        </ListItem>   
                    }
                    {levelsArray.l2 ?
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={() => handleProductDetail(levelsArray.l2)}>
                                <Grid item xs={4} sm={4} md={4} lg={4} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                    <Typography variant="body2">Nivel 2</Typography>
                                </Grid>
                                
                                <Grid item xs={4} sm={4} md={4} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                    <Typography variant="body2">{`${levelsArray.l2.warehouseProduct.description}`}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                {levelsArray.l2.deliveryOrder ?
                                    <Typography variant="body2">{`${levelsArray.l2.deliveryOrder.orderCode}`}</Typography>
                                    :
                                    <Typography variant="body2">Sin Orden</Typography>
                                }
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    :
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={() => handleBusyLevel("l2")}>
                                <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                    <Typography variant="body2">Nivel 2</Typography>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    }
                    {levelsArray.l3 ?
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={() => handleProductDetail(levelsArray.l3)}>
                                <Grid item xs={4} sm={4} md={4} lg={4} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                    <Typography variant="body2">Nivel 3</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                    <Typography variant="body2">{`${levelsArray.l3.warehouseProduct.description}`}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                {levelsArray.l3.deliveryOrder ?
                                    <Typography variant="body2">{`${levelsArray.l3.deliveryOrder.orderCode}`}</Typography>
                                    :
                                    <Typography variant="body2">Sin Orden</Typography>
                                }
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    :
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={() => handleBusyLevel("l3")}>
                                <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                    <Typography variant="body2">Nivel 3</Typography>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    }
                </Grid>
            </List> 
        );

        setPositionDetail(true);


    };

    return (
        <>
        {used===true&& deliveryOrder===false &&
        <>
            {busyLevels && busyLevels===1 ?
                <Box onClick={()=>handlePositionDetail(productBatch)} sx={{backgroundColor: "#50e0ff", cursor: "pointer", border: "1px solid silver"}}>
                    <Typography sx={{fontSize: "0.8rem", color: "white"}}>{"x"}</Typography>
                </Box>
                : busyLevels && busyLevels===2 ?
                <Box onClick={()=>handlePositionDetail(productBatch)} sx={{backgroundColor: "#ff7400", cursor: "pointer", border: "1px solid silver"}}>
                    <Typography sx={{fontSize: "0.8rem", color: "white"}}>{"x"}</Typography>
                </Box>
                : busyLevels && busyLevels===3 ?
                <Box onClick={()=>handlePositionDetail(productBatch)} sx={{backgroundColor: "#D21F3C", cursor: "pointer", border: "1px solid silver"}}>
                    <Typography sx={{fontSize: "0.8rem", color: "white"}}>{"x"}</Typography>
                </Box>
                :
                <Box onClick={()=>handlePositionDetail(productBatch)} sx={{backgroundColor: "#50e0ff", cursor: "pointer", border: "1px solid silver"}}>
                    <Typography sx={{fontSize: "0.8rem", color: "white"}}>{"x"}</Typography>
                </Box>
            }
        </>
        }
        {used===true&& deliveryOrder===true &&
            <Box onClick={()=>handlePositionDetail(productBatch)} sx={{backgroundColor: "#50C878", cursor: "pointer", border: "1px solid silver"}}>
                <Typography sx={{fontSize: "0.8rem", color: "white"}}>{"r"}</Typography>
            </Box>
        }
        {used===false&&
            <Box onClick={()=>openPositionBox(positionName)} sx={{border: "1px solid silver", cursor: "pointer"}}>
                <Typography sx={{fontSize: "0.8rem"}}>{"x"}</Typography>
            </Box>
        }
        <Dialog open={positionBox} onClose={closePositionBox}
                sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "40%"},
                                // height: "20rem",
                            },
                        },
                    }}
            >
                <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                        <Typography variant="h4">{"Registro Estiba"}</Typography>
                    </Grid>
                </Grid>
                <DialogContent>
                    <List sx={{width: "100%",}}>
                        <Grid container spacing={2}>
                            <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                <ListItemButton  onClick={() => handleLevel("l1")}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                        <Typography variant="body2">Nivel 1</Typography>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                <ListItemButton  onClick={() => handleLevel("l2")}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                        <Typography variant="body2">Nivel 2</Typography>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                <ListItemButton  onClick={() => handleLevel("l3")}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                        <Typography variant="body2">Nivel 3</Typography>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                        </Grid>
                    </List>                               
                </DialogContent>
                <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2rem", marginBottom: "1rem"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button onClick={closePositionBox} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Atrás
                        </Button>  
                    </Grid>  
                    {/* <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button onClick={()=>handleNewPosition(positionName)} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Registro
                        </Button>  
                    </Grid>              */}
                </Grid>
        </Dialog>
        <Dialog open={positionDetail} onClose={closePositionDetail}
                sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "40%"},
                                // height: "20rem",
                            },
                        },
                    }}
            >
                <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                        <Typography variant="h4">{"Registro Estiba"}</Typography>
                    </Grid>
                </Grid>
                <DialogContent>
                    {levelsBusy}                              
                </DialogContent>
                <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2rem", marginBottom: "1rem"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button onClick={closePositionDetail} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Atrás
                        </Button>  
                    </Grid>  
                    {/* <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button onClick={()=>handleNewPosition(positionName)} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Registro
                        </Button>  
                    </Grid>              */}
                </Grid>
        </Dialog>
        <Dialog open={productDetailBox} onClose={closeProductDetailBox}
                sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "40%"},
                            },
                        },
                    }}
            >
                <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                        <Typography variant="h4">Detalle Estiba</Typography>
                    </Grid>
                </Grid>
                <DialogContent sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {productDetail}                              
                </DialogContent>
                <Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2rem", marginBottom: "1rem"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button onClick={closeProductDetailBox} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Atrás
                        </Button>  
                    </Grid> 
                </Grid>
        </Dialog>
        </>
    )
}
