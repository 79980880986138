import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Colors from "../../components/ui/Colors";
import { Button, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import EditNoteIcon from '@mui/icons-material/EditNote';
import {getOpenCediOrders, createCediOrder, updateCediCheckpoint} from "../../utilityFunctions/WarehouseUtil";
import swal from 'sweetalert';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";


const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const newPlateReq = {
    transportPlate:"",
}

const batchLoadingReq = {
    id: "",
    cediOrderId: "",
    username: "",
};



export default function LabelReaderCedi(props) {

    const {loginUser} = props;

    const history = useHistory();
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        initialValues();
    }, []);

    const initialValues = () => {

        console.log(localStorage.getItem("currentCediOrder"));
        console.log(localStorage.getItem("currentCediPlate"));
        console.log(props.location.state.batch);

        batchLoadingReq.id = props.location.state.batch.id;
        closeOpenCediOrdersBox();
        setNewPlateBox(false);
        setCediBox(true);

        // if(localStorage.getItem("currentCediOrder") && localStorage.getItem("currentCediPlate")){
        //     batchLoadingReq.cediOrderId = localStorage.getItem("currentCediOrder");
        //     setNewPlateBox(false);
        //     setCediBox(true);
        // } else{
        //     setNewPlateBox(true);
        //     setCediBox(false);
        // }
    };


    const[newPlate, setNewPlate] = React.useState("");
    const[newPlateBox, setNewPlateBox] = React.useState(false);
    const[cediBox, setCediBox] = React.useState(true);

    const closeCediBox = () => {
        setCediBox(false);
    };

    const openNewPlateBox = () => {
        closeCediBox();
        setNewPlateBox(true);
    };

    const closeNewPlateBox = () => {
        setNewPlateBox(false);
    };

    const newPlateChange = (e) => {
        setNewPlate(e.target.value);
        newPlateReq.transportPlate = e.target.value;
    };

    const createNewPlate = async () => {
        
        setLoading(true);
        const orderCreated = await createCediOrder(newPlateReq, loginUser.jwtToken);
        setLoading(false);

        if(!orderCreated){
            swal({
                title: "Error",
                text: "No se pudo crear la orden",
                icon: "error",
                buttons: "Ok"
            });
            return;
        }

        swal({
            title: "Creada",
            icon: "success",
            buttons: "Ok"
        });

        localStorage.setItem("currentCediOrder", orderCreated.id);
        localStorage.setItem("currentCediPlate", orderCreated.transportPlate);

        initialValues();
    };

    const changePlate = async () => {

        setLoading(true);
        const cediOrders = await getOpenCediOrders(loginUser.jwtToken);
        setLoading(false);

        if(!cediOrders){
            swal({
                title: "Información",
                text: "No se encontraron órdenes abiertas",
                icon: "info",
                buttons: "Ok"
            });
        }

        renderOpenOrders(cediOrders);

    };

    const [openOrders, setOpenOrders] = React.useState("");
    const [openCediOrdersBox, setOpenCediOrdersBox] = React.useState(false);

    const closeOpenCediOrdersBox = () => {
        setOpenCediOrdersBox(false);
    };

    const renderOpenOrders = (cediOrders) => {

        setOpenOrders(
            <List sx={{width: "100%",}}>
                {cediOrders.map((order) => (
                    <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1rem"}}>
                        <ListItemButton key={generateRandomKey()} onClick={() => selectOrder(order)}>
                            <Typography key={generateRandomKey()} variant="h6">{`Placa: ${order.transportPlate}, Id: ${order.id}`}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        );

        closeCediBox();
        setOpenCediOrdersBox(true);

    };

    const selectOrder = async (order) => {
        localStorage.setItem("currentCediOrder", order.id);
        localStorage.setItem("currentCediPlate", order.transportPlate);

        initialValues();
    };

    const confirmCheckpoint = async () => {
        
        batchLoadingReq.cediOrderId = localStorage.getItem("currentCediOrder");
        batchLoadingReq.username = loginUser.accountId;

        setLoading(true);
        const checkCediOrder = await updateCediCheckpoint(batchLoadingReq, loginUser.jwtToken);
        setLoading(false);

        if(!checkCediOrder){
            swal({
                title: "Error",
                text: "No se pudo registrar el checkpoint",
                icon: "error",
                buttons: "Ok"
            });
            return;
        }

        swal({
            title: "Resgistrado",
            text: "Checkpoint registrado",
            icon: "success",
            buttons: "Ok"
        });

        history.push("/warehouse/checkpoint");

    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center", md: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
            }}
        >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :  
                <>
                <Dialog open={cediBox} onClose={closeCediBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "60%", md: "50%"},
                                height: "auto"
                            },
                        },
                    }}
                >
                    <List sx={{width: "100%",}}>
                        {localStorage.getItem("currentCediOrder") && localStorage.getItem("currentCediPlate") &&
                        <>
                        <ListItem disablePadding sx={{marginBottom: "2rem", textAlign: "center"}}>
                            <Typography variant="h6" sx={{fontWeight: "bold", marginLeft: "1.5rem"}}>{`Placa Actual: ${localStorage.getItem("currentCediPlate")}`}</Typography>
                        </ListItem>
                        <ListItem disablePadding divider sx={{marginBottom: "0.5rem"}}>
                            <ListItemButton  onClick={confirmCheckpoint}>
                                <Grid container>
                                    <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <LocalShippingIcon style={{fontSize: "2rem"}}/>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start"}}>
                                        <Typography variant="h6">Registrar</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        </>
                        }
                        <ListItem disablePadding divider sx={{marginBottom: "0.5rem"}}>
                            <ListItemButton  onClick={changePlate} sx={{textAlign: "center"}}>
                                <Grid container>
                                    <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <ChangeCircleIcon style={{fontSize: "2rem"}}/>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start"}}>
                                        <Typography variant="h6">Cambiar Placa</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{marginBottom: "0.5rem"}}>
                            <ListItemButton  onClick={openNewPlateBox} sx={{textAlign: "center"}}>
                                <Grid container>
                                    <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <EditNoteIcon style={{fontSize: "2rem"}}/>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start"}}>
                                        <Typography variant="h6">Nueva Placa</Typography>
                                    </Grid>
                                </Grid>                                
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Dialog>
                <Dialog open={newPlateBox} onClose={closeNewPlateBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "40%", md: "30%"},
                                height: "auto"
                            },
                        },
                    }}
                >
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.2rem"}}>
                            <TextField
                                sx={{width: "80%"}}
                                variant="standard"
                                fullWidth={true}
                                label={<Typography variant="body2">Nueva Placa</Typography>}
                                value={newPlate}
                                onChange={(e)=> newPlateChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                            <Button onClick={createNewPlate} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                Crear
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
                <Dialog open={openCediOrdersBox} onClose={closeOpenCediOrdersBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "60%", md: "50%"},
                                height: "auto"
                            },
                        },
                    }}
                >
                    {openOrders}
                </Dialog>
                </>
            }
        </Box>
    </Box>
    )
}
