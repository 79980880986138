import React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../components/ui/Colors";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import 'moment/locale/es';
import {particularOrderChange} from "../../utilityFunctions/WarehouseUtil.js";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const shippingRequest = {
    username: "",
    id: "",
    newAmount: "",

    date: "",
    purchaseOrder: "",
    request: "",
    invoice: "",
    client: "",
    address: "",
    phone: "",
    city: "",
    comment: "",
    unit: "",
    weight: "",
    kgPerUnit: "",
    product: "",
};

export default function SpecificShipping(props) {
    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=> {
        initialValues();
    }, []);

    const initialValues = () => {
        if(props.location && props.location.state && props.location.state.batch && props.location.state.change){

            shippingRequest.product = props.location.state.batch.warehouseProduct.description;
            shippingRequest.unit = props.location.state.batch.warehouseProduct.unit;

            shippingRequest.username = props.location.state.change.username;
            shippingRequest.id = props.location.state.change.id;
            shippingRequest.newAmount = props.location.state.change.newAmount;


            return props.location.state.batch;
        }


        return null;
    };

    const [selectedDate, setSelectedDate] = React.useState("");
    const [purchaseOrder, setPurchaseOrder] = React.useState("");
    const [request, setRequest] = React.useState("");
    const [invoice, setInvoice] = React.useState("");
    const [client, setClient] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [city, setCity] = React.useState("");
    const [weight, setWeight] = React.useState("");
    const [kgPerUnit, setKgPerUnit] = React.useState("");
    const [comment, setComment] = React.useState("");
    
    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format('YYYYMMDD'));
        shippingRequest.date = moment(date).format('YYYYMMDD');
    };

    const changePurchaseOrder = (e) => {
        setPurchaseOrder(e.target.value);
        shippingRequest.purchaseOrder = e.target.value;
    };

    const changeRequest = (e) => {
        setRequest(e.target.value);
        shippingRequest.request = e.target.value;
    };

    const changeInvoice = (e) => {
        setInvoice(e.target.value);
        shippingRequest.invoice = e.target.value;
    };

    const changeClient = (e) => {
        setClient(e.target.value);
        shippingRequest.client = e.target.value;
    };

    const changeAddress = (e) => {
        setAddress(e.target.value);
        shippingRequest.address = e.target.value;
    };

    const changePhone = (e) => {
        setPhone(e.target.value);
        shippingRequest.phone = e.target.value;
    };

    const changeCity = (e) => {
        setCity(e.target.value);
        shippingRequest.city = e.target.value;
    };

    // const changeProduct = (e) => {
    //     setProduct(e.target.value);
    //     shippingRequest.product = e.target.value;
    // };

    // const changeUnit = (e) => {
    //     setUnit(e.target.value);
    //     shippingRequest.unit = e.target.value;
    // };

    const changeWeight = (e) => {
        setWeight(e.floatValue);
        shippingRequest.weight = e.floatValue;
    };

    const changeKgPerUnit = (e) => {
        setKgPerUnit(e.floatValue);
        shippingRequest.kgPerUnit = e.floatValue;
    };

    const changeComment = (e) => {
        setComment(e.target.value);
        shippingRequest.comment = e.target.value;
    };

    const handleOrderChange = async() => {

        setLoading(true);
        const batchChanged = await particularOrderChange(shippingRequest, loginUser.jwtToken);
        setLoading(false);

        if(!batchChanged){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            
            return;
        }

        swal({
            title: "Actualización exitosa",
            icon: "success",
            buttons: "OK"
        });

    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} sm={12} md={12} sx={{width: "100%", marginTop: "2rem", textAlign: "center"}}>
                        <Typography variant="body" sx={{color: "black"}}>Cantidad</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{width: "100%", marginTop: "0", textAlign: "center"}}>
                        <Typography variant="h4" sx={{color: "black", fontWeight: "bold"}}>{Intl.NumberFormat('es-CO').format(Math.abs(props.location.state.batch.productAmount - props.location.state.change.newAmount))}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} sx={{width: "100%", marginTop: "0.5rem"}}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                timezone="America/Bogota"
                                locale="es" 
                                label={<Typography variant="body2">Fecha de despacho</Typography>}
                                inputFormat="DD/MM/YYYY"
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => 
                                    <TextField {...params} 
                                        variant="standard"
                                        fullWidth
                                        sx={{width: {xs: "90%", sm: "80%", md: "80%"}}}
                                    />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="purchaseOrder"
                            name="purchaseOrder"
                            label={<Typography variant="body2">Orden de Compra</Typography>}
                            value={purchaseOrder}
                            onChange={(e)=> changePurchaseOrder(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="request"
                            name="request"
                            label={<Typography variant="body2">Pedido</Typography>}
                            value={request}
                            onChange={(e)=> changeRequest(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="invoice"
                            name="invoice"
                            label={<Typography variant="body2">Factura</Typography>}
                            value={invoice}
                            onChange={(e)=> changeInvoice(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="client"
                            name="client"
                            label={<Typography variant="body2">Cliente</Typography>}
                            value={client}
                            onChange={(e)=> changeClient(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="address"
                            name="address"
                            label={<Typography variant="body2">Dirección</Typography>}
                            value={address}
                            onChange={(e)=> changeAddress(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="phone"
                            name="phone"
                            label={<Typography variant="body2">Teléfono</Typography>}
                            value={phone}
                            onChange={(e)=> changePhone(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="city"
                            name="city"
                            label={<Typography variant="body2">Ciudad</Typography>}
                            value={city}
                            onChange={(e)=> changeCity(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "90%"}}
                            name="weight"
                            customInput={TextField}
                            // prefix={'$ '}
                            suffix={' kg'}
                            type="weight"
                            label={<Typography variant="body2">Peso</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={weight}
                            onValueChange={(values) => {
                                changeWeight(values);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "90%"}}
                            name="kgPerUnit"
                            customInput={TextField}
                            // prefix={'$ '}
                            suffix={' kg'}
                            type="kgPerUnit"
                            label={<Typography variant="body2">Kg por unidad</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={kgPerUnit}
                            onValueChange={(values) => {
                                changeKgPerUnit(values);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "2rem"}}>
                        <TextField
                            variant="outlined"
                            fullWidth={true}
                            multiline
                            rows={2}
                            id="comment"
                            name="comment"
                            label={<Typography variant="body2">Comentario</Typography>}
                            value={comment}
                            onChange={(e)=> changeComment(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={handleOrderChange} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                            Cargar
                        </Button>
                    </Grid>
                    
                </Grid>
            }
            </Box>
        </Box>
    )
}


