import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import Colors from "../../components/ui/Colors";


export default function Timer({checkpiontTime}) {
    const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {

    // Parse the received Instant and convert it to a JavaScript Date object
    const parsedStartTime = new Date(checkpiontTime);

    // Set the parsed startTime
    setStartTime(parsedStartTime);
    setIsRunning(true);
  }, []);

  useEffect(() => {
    // Update the timer display every second when it's running
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => {
        setEndTime(new Date());
      }, 1000);
    }

    // Clear the interval when the component unmounts or the timer stops
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRunning]);

  const formatTimeDifference = () => {
    if (!startTime || !endTime) {
      return '0:00';
    }
    const differenceInSeconds = Math.floor((endTime - startTime) / 1000);
    const minutes = Math.floor(differenceInSeconds / 60);
    const seconds = differenceInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <Typography variant="h3" align="center" 
            sx={{
                fontWeight: 'bold',
                color: Colors.primary, // Use the secondary color from Material-UI's color palette
            }}
        >
      {formatTimeDifference()}
    </Typography>
    
  );
}