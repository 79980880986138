import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography, Select, MenuItem } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {getAllProductBatches, getProductBatchPage} from "../../utilityFunctions/WarehouseUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import Button from '@mui/material/Button';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

const cediDateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
};

let batchesArray;
let allBatchesArray;
const batchesCheckpoints = ["Producción", "Bodega", "Entregada"];
let productsArray;

const pageRequest = {
    size: "",
    page: "",
}

const pageSize=100;



export default function BatchesList(props) {

    const{loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const[disableNextButton, setDisableNextButton] = React.useState(false);

    React.useEffect(()=> {
        setLoading(true);
        if(!allBatches){
            initialValues();
        }
        
    },[]);

    const initialValues = async () => {

        pageRequest.page = 0;

        setLoading(true);
        // const batches = await getAllProductBatches(loginUser.jwtToken);
        const batches = await getProductBatchPage(pageRequest, loginUser.jwtToken);
        
        setLoading(false);

        if(!batches){
            return;
        }

        if(batches.length < pageSize){
            setDisableNextButton(true);
        }else{
            setDisableNextButton(false);
        }

        batchesArray = batches;
        allBatchesArray = batches;

        productsArray = [...new Set(batches.map(item => item.warehouseProduct.description))];

        renderBatches();

        return batches;
    };

    const [currentPage, setCurrentPage] = React.useState(1);

    const handlePageChange = async (newPage) => {
        setCurrentPage(newPage);
        pageRequest.page= newPage-1;

        setLoading(true);
        const receivedOrders = await getProductBatchPage(pageRequest, loginUser.jwtToken);
        setLoading(false);

        if(!receivedOrders){
            return;
        }

        if(receivedOrders.length < pageSize){
            setDisableNextButton(true);
        }else{
            setDisableNextButton(false);
        }

        batchesArray = receivedOrders;
        allBatchesArray = receivedOrders;

        renderBatches();
    };

    const[allBatches, setAllBatches] = React.useState("");

    const renderBatches = async () => {
        
        // const batches = await initialValues();


        if(!batchesArray){
            return;
        }

        batchesArray.sort((a, b) => {

            const codeA = new Date(a.expirationDate);
            const codeB = new Date(b.expirationDate);

            if (codeA < codeB) {
                return -1;
            } else if (codeA > codeB) {
                return 1;
            } else {
                return b.id - a.id;
            }
        });

        // console.log(batchesArray);
        
        setAllBatches(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>

                    {batchesArray.map((batch) => {
                        return(
                            <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                <Grid key={generateRandomKey()}  container spacing={1}>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        <Grid direction="column">
                                            <Grid item>
                                                <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                    Código Estiba
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                    {`${batch.id}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        <Grid direction="column">
                                            <Grid item>
                                                <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                    Descripción
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                    {`${batch.warehouseProduct.description}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        <Grid direction="column">
                                            <Grid item>
                                                <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                    Fecha vencimiento
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                    {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.expirationDate))}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        <Grid direction="column">
                                            <Grid item>
                                                <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                    Fecha producción
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                    {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.date))}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        <Grid direction="column">
                                            <Grid item>
                                                <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                    Cantidad
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                    {/* {`${new Intl.NumberFormat('es-CO').format(batch.warehouseProduct.content * batch.productAmount)}`} */}
                                                    {`${new Intl.NumberFormat('es-CO').format(batch.productAmount)}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        <Grid direction="column">
                                            <Grid item>
                                                <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                    Código Cedi
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item>
                                                {batch.cediOrder && batch.cediOrder.id ?
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        {`${batch.cediOrder.id}`}
                                                    </Typography>
                                                    :
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        Pendiente
                                                    </Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        <Grid direction="column">
                                            <Grid item>
                                                <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                    Fecha Cedi
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                {batch.cediOrder && batch.cediOrder.date ?
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        {`${new Intl.DateTimeFormat('es-CO', cediDateOptions).format(new Date(batch.cediOrder.date))}`}
                                                    </Typography>
                                                    :
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        Pendiente
                                                    </Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        <Grid direction="column">
                                            <Grid item>
                                                <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                    Placa Cedi
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                {batch.cediOrder && batch.cediOrder.transportPlate ?
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        {`${batch.cediOrder.transportPlate}`}
                                                    </Typography>
                                                    :
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        Pendiente
                                                    </Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        <Grid direction="column">
                                            <Grid item>
                                                <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                    Posición
                                                </Typography>
                                            </Grid>
                                            {batch.position && batch.inWarehouse===true?
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        {batch.position}
                                                    </Typography>
                                                </Grid>
                                            :
                                            batch.inWarehouse===true?
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        En Bodega
                                                    </Typography>
                                                </Grid>
                                            :
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        Fuera de Bodega
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>

                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.productionCheck ?
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                        Check Producción
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.productionCheck))}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                    Check Producción
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        Pendiente
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.supervisorWarehouseCheck ?
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                        Supervisor bodega
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.supervisorWarehouseCheck))}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                        Supervisor bodega
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        Pendiente
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2}  sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.warehouseDeliveryCheck ?
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                        Descargada en bodega
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.warehouseDeliveryCheck))}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                        Descargada en bodega
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        Pendiente
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2}  sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.warehousePickupCheck ?
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                        Recogida en bodega
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.warehousePickupCheck))}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                        Recogida en bodega
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        Pendiente
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2}  sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.transporterDeliveryCheck ?
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                        Entregada
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.transporterDeliveryCheck))}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                        Entregada
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        Pendiente
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2}  sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.supervisorDeliveryCheck ?
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                        Supervisor
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.supervisorDeliveryCheck))}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid direction="column">
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} sx={{color: "silver", fontSize: "0.7rem"}}>
                                                        Supervisor
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                        Pendiente
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2}  sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.productionCheckUsername ?
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {batch.productionCheckUsername}
                                            </Typography>
                                            :

                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                Pendiente
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2}  sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.supervisorWarehouseCheckUsername ?
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {batch.supervisorWarehouseCheckUsername}
                                            </Typography>
                                            :

                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                Pendiente
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2}  sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.warehouseDeliveryCheckUsername ?
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {batch.warehouseDeliveryCheckUsername}
                                            </Typography>
                                            :
                                            
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                Pendiente
                                            </Typography>
                                              
                                        }
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2}  sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.warehousePickupCheckUsername ?
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {batch.warehousePickupCheckUsername}
                                            </Typography>
                                            :
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                Pendiente
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2}  sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.transporterDeliveryCheckUsername ?
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {batch.transporterDeliveryCheckUsername}
                                            </Typography>
                                            :
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                Pendiente
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2}  sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                        {batch.supervisorDeliveryCheckUsername ?
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                {batch.supervisorDeliveryCheckUsername}
                                            </Typography>
                                            :
                                            <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                                                Pendiente
                                            </Typography>
                                        }
                                    </Grid> */}
                                </Grid>
                            </ListItem>
                        )
                    })

                    }
                
            </List>
        );
    };

    const[checkpointFilter, setCheckpointFilter] = React.useState("");

    const filterByCheckpoint = (e) => {
        setCheckpointFilter(e.target.value);

        const filter = e.target.value;
        
        if(filter === "Producción"){
            batchesArray = allBatchesArray.filter((batch) => batch.productionCheck && !batch.transporterDeliveryCheck);

        }

        else if(filter === "Bodega"){
            batchesArray = allBatchesArray.filter((batch) => batch.supervisorWarehouseCheck && batch.warehouseDeliveryCheck && !batch.warehousePickupCheck && !batch.transporterDeliveryCheck && !batch.supervisorDeliveryCheck);

        }

        else if(filter === "Entregada"){
            batchesArray = allBatchesArray.filter((batch) => batch.transporterDeliveryCheck && batch.supervisorDeliveryCheck);

        }

        else if(filter === "Todas"){
            batchesArray = allBatchesArray;

        }

        renderBatches();

    };

    const [productFilter, setProductFilter] = React.useState("");

    const filterByProduct = (e) => {
        
        const filter = e.target.value;
        setProductFilter(filter);

        if(filter === "Todas"){
            batchesArray = allBatchesArray;
        }

        else{
            batchesArray = allBatchesArray.filter((el) => el.warehouseProduct && el.warehouseProduct.description === filter);
        }

        renderBatches();
    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : 
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                        <Grid  item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center", textAlign: "center", marginTop: "2rem", marginBottom: "1.5rem"}}>
                            <Grid direction="column" sx={{width: "100%"}}>
                                <Grid item sx={{ textAlign: "center"}}>
                                    <Typography variant="body2">Estado</Typography>
                                </Grid>
                                <Grid item>
                                    <Select
                                        sx={{width: "60%"}}
                                        fullWidth
                                        variant="standard"
                                        value={checkpointFilter}
                                        onChange={(e) => filterByCheckpoint(e)}
                                    >
                                        <MenuItem value="Todas">Todas</MenuItem>
                                        {batchesCheckpoints.map((el) => (
                                            <MenuItem key={generateRandomKey()} value={el}>
                                                {el}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                        {productsArray &&
                        <Grid  item xs={6} sm={6} md={6} sx={{ display: "flex", justifyContent: "center", textAlign: "center", marginTop: "2rem", marginBottom: "1.5rem"}}>
                            <Grid direction="column" sx={{width: "100%"}}>
                                <Grid item sx={{textAlign: "center"}}>
                                    <Typography variant="body2">Producto</Typography>
                                </Grid>
                                <Grid item sx={{textAlign: "center"}}>
                                    <Select
                                    sx={{width: "60%"}}
                                    fullWidth
                                    variant="standard"
                                    value={productFilter}
                                    onChange={(e) => filterByProduct(e)}
                                >
                                    <MenuItem value="Todas">Todas</MenuItem>
                                    {productsArray.map((el) => (
                                        <MenuItem key={generateRandomKey()} value={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {allBatches}
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "center", marginTop: "2rem", marginBottom: "2rem" }}>

                        <Button
                            onClick={() => handlePageChange(currentPage - 1)}
                            variant="contained"
                            disabled={currentPage === 1}
                            sx={{ marginRight: "1rem" }}
                        >
                            Anterior
                        </Button>
                        <Typography>{currentPage}</Typography>
                        <Button
                            onClick={() => handlePageChange(currentPage + 1)}
                            variant="contained"
                            disabled={disableNextButton}
                            sx={{ marginLeft: "1rem" }}
                        >
                            Siguiente
                        </Button>
                </Grid>
            </Grid>
            }
            </Box>
        </Box>
    )
}
