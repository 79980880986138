const Colors = {
  // primary: "#34f2c6", //wupo's color
  // primary: "#0F3876", //Dark blue 0F3876
  primary: "#1c2444", //cool green
  secondary: "#1c2444", //green
  third: "#E2062C", // cool red
  hover: "#021B79",

  fourth: "#C28100", // wupo
  fifth: "#C7C4C4", // Grey
  credit: "#6DD47E", //wupo Green
  debit: "#ff003f", // wupo red
  labelColor: "#333",  // Dark grey
  button1: "#c7357c", //pink
  button2: "#6DD47E", // green
  button3: "#FFD55A" // yellow
}

export default Colors