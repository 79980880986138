import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {getAllProductBatches} from "../../utilityFunctions/WarehouseUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function DurationsReport(props) {

    const{loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=> {
        setLoading(true);
        renderReport();
        
    },[]);


    const initialValues = async () => {
        setLoading(true);
        const batches = await getAllProductBatches(loginUser.jwtToken);
        setLoading(false);
        
        return batches;
    };

    const[durationReport, setDurationReport] = React.useState("");

    const renderReport = async() => {

        const batches = await initialValues();

        if(!batches){
            return;
        }

        batches.sort((a, b) => {

            const codeA = a.id;
            const codeB = b.id;

            if (codeA < codeB) {
                return 1;
            } else if (codeA > codeB) {
                return -1;
            } else {
                return 0;
            }
        });

        setDurationReport(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {batches.map((batch) => {
                    return(
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <Grid key={generateRandomKey()}  container spacing={1}>
                                <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{batch.id}</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{batch.warehouseProduct.description}</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{batch.qrToWarehouse}</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{batch.warehouseToTransport}</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    )
                })}
            </List>
        );

    }

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container sx={{marginTop: "1rem"}}>
                            <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Código Estiba</Typography>
                            </Grid>
                            <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Producto</Typography>
                            </Grid>
                            <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Tiempo a Bodega</Typography>
                            </Grid>
                            <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Tiempo a Transporte</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {durationReport}
                    </Grid>
                </Grid>
            }
            </Box>
        </Box>
    )
}
