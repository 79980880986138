import React, { useState } from "react";
import AuthedRoutes from "../AuthedRoutes";
import Routes from "../Routes";
import {
  exchangeCurrencies,
  retailerTokens,
  wupoServerUri,
  retailOperation,
  stockOrderSymbol,
  bridgeAsset,
  enableAccountAsset,
  enablePaymentValue,
  environmentReferenceFundingAsset
} from "../Api";
import { fetchVisitorAccounts, fetchWupoAssets } from "../utilityFunctions/FetchUtil";
import { fetchLoan, fetchLoanParameters, fetchRefinancingParameters, 
  fetchSimulatorParameters } from "../utilityFunctions/CreditUtil";

import {fetchExplanations} from "../utilityFunctions/AdminUtil";
import {getAllDealsDefinitions, getAllDeals} from "../utilityFunctions/DealsUtil";
import {getInventory} from "../utilityFunctions/WarehouseUtil";

let isAuth=false;

export default function Auth() {
  const [user, setUser] = useState();
  const [ledgerAccounts, setLedgerAccounts] = useState([]);
  const [loginUser, setLoginUser] = useState({});
  const [registerUser, setRegisterUser] = useState();
  const [visitorAccounts, setVisitorAccounts] = useState();
  const [visitorLoans, setVisitorsLoans] = useState();
  const [creditRiskStatus, setCreditRiskStatus] = useState();
  const [navBarEnabled, setNavBarEnabled] = useState(true);
  const [wupoAvailableAssets, setWupoAvailableAssets] = useState();
  const [loanCostsSettings, setLoanCostsSettings] = useState();
  const [loanConditionsSettings, setLoanConditionsSettings] = useState();
  const [refinancingCostsSettings, setRefinancingCostsSettings] = useState();
  const [refinancingConditionsSettings, setRefinancingConditionsSettings] = useState();
  const [displayExplanations, setDisplayExplanations] = useState();
  const [dealsDefinitions, setDealsDefinitions] = useState();
  const [dealsList, setDealsList] = useState();
  const [warehouseProducts, setWarehouseProducts] = useState("");

  const updateNavBarEnabled = (enabled) =>{
    setNavBarEnabled(enabled)
    return navBarEnabled;
  };

  const updateDisplayExplanations = (username) => {
    return (new Promise((resolve) => {
      fetchExplanations(username).then((explanations) => {
        setDisplayExplanations(explanations);
        resolve(explanations);
      })
    }));
  };

  const updatedUserData = (userData) => {
    if(userData!==null){
      isAuth=true;
    }else{
      isAuth=false;
    }
    
    // console.log(userData); // DEBUG PRINTING
    const userUpdate = ledgerAccounts.find(
      (el) => userData && el.accountId === userData.accountId
    );
    if (userUpdate) {
      // localStorage.setItem(
      //   "loggedInUser",
      //   JSON.stringify({ ...userData, ...userUpdate })
      // );
      setUser({ ...userData, ...userUpdate });
      // console.log("user in updatedUserData: " + JSON.stringify(user)) // DEBUG PRINTING
    } else {
      // setUser(userData);
      setLoginUser({...userData, loginUser});
      // console.log("Login user in updateUserData else:" + JSON.stringify(loginUser)) // DEBUG PRINTING
      /** It checks if the environment variable that defines the retailer login is enabled. 
       * If enabled post the jwt received from the middel to the express endpoint */
      // console.log("retailer enable: " + retailOperation.retailLoginEnable); // DEBUG PRINTING
      // console.log("retailer endpoint: " + retailOperation.retailerJwtEndpoint); // DEBUG PRINTING
      if(retailOperation.retailLoginEnable){
        if(userData !== null){
          // console.log("jwt Token: " + JSON.stringify(userData.jwtToken)) // DEBUG PRINTING
          const retailerJwt={
            jwtToken: userData.jwtToken
          };
          
        }
      }
    }
  };

  const updateRegisterUser = (userData) => {
    setRegisterUser(userData)
  };

  const updateLedgerAccounts = (jwtToken) => {
    isAuth=true;
    fetch(`${wupoServerUri.devnet}/api/secure/ledger/accounts`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${jwtToken}`, 
        'Content-Type': 'application/json'                   
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        // console.log(results); //LOANS DEBUG PRINTING
        setLedgerAccounts(results);
        try {
          // const storageUser = JSON.parse(localStorage.getItem("loggedInUser"));
          // console.log(storageUser);
          // ToDo: Use Token
          const existUser =
            loginUser &&
            results.find((el) => el.account === loginUser.accountId);
            // console.log(existUser)
    
          if (existUser) {
            setUser(existUser);
            // console.log(existUser); //LOANS DEBUG PRINTING
            // console.log("user in updateLedger if: " + JSON.stringify(user)) // DEBUG PRINTING
          }
          // return(results);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        console.log("Error msg: " + error);
      });
  }

  
  const updateUser = (asset) => {
    // console.log(loginUser);
    // console.log(asset);
    isAuth=true;


    const existUser =
            user &&
            ledgerAccounts.find((el) => el.account === user.account && el.asset===asset);
    if (existUser) {
      setUser(existUser);
      // console.log(existUser)
      // console.log("user in updateLedger if: " + JSON.stringify(user)) // DEBUG PRINTING
    }

  }

  const updateVisitorAccounts = (visitorAccountsRequest, jwtToken)=>{
    return(
      fetchVisitorAccounts(visitorAccountsRequest, jwtToken).then((usernameAccounts) =>{
        setVisitorAccounts(usernameAccounts);
        return(usernameAccounts);
      })
    )

  };

  const updateVisitorLoans = (visitorLoanRequest, jwtToken)=>{
    return(
      fetchLoan(visitorLoanRequest, jwtToken).then((accountLoans) =>{
        setCreditRiskStatus(accountLoans.creditRiskStatus);
        // if(accountLoans.visitorLoan){
          setVisitorsLoans(accountLoans.visitorLoan);
        // }
        return(accountLoans.visitorLoan);
      })
    )
  };

  const updateWupoAssets = (jwtToken) => {
    return(
      fetchWupoAssets(jwtToken).then((availableAssets) =>{
        setWupoAvailableAssets(availableAssets);
        return(availableAssets);
      })
    )
  };

  const updateLoanSettings = async (jwtToken) =>{
    return(
      fetchLoanParameters(jwtToken).then((loanSettings) =>{
        setLoanCostsSettings(loanSettings.loanCosts);
        setLoanConditionsSettings(loanSettings.loanConditions);

        return(loanSettings);
      })
    );
  };

  const updateRefinancingSettings = async (jwtToken) =>{
    return(
      fetchRefinancingParameters(jwtToken).then((loanSettings) =>{
        setRefinancingCostsSettings(loanSettings.loanCosts);
        setRefinancingConditionsSettings(loanSettings.loanConditions);

        return(loanSettings);
      })
    );
  };

  const updateSimulatorSettings = () => {
    return( new Promise((resolve) => {
      fetchSimulatorParameters().then((simulatorSettings) => {
        resolve(simulatorSettings);
      })
    }));  
  }

  const loadProductsAndSetting = async (fromHistory, account, jwtToken) => {

      const loadedInfo = {
        visitorLoans: undefined,
        visitorAccounts: undefined,
        loanSettings: undefined,
        refinancingSettings: undefined,
        wupoAssets: undefined,
        ledgerAccounts: undefined,
      };

      return (new Promise((resolve) => {
        if(fromHistory === "/"){
            
          const loanPromise = new Promise((resolve => {
            updateVisitorLoans(account, jwtToken).then((loan) => {
              loadedInfo.visitorLoans = loan;
              resolve(loan);
            });
          })); 

          const loanSettingsPromise = new Promise((resolve) => {
            updateLoanSettings(jwtToken).then((settings) => {
              loadedInfo.loanSettings = settings;
              resolve(settings);
            });
          }); 

          const refinancingSettingsPromise = new Promise((resolve) => {
            updateRefinancingSettings(jwtToken).then((settings) => {
              loadedInfo.refinancingSettings = settings;
              resolve(settings);
            });
          }); 

          const ledgerAccountsPromise = new Promise((resolve) => {
            updateLedgerAccounts(jwtToken);
            resolve("ok");
          }); 

          const wupoAssetsPromise = new Promise((resolve) => {
            updateWupoAssets(jwtToken).then((assets) => {
              loadedInfo.wupoAssets = assets;
              resolve(assets);
            });
          }); 

          const accountsPromise = new Promise((resolve) => {
            updateVisitorAccounts(account, jwtToken).then((clientAccounts) => {
              loadedInfo.visitorAccounts = clientAccounts;
              resolve(clientAccounts);
            }); 
          }); 

          Promise.all([loanPromise, loanSettingsPromise, refinancingSettingsPromise, ledgerAccountsPromise,
            wupoAssetsPromise, accountsPromise]).then((result) => {
              console.log(result);
              resolve(loadedInfo);
          })

        }

        else if(fromHistory === "/login") {
          resolve(loadedInfo);
        }

        else if(fromHistory === "/account/create_credit"){
          const loanSettingsPromise = new Promise((resolve) => {
            updateLoanSettings(jwtToken).then((settings) => {
              loadedInfo.loanSettings = settings;
              resolve(settings);
            });
          }); 
          const loanPromise = new Promise((resolve) => {
            updateVisitorLoans(account, jwtToken).then((loan) => {
              loadedInfo.visitorLoans = loan;
              resolve(loan);
            });
          }); 
          const wupoAssetsPromise = new Promise((resolve) => {
            updateWupoAssets(jwtToken).then((assets) => {
              loadedInfo.wupoAssets = assets;
              resolve(assets);
            });
          }); 
          const accounts = new Promise((resolve) => {
            updateVisitorAccounts(account, jwtToken).then((clientAccount) => {
              loadedInfo.visitorAccounts = clientAccount;
              resolve(clientAccount);
            });
          });

          updateLedgerAccounts(jwtToken); //Not in the pomise all for performance reasons... only required for the autocomplete in payment

          Promise.all([loanPromise, accounts, loanSettingsPromise, wupoAssetsPromise]).then((result) => {
            console.log(result);
            resolve(loadedInfo);
          });
        }

        else if(fromHistory === "/credit/handle_credit"){
          
          const loanPromise = new Promise((resolve) => {
            updateVisitorLoans(account, jwtToken).then((loan) => {
              loadedInfo.visitorLoans = loan;
              resolve(loan);
            });
          }); 

          const accountsPromise = new Promise((resolve) => {
            updateVisitorAccounts(account, jwtToken).then((clientAccount) => {
              loadedInfo.visitorAccounts = clientAccount;
              resolve(clientAccount);
            });
          })

          const refinancingSettingsPromise = new Promise((resolve) => {
            updateRefinancingSettings(jwtToken).then((settings) => {
              loadedInfo.refinancingSettings = settings;
              resolve(settings);
            });
          }); 


          updateLedgerAccounts(jwtToken); //Not in the pomise all for performance reasons... only required for the autocomplete in payment

          Promise.all([loanPromise, accountsPromise, refinancingSettingsPromise]).then((result) => {
            console.log(result);
            resolve(loadedInfo);
          });
        }

        else if(fromHistory === "/account/handle_account"){
          const accountsPromise = new Promise((resolve) => {
            updateVisitorAccounts(account, jwtToken).then((clientAccounts) => {
              loadedInfo.visitorAccounts = clientAccounts;
              resolve(clientAccounts);
            }); 
          }); 
          const ledgerAccountsPromise = new Promise((resolve) => {
            updateLedgerAccounts(jwtToken);
            resolve("ok");
          });
          
          

          Promise.all([accountsPromise, ledgerAccountsPromise]).then((result) => {
            console.log(result);
            resolve(loadedInfo);
          });
        }

        else if(fromHistory === "/account/create_account"){
          
          const accountsPromise = new Promise((resolve) => {
            updateVisitorAccounts(account, jwtToken).then((clientAccounts) => {
              loadedInfo.visitorAccounts = clientAccounts;
              resolve(clientAccounts);
            }); 
          }); 
          const ledgerAccountsPromise = new Promise((resolve) => {
            updateLedgerAccounts(jwtToken);
            resolve("ok");
          }); 

          Promise.all([accountsPromise, ledgerAccountsPromise]).then((result) => {
            console.log(result);
            resolve(loadedInfo);
          });
        }

        else if(fromHistory === "/simulator"){ 
            updateSimulatorSettings().then((simulatorSettings) => {
              console.log(simulatorSettings);
              resolve(simulatorSettings);
            });
        }
      }));


  };

  const updateDealsDefinitions = async (jwtToken) => {

    const definitions = await getAllDealsDefinitions(jwtToken);
    setDealsDefinitions(definitions);

    return new Promise((resolve) => {
      resolve(definitions);
    });

  };

  const updateDealsList = async (jwtToken) => {

    const deals = await getAllDeals(jwtToken);
    setDealsList(deals);

    return new Promise((resolve) => {
      resolve(deals);
    });

  };

  const getWarehouseProducts = async(jwtToken) => {
    
    const allProducts = await getInventory(jwtToken);

    setWarehouseProducts(allProducts)
    
    return( new Promise((resolve) => {
      resolve(allProducts);
    })); 
  };
  
  return isAuth ? ( //loginUser
        <AuthedRoutes
          ledgerAccounts={ledgerAccounts}
          updateLedgerAccounts={updateLedgerAccounts}
          user={user}
          loginUser={loginUser}
          retailerTokens={retailerTokens}
          exchangeCurrencies={exchangeCurrencies}
          wupoServerUri={wupoServerUri}
          stockOrderSymbol={stockOrderSymbol}
          bridgeAsset={bridgeAsset}
          enableAccountAsset={enableAccountAsset}
          enablePaymentValue={enablePaymentValue}
          environmentReferenceFundingAsset={environmentReferenceFundingAsset}
          userdata={updatedUserData}
          updateUser={updateUser}
          updateVisitorAccounts={updateVisitorAccounts}
          visitorAccounts={visitorAccounts}
          updateVisitorLoans={updateVisitorLoans}
          visitorLoans={visitorLoans}
          creditRiskStatus={creditRiskStatus}
          updateNavBarEnabled={updateNavBarEnabled}
          navBarEnabled={navBarEnabled}
          updateWupoAssets={updateWupoAssets}
          wupoAvailableAssets={wupoAvailableAssets}
          loanCostsSettings={loanCostsSettings}
          loanConditionsSettings={loanConditionsSettings}
          updateLoanSettings={updateLoanSettings}
          refinancingCostsSettings={refinancingCostsSettings}
          refinancingConditionsSettings={refinancingConditionsSettings}
          updateRefinancingSettings={updateRefinancingSettings}
          loadProductsAndSetting={loadProductsAndSetting}
          displayExplanations={displayExplanations}
          updateDealsDefinitions={updateDealsDefinitions}
          dealsDefinitions={dealsDefinitions}
          updateDealsList={updateDealsList}
          dealsList={dealsList}
          getWarehouseProducts={getWarehouseProducts}
          warehouseProducts={warehouseProducts}
        />
      )
      :
      (
        <Routes 
          userdata={updatedUserData} 
          updateRegisterUser={updateRegisterUser}
          wupoServerUri={wupoServerUri}
          registerUser={registerUser}
          updateNavBarEnabled={updateNavBarEnabled}
          navBarEnabled={navBarEnabled}
          loadProductsAndSetting={loadProductsAndSetting}
          visitorLoans={visitorLoans}
          visitorAccounts={visitorAccounts}
          loanCostsSettings={loanCostsSettings}
          loanConditionsSettings={loanConditionsSettings}
          displayExplanations={displayExplanations}
          updateDisplayExplanations={updateDisplayExplanations}
        />
      );
}
