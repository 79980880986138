import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import swal from "sweetalert";
import {getInventory} from "../../utilityFunctions/WarehouseUtil";
import Button from '@mui/material/Button';
import Colors from "../../components/ui/Colors";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let allProducts;

export default function TransportOrder(props) {

    const{loginUser, warehouseProducts} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=> {
        renderOrder();
    }, []);

    const initialValues = async () => {
        allProducts = await getInventory(loginUser.jwtToken);

        if(props.location.state && props.location.state.order){
            return props.location.state.order;
        }

        return null;
    };


    const convertArray = (originalArray) => {
        
        const result = [];
        
        originalArray.forEach((currentObj) => {
            // console.log(currentObj.orderCode);
            
            const existingGroup = result.find((group) => group.orderCode === currentObj.orderCode);
            
            // console.log(existingGroup);
            
            if (existingGroup) {
                existingGroup.orders.push(currentObj);
            } else {
                result.push({
                    orderCode: currentObj.orderCode,
                    orders: [currentObj],
                });
            }
        });

        // console.log(result);

        return result;

        
    };

    const reduceByItem = (originalArray) => {
        const uniqueItemNumbers = new Set(); // Create a set to store unique itemNumber values
    
        const reducedArray = originalArray.reduce((result, obj) => {
            // Check if the itemNumber is not already in the set
            if (!uniqueItemNumbers.has(obj.itemNumber)) {
                result.push({ itemNumber: obj.itemNumber, description: obj.description });
                uniqueItemNumbers.add(obj.itemNumber); // Add the itemNumber to the set
            }
    
            return result;
        }, []); // Initialize result as an empty array
    
        return reducedArray;
    };

    const sortByString = (arrayOfObjects) => {
        arrayOfObjects.sort((a, b) => {
            const nameA = a.description.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
            const nameB = b.description.toUpperCase();
          
            if (nameA < nameB) {
              return -1;
            }
          
            if (nameA > nameB) {
              return 1;
            }
          
            return 0; // Names are equal
        });

        return arrayOfObjects;
    };

    const [order, setOrder] = React.useState("");
    
    const renderOrder = async () => {

        setLoading(true);

        const selectedOrder= await initialValues();

        const ordersArray = selectedOrder.deliveryOrderResponse;

        if(!ordersArray){
            swal({
                title: "Orden incompleta",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        ordersArray.sort((a, b) => a.orderCode - b.orderCode);

        // const productsArray = ordersArray.map((el) => {
        //     return{itemNumber: el.itemNumber, description: el.description}
        // });

        const productsArray = reduceByItem(ordersArray);

        sortByString(productsArray);

        const accumulatedArray = convertArray(ordersArray);

        setOrder(
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow sx={{backgroundColor: "silver"}}>
                            <TableCell key={generateRandomKey()}>
                                <Typography variant="body2">Código</Typography>
                            </TableCell>
                            <TableCell key={generateRandomKey()}>
                                <Typography variant="body2">Descripción</Typography>
                            </TableCell>
                            {accumulatedArray.map((orderEl) => {
                                return(
                                    <TableCell key={generateRandomKey()}>
                                        <Typography key={generateRandomKey()} variant="body2">{orderEl.orderCode}</Typography>
                                    </TableCell>
                                )
                            })
                            }
                            <TableCell key={generateRandomKey()}>
                                <Typography variant="body2">Total Unidades</Typography>
                            </TableCell>
                            <TableCell key={generateRandomKey()}>
                                <Typography variant="body2">Total Descargada</Typography>
                            </TableCell>
                            <TableCell key={generateRandomKey()}>
                                <Typography variant="body2">Total Pacas</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productsArray.map((product) => {
                            let totalAmount=0;
                            let deliveredAmount=0;
                            let bales = 1;
                            if(warehouseProducts){
                                const foundProduct = warehouseProducts.find((obj) => obj.itemNumber === product.itemNumber);
                                if(foundProduct){
                                    bales = foundProduct.unitsPerBale;
                                }
                            }
                            return(
                                <TableRow key={generateRandomKey()}>
                                    <TableCell key={generateRandomKey()} onClick={()=>manualPickupSelection(product)} sx={{cursor: "pointer"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{product.itemNumber}</Typography>
                                    </TableCell>
                                    <TableCell key={generateRandomKey()} onClick={()=>manualPickupSelection(product)} sx={{cursor: "pointer"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{product.description}</Typography>
                                    </TableCell>
                                    {accumulatedArray.map((orderEl) => {
                                        let orderProductAmount = 0;
                                        orderEl.orders.forEach((item) => {                                         
                                            if(item.itemNumber === product.itemNumber){
                                                totalAmount=totalAmount+parseFloat(item.orderAmount);
                                                orderProductAmount=orderProductAmount+parseFloat(item.orderAmount);
                                                if(item.individualsAmount){
                                                    deliveredAmount=deliveredAmount+parseFloat(item.currentOrderAmount + item.individualsAmount); 
                                                }
                                                else{
                                                    deliveredAmount=deliveredAmount+parseFloat(item.currentOrderAmount);
                                                }
                                            }
                                        });
                                        return(
                                           
                                            <TableCell key={generateRandomKey()}>
                                                    <Typography key={generateRandomKey()} variant="body2">{new Intl.NumberFormat('es-CO').format(orderProductAmount)}</Typography>
                                            </TableCell>
                                        )
                                    })
                                    }
                                    <TableCell key={generateRandomKey()}>
                                        <Typography key={generateRandomKey()} variant="body2">{new Intl.NumberFormat('es-CO').format(totalAmount)}</Typography>
                                    </TableCell>
                                    <TableCell key={generateRandomKey()}>
                                        <Typography key={generateRandomKey()} variant="body2">{new Intl.NumberFormat('es-CO').format(deliveredAmount)}</Typography>
                                    </TableCell>
                                    <TableCell key={generateRandomKey()}>
                                        <Typography key={generateRandomKey()} variant="body2">{new Intl.NumberFormat('es-CO').format(totalAmount / bales)}</Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        );

        setLoading(false);
    };

    const goToBatches = () => {
        history.push({
            pathname: "/warehouse/transport-order-batches",
            state: {
                order:props.location.state.order,
            }
        });
    }

    const manualPickupSelection = async(selectedProduct) => {
        
        // Use reduce to accumulate orderAmount
        const totalOrderAmount = props.location.state.order.deliveryOrderResponse.reduce((accumulator, currentOrder) => {
            return accumulator + parseFloat(currentOrder.orderAmount);
        }, 0);

        const batchesRequest = {
            order: props.location.state.order,
            description: selectedProduct.description,
            itemNumber: selectedProduct.itemNumber,
            amount: totalOrderAmount
        };

        history.push({
            pathname: "/warehouse/batches-order",
            state: {
                batchesRequest: batchesRequest
            }
        })
    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : 
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        {order}
                    </Grid>
                    {order &&
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                        <Button onClick={goToBatches} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Estibas
                        </Button>
                    </Grid>
                    }
                </Grid>
            }
            </Box>
        </Box>
    )
}
