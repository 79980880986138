import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import swal from 'sweetalert'
import Content from "../../layout/Content"
import Input from "../../components/Input"
import AppLabel from "../../components/ui/AppLabel"
import DefaultSection from "../../components/ui/DefaultSection"
import AppButton from "../../components/ui/AppButton"
import Colors from "../../components/ui/Colors"

export default function FundingWupoPayment(props) {

  const history = useHistory()

  const [inputFromChild, setInputFromChild] = useState();
  const [eraseLabel, setEraseLabel] = useState(false);

  useEffect(() => {
    swal({
      title: "¿QUÉ HAGO?",
      text: `\n Ingresa un valor en USD para fondear la cuenta que escogiste`,
      icon: "success",
      buttons: {
        cancel: "Entiendo",
        info: "Más Info",
        admin: "Admin"
      }
    })
    .then(value => {
      switch (value) {
        case "info":
          swal({
            title: "¿QUÉ ES ESTO?",
            text: `\n Con el valor ingresado fondeamos tu cuenta en wupo.NET \n \n`,
            icon: "success",
            button: "Súper"
          });
          break;
        case "admin":
          swal({
            title: "PARA ADMINS E INVERSIONISTAS",
            text: `\n A diferencia de la funcionalidad de fondeo en cuentas ya existentes (en la que que se transfiere el valor a la cuenta en ilp y en xrpl), en esta funcionalidad el fondeo es usado directamente por una cuenta de WUPO para hacer el pago. \n \n
                        Te invitamos a revisar la consola (En Chrome: click derecho -> inspeccionar -> console) para ver lo que pasa por detrás en tiempo real!\n\n        
                        Actualmente, el valor ingresado simula un pago leído por una API con la infraestructura de pagos usada`,
            icon: "success",
            button: "Súper"
          });
          break;
        default:
      }
    });
  }, [])

  const handleOnclick = (e) => {
    // dataForParent(inputFromChild, e.target.id);
    history.push("/")
    setEraseLabel(true);
  }

  return (
    <div style={{
      width: "100%",
      display: "flex",
      flexDirection: "column"
    }}>
      <AppLabel
        bgColor={Colors.secondary}
        title="VALOR FONDEO"
        subTitle="(USD)"
      />
      <Content style={{ flex: 1 }}>
        <Input
          onChangeInfo={e => setInputFromChild(e.target.value)}
          eraseInput={eraseLabel}
          inputIcon="assetAmount"
        />
        <AppButton primary onClick={handleOnclick}>Continuar</AppButton>
      </Content>
    </div>
  )
}
