import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Typography, Select, MenuItem, Button} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Colors from "../../components/ui/Colors";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import swal from 'sweetalert';
import {getAllTransportOrders, closeTransportOrder, getAllRawTransportOrders, getTransportOrderResponse} from "../../utilityFunctions/WarehouseUtil";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

let selectedOrder;

const orderClosingRequest = {
    id: "",
    lockNumbers: "",
};

let OrdersDates;
let ordersArray;
let allOrders;

export default function TransportOrdersList(props) {
    const {loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=> {
        orderClosingRequest.id=null;
        initialValues();
    },[]);

    const initialValues = async () => {
        setLoading(true);
        // const orderObjects = await getAllTransportOrders(loginUser.jwtToken);
        const orderObjects = await getAllRawTransportOrders(loginUser.jwtToken);
        setLoading(false);

        OrdersDates = [...new Set(orderObjects.map(item => item.date))];

        allOrders=orderObjects;

        ordersArray=orderObjects;

        renderOrderList();

        return orderObjects;
    }

    const [ordersList, setOrdersList] = React.useState("");

    const renderOrderList = async () => {

        if(!ordersArray){
            
            return;
        }

        ordersArray.sort((a, b) => {
            // Compare by 'status' first
            const statusComparison = a.status.localeCompare(b.status);
          
            // If statuses are different, return the status comparison result
            if (statusComparison !== 0) {
              return statusComparison;
            }
          
            // If statuses are the same, compare by 'date'
            return new Date(b.date) - new Date(a.date);
        });

        setOrdersList(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {ordersArray.map((order) => {
                    return(
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton key={generateRandomKey()} onClick={() => openActionBox(order)}>
                                <Grid key={generateRandomKey()}  container spacing={1}>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(order.date))}`}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.carPlate}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.driverId}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.destination}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.id}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.status === "active" ? "Activa" : "Completada"}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    )
                })

                }
            </List>
        );
    };

    const selectOrder = async (order) => {

        const orderResponseRequest = {
            id: selectedOrder.id,
        };

        setLoading(true);
        const orderResponse = await getTransportOrderResponse(orderResponseRequest, loginUser.jwtToken);
        setLoading(false);

        if(!orderResponse){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        history.push({
            pathname: "/warehouse/transport-order",
            state: {
                // order:selectedOrder,
                order: orderResponse,
            }
        });
    };

    const[actionBox, setActionsBox] = React.useState(false);

    const closeActionBox = () => {
        setActionsBox(false);
    };

    const openActionBox = (order) => {
        selectedOrder = order;
        orderClosingRequest.id = order.id;
        setActionsBox(true);
    };

    const completeOrder = async () => {
        closeActionBox();
        closeOrderLocksBox();
        setLoading(true);
        const orderClosed = await closeTransportOrder(orderClosingRequest, loginUser.jwtToken);
        setLoading(false);

        if(!orderClosed){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        initialValues();

        swal({
            title: "Actualización exitosa",
            icon: "success",
            buttons: "OK"
        });
    };

    const batchesForOrders = () => {
        history.push({
            pathname: "/warehouse/active-orders",
            state: {
                order:selectedOrder,
            }
        });
    };

    const changeOrder = () => {
        history.push({
            pathname: "/warehouse/change-transport",
            state: {
                order:selectedOrder,
            }
        })
    };

    const pickupBatches = () => {
        history.push({
            pathname: "/warehouse/batches-pickup",
            state: {
                order:selectedOrder,
            }
        })
    };

    const [selectedDate, setSelectedDate] = React.useState("");

    

    const changeSelectedDate = (e) => {

        setSelectedDate(e.target.value);

        if(e.target.value === "all"){
            ordersArray=allOrders;
        }

        else{
            ordersArray = allOrders.filter((el) => el.date === e.target.value);
        }

        renderOrderList();
    };

    const[datesFilter, setDatesFilter] = React.useState(false);

    React.useEffect(()=> {
        if(OrdersDates){
            setDatesFilter(true);
        }
        else{
            setDatesFilter(false);
        }
    }, [OrdersDates]);

    const [orderLocksBox, setOrderLocksBox] = React.useState(false);
    const [orderLocks, setOrderLocks] = React.useState("");

    const closeOrderLocksBox = () => {
        setOrderLocksBox(false);
    };

    const orderLocksChange = (e) => {
        setOrderLocks(e.target.value);
        orderClosingRequest.lockNumbers=e.target.value;
    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : 
            <>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container sx={{marginTop: "1.5rem"}}>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Grid container>
                                    <Grid  item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline', cursor: "pointer"}}>Fecha de Transporte</Typography>
                                    </Grid>
                                    <Grid  item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                    {OrdersDates &&
                                        <Select
                                            sx={{width: "60%"}}
                                            fullWidth
                                            variant="standard"
                                            value={selectedDate}
                                            onChange={(e) => changeSelectedDate(e)}
                                        >
                                            <MenuItem value="all">Todas</MenuItem>
                                            {OrdersDates.map((date) => (
                                                <MenuItem key={generateRandomKey()} value={date}>
                                                    {new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(date))}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    }
                                    </Grid>
                                </Grid>
                                 
                            </Grid> 
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Placa</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Id. Conductor</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Destino</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Orden</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Estado</Typography>
                            </Grid>                          
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {ordersList}
                    </Grid>
                </Grid>

                <Dialog open={actionBox} onClose={closeActionBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "40%", md: "30%"},
                                // height: "20%"
                            },
                        },
                    }}
                >
                    <List sx={{width: "100%",}}>
                        <ListItem disablePadding divider sx={{marginBottom: "0.2rem", marginTop: "0.5rem"}}>
                            <ListItemButton  onClick={selectOrder}>
                                <Typography variant="h6">Detalle</Typography>
                            </ListItemButton>
                        </ListItem>
                        {/* <ListItem disablePadding divider sx={{marginBottom: "0.2rem", marginTop: "0.5rem"}}>
                            <ListItemButton  onClick={pickupBatches}>
                                <Typography variant="h6">Estibas Para Recoger</Typography>
                            </ListItemButton>
                        </ListItem> */}
                        <ListItem disablePadding divider sx={{marginBottom: "0.2rem", marginTop: "0.5rem"}}>
                            <ListItemButton  onClick={changeOrder}>
                                <Typography variant="h6">Modificaciones</Typography>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding sx={{marginBottom: "0.5rem", marginTop: "0.2rem"}}>
                            <ListItemButton  onClick={setOrderLocksBox} sx={{textAlign: "center"}}>
                                <Typography variant="h6">Cerrar Orden</Typography>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Dialog>
                <Dialog open={orderLocksBox} onClose={closeOrderLocksBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "60%", md: "60%"},
                                height: "30%"
                            },
                        },
                    }}
                >
                    <Grid container spacing={1} sx={{height: "100%", alignItems: "center"}}>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "0.5rem"}}>
                            <TextField
                                sx={{width: "90%"}}
                                variant="outlined"
                                fullWidth={true}
                                multiline
                                rows={4}
                                id="orderLocks"
                                name="orderLocks"
                                label={<Typography variant="body2">Precintos</Typography>}
                                value={orderLocks}
                                onChange={(e)=> orderLocksChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        {orderLocks &&
                            <>
                            <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                <Button onClick={closeOrderLocksBox} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                    Atrás
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                <Button onClick={completeOrder} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                    Cerrar
                                </Button>
                            </Grid>
                            </>
                        }
                    </Grid>
                </Dialog>
            </>
            }
            </Box>
        </Box>
    )
}
