import React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../components/ui/Colors";
import NumberFormat from "react-number-format";
import MenuItem from '@mui/material/MenuItem';
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import 'moment/locale/es';
import {updateDeliveryOrder} from "../../utilityFunctions/WarehouseUtil.js";
import {transportArray, destinationNutrion} from "../../Api.js"

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const updateOrderRequest = {
    id: null,
    date: null,
    transporter: null,
    driverName: null,
    driverId: null,
    carPlate: null,
    transportCost: null,
    destination: null,
    destinationType: null,
    comment: null,
}

const destinationTypeArray = [{tipo: "Nacional"}, {tipo: "Urbano"},]

export default function UpdateDeliveryOrder(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const setPropertiesToNull = (obj) => {
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            obj[prop] = null;
          }
        }
      }

    React.useEffect(()=> {
        setPropertiesToNull(updateOrderRequest);
        initialValues();
    }, []);

    const initialValues = () => {

        destinationNutrion.sort((a, b) => {
            const nameA = a.Destino.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
            const nameB = b.Destino.toUpperCase();
          
            if (nameA < nameB) {
              return -1; // a should come before b
            } else if (nameA > nameB) {
              return 1; // a should come after b
            } else {
              return 0; // names are equal
            }
        });

        updateOrderRequest.id = props.location.state.order.map((el) => {
            return el.id;
        })
    };

    const [selectedDate, setSelectedDate] = React.useState("");
    const [transporter, setTransporter] = React.useState("");
    const [driverName, setDriverName] = React.useState("");
    const [driverId, setDriverId] = React.useState("");
    const [carPlate, setCarPlate] = React.useState("");
    const [transportCost, setTransportCost] = React.useState("");
    const [destination, setDestination] = React.useState("");
    const [destinationType, setDestinationType] = React.useState("");
    const [comment, setComment] = React.useState("");;


    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format('YYYYMMDD'));
        updateOrderRequest.date=moment(date).format('YYYYMMDD');
    };

    const transporterChange = (e) => {
        setTransporter(e.target.value);
        updateOrderRequest.transporter = e.target.value;
    };

    const driverNameChange = (e) => {
        setDriverName(e.target.value);
        updateOrderRequest.driverName = e.target.value;
    };

    const driverIdChange = (e) => {
        setDriverId(e.target.value);
        updateOrderRequest.driverId = e.target.value;
    };

    const carPlateChange = (e) => {
        setCarPlate(e.target.value);
        updateOrderRequest.carPlate = e.target.value;
    };

    const transportCostChange = (e) => {
        // console.log(e.floatValue);
        setTransportCost(e.floatValue);
        updateOrderRequest.transportCost = e.floatValue;
    };

    const destinationChange = (e) => {
        setDestination(e.target.value);
        setDestinationType(e.target.value.TIPO);
        updateOrderRequest.destination = e.target.value.Destino;
        updateOrderRequest.destinationType = e.target.value.TIPO;
    };

    const destinationTypeChange = (e) => {
        setDestinationType(e.target.value);
        updateOrderRequest.destinationType = e.target.value;
    };

    const commentChange = (e) => {
        setComment(e.target.value);
        updateOrderRequest.comment = e.target.value;
    };

    const updateDelOrder = async() => {

        setLoading(true);
        const receivedOrder = await updateDeliveryOrder(updateOrderRequest, loginUser.jwtToken);
        setLoading(false);

        if(!receivedOrder){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        swal({
            title: "Actualización exitosa",
            icon: "success",
            buttons: "OK"
        });
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2}>
                     <Grid item xs={12} sm={12} md={6} sx={{width: "100%", marginTop: "0.5rem"}}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                timezone="America/Bogota"
                                locale="es" 
                                label={<Typography variant="body2">Fecha de despacho</Typography>}
                                inputFormat="DD/MM/YYYY"
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => 
                                    <TextField {...params} 
                                        variant="standard"
                                        fullWidth
                                        sx={{width: {xs: "90%", sm: "80%", md: "80%"}}}
                                    />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            sx={{textAlign: "start"}}
                            variant="standard"
                            fullWidth={true}
                            id="transporter"
                            name="transporter"
                            select
                            label={<Typography variant="body2">Transportadora</Typography>}
                            value={transporter}
                            onChange={(e)=>transporterChange(e)}
                            autoComplete="off"
                        >
                            {transportArray.map((el) => (
                                <MenuItem key={el.name} value={el.name}>
                                    {el.name}
                                </MenuItem>
                            ))}
                        </TextField> 
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="transporter"
                            name="transporter"
                            label={<Typography variant="body2">Transportadora</Typography>}
                            value={transporter}
                            onChange={(e)=> transporterChange(e)}
                            autoComplete="off"
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="driverName"
                            name="driverName"
                            label={<Typography variant="body2">Nombre Conductor</Typography>}
                            value={driverName}
                            onChange={(e)=> driverNameChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="driverId"
                            name="driverId"
                            label={<Typography variant="body2">Identificación Conductor</Typography>}
                            value={driverId}
                            onChange={(e)=> driverIdChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="carPlate"
                            name="carPlate"
                            label={<Typography variant="body2">Placa Transporte</Typography>}
                            value={carPlate}
                            onChange={(e)=> carPlateChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "90%"}}
                            name="transportCost"
                            customInput={TextField}
                            prefix={'$ '}
                            // suffix={' meses'}
                            type="transportCost"
                            label={<Typography variant="body2">Costo Transporte</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={transportCost}
                            onValueChange={(values) => {
                                transportCostChange(values);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            sx={{textAlign: "start"}}
                            variant="standard"
                            fullWidth={true}
                            id="destination"
                            name="destination"
                            select
                            label={<Typography variant="body2">Destino</Typography>}
                            value={destination}
                            onChange={(e)=>destinationChange(e)}
                            autoComplete="off"
                        >
                            {destinationNutrion.map((el) => (
                                <MenuItem key={el.Destino} value={el}>
                                    {el.Destino}
                                </MenuItem>
                            ))}
                        </TextField> 
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="destination"
                            name="destination"
                            label={<Typography variant="body2">Destino</Typography>}
                            value={destination}
                            onChange={(e)=> destinationChange(e)}
                            autoComplete="off"
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            sx={{textAlign: "start"}}
                            variant="standard"
                            fullWidth={true}
                            id="destinationType"
                            name="destinationType"
                            select
                            label={<Typography variant="body2">Tipo Destino</Typography>}
                            value={destinationType}
                            onChange={(e)=>destinationTypeChange(e)}
                            autoComplete="off"
                        >
                                {destinationTypeArray.map((el) => (
                                <MenuItem key={el.tipo} value={el.tipo}>
                                    {el.tipo}
                                </MenuItem>
                            ))}
                        </TextField> 
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="destinationType"
                            name="destinationType"
                            label={<Typography variant="body2">Tipo Destino</Typography>}
                            value={destinationType}
                            onChange={(e)=> destinationTypeChange(e)}
                            autoComplete="off"
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "2rem"}}>
                        <TextField
                            variant="outlined"
                            fullWidth={true}
                            multiline
                            rows={2}
                            id="comment"
                            name="comment"
                            label={<Typography variant="body2">Comentario</Typography>}
                            value={comment}
                            onChange={(e)=> commentChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={updateDelOrder} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                            Cargar
                        </Button>
                    </Grid>
                </Grid>
            }
            </Box>
        </Box>
    )
}
