import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { css } from "@emotion/react"
import swal from "sweetalert"
import { PuffLoader } from "react-spinners"
// import Content from "../../layout/Content"
import ContentForm from "../../layout/ContentForm"
import PageLabel from "../../components/ui/PageLabel"
import AppButton from "../../components/ui/AppButton"
import StepProgress from "../../components/ui/StepProgress"
import Colors from "../../components/ui/Colors"
import { wupoServerUri, b2bClient } from "../../Api";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import discAuthSecondFactorTextlaimerText from '../../Texts/Security';
import {
  authConditionEmail, duplicatedUsername, duplicatedEmail, errTransaction} from '../../Texts/OperationResult';
import { xrpPayment, createXrplAccount } from "../../utilityFunctions/Paymentutil";
import {authRegister} from "../../utilityFunctions/AuthUtil";
import {loginAuth} from "../../utilityFunctions/SecurityUtil"
import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import {registerExplanation} from "../../Texts/Explanations";
import privacyConsent from '../../Texts/PrivacyConsent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const fpPromise = FingerprintJS.load();
// const fpPromise = FingerprintJS.load({token: 'nDQ31LvAqr3zFsWM8omJ'});

const steps = [
  {
    key: "username",
    name: "CORREO ELECTRÓNICO",
    value: "",
  },
  // {
  //   key: "email",
  //   name: "CORREO ELECTRÓNICO",
  //   value: "",
  // },
  {
    key: "password",
    name: "CREA CONTRASEÑA",
    value: "",
  }
]

let wupoSignUpData = {
  accountId: "",
  accountRelationship: "CHILD",
  linkType: "ILP_OVER_HTTP",
  assetCode: "WPO",
  assetScale: 6,
  customSettings: {
    "ilpOverHttp.incoming.auth_type": "SIMPLE",
    "ilpOverHttp.incoming.simple.auth_token": ""
  },
  sendRoutes: "true",
  receiveRoutes: "true"
}

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const useStyles = makeStyles((theme) => ({
  
  dialogStyle:{
    whiteSpace: "pre-line"
  },
  
  linkStyle:{
    cursor: "pointer",
    textAlign: "center",
    color: "blue"
  },
}));

const PASSWORDMESSAGE = `
La clave debe ser de mínimo 8 caracteres y debe contener: minúsculas, mayúsculas, números y alguno de estos símbolos ($@$!%*#?&) 

Por ejemplo: MiClave25*`

let secondFactor;

const restictedDomains = /romebook|pk2s|ponotaxi|pibwifi/i;
const restrictedCharacters = /[*ñ´|\":<>[\]{}`\\()';@& $áéíóúàèìòù]/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const restrictedPhoneCharacters = /[ \-+()a-zA-Z]/;

let deviceFingerprint;

let privacyConsentChecked = false;

const userParams ={
  username: "",
  email: "",
  password: "",
  account: "",
  totpEnabled: "",
  deviceFingerprint: "",
  privacyConsentChecked: false
}

export default function Register(props) {
  const { updateRegisterUser, updateNavBarEnabled, userdata } = props
  let history = useHistory()

  const classes = useStyles();

  const [userInfo, setUserInfo] = useState({
    username: "", email: "", password: ""
  })
  const [stepNumber, setStepNumber] = useState(0) // cambiar para solo tomar email
  const [inputText, setInputText] = useState("")
  const [color, setColor] = useState("")
  const [strengthMessage, setStrengthMessage] = useState("")
  const [strengthLevel, setStrengthLevel] = useState(0);
  const [loading, setLoading] = useState(false)

  const deviceFingerPrint = async () =>{
    const fp = await fpPromise
    const result = await fp.get()

    // This is the visitor identifier:
    deviceFingerprint = result.visitorId
    console.log(deviceFingerprint) //DEBUG PRINTING

  }

  useEffect(() => {
    setInputText("");
  }, [stepNumber])

  const explanationMessages = () => {
    swal({
        title: "Explicación",
        text: registerExplanation,
        icon: "success",
        button: "Entiendo",
    });
  }; 

  useEffect(() => {
    updateNavBarEnabled(false);
    if(b2bClient && b2bClient === "explanation"){
      explanationMessages();
    }
    
    privacyConsentChecked = false;
    deviceFingerPrint(); //Uncomment for complete register and login
  }, []);


  const textChange = (e) => {
    
    setInputText(e.target.value)
    if(steps[stepNumber].key === 'password'){
      checkPassword(e.target.value);
    }
    // if(steps[stepNumber].key === 'username' && restrictedPhoneCharacters.test(e.target.value)){
    //   swal({
    //     title: "Formato incorrecto",
    //     text: `\n Incluye solo los 10 números del celular sin espacios, guiones o letras 
    //     `,
    //     icon: "error",
    //     button: "Entiendo"
    //   }).then(()=>{
    //     setInputText("");
    //   });
    //   return;
    // }

    setUserInfo({
      ...userInfo,
      [steps[stepNumber].key]: e.target.value
    })
  }

  const handleNextClick = () => {
    // console.log(stepNumber) //DEBBUG
    if (!inputText) return
    // if(restrictedCharacters.test(inputText) && steps[stepNumber].key === 'username'){
    //   swal({
    //     title: "USUARIO NO CREADO",
    //     text: `\n No ingreses "ñ", tildes, espacios u otro caracter especial en tu usuario`,
    //     icon: "error",
    //     button: "Entiendo"
    //   }).then(()=>{
    //     setInputText("");
    //   });
    //   return;
    // }
    if(steps[stepNumber].key === 'username'){
      if(!privacyConsentChecked){
        swal({
          title: "DATOS REQUERIDOS",
          text: `\n Para continuar debes aceptar la autorización de protección de datos \n \n
                `,
          icon: "info",
          button: "Entiendo",
        });
        return;
      };
      // if(userInfo.username.length < 10){
      //   swal({
      //     title: "CELULAR INVÁLIDO",
      //     text: `\n Ingresa los 10 números de tu celular \n \n
      //           `,
      //     icon: "info",
      //     button: "Entiendo",
      //   });
      //   return;
      // }
    }
    if(steps[stepNumber].key === 'password'){
      if(strengthLevel < 5){
        setColor("red");
        setStrengthMessage("La contraseña debe contener como mínimo 8 caractéres, una minúscula, una mayúscula, un dígito y un caracter especial ($@$!%*#?&)");
        return;
      }
    }
    if(steps[stepNumber].key === 'username' && restictedDomains.test(inputText)){
      swal({
        title: "EMAIL RESTRINGIDO",
        text: `\n El correo ingresado está restringido`,
        icon: "error",
        buttons: "entiendo"
      }).then(()=>{
        setInputText("");
      });
      return;
    }

    if(steps[stepNumber].key === 'username' && !emailRegex.test(inputText)){
      console.log("Formato de correo incorrecto");
      swal({
        title: "EMAIL INVÁLIDO",
        text: `\n Ingresa un correo válido`,
        icon: "error",
        buttons: "entiendo"
      }).then(()=>{
        setInputText("");
      });
      return;
    }

    if (stepNumber < steps.length - 1) {
      setStepNumber(stepNumber + 1);
    } else {
       //TODO: Include the TOTP conditional
       handleSecondFactorBox();

      // setLoading(true);
      // submit();
    }
  }
  const checkPassword = (password) => {
    //Regular Expressions.
    const regex = new Array();
    regex.push("[A-Z]"); //Uppercase Alphabet.
    regex.push("[a-z]"); //Lowercase Alphabet.
    regex.push("[0-9]"); //Digit.
    regex.push("[$@$!%*#?&]"); //Special Character.

    let level = 0;

    //Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
          level++;
        }
    }

    //Validate for length of Password.
    if (level > 2 && password.length > 8) {
      level++;
    }

    if (password.length > 15) {
      level++;
    }
    
    switch (level) {
        
        case 1:
          setStrengthMessage(`${PASSWORDMESSAGE}`);
          setColor("red");
        break;
        case 2:
          setStrengthMessage(`${PASSWORDMESSAGE}`);
          setColor("darkgrey");
        break;
        case 3:
        case 4:
          setStrengthMessage(`${PASSWORDMESSAGE}`);
          setColor("darkorange");
        break;
        case 5:
          setStrengthMessage("Muy buena!");
          setColor("green");
        break;
        case 6:
          setStrengthMessage("Muy fuerte");
          setColor("darkgreen");
        break;
        case 0:
        default:
          setStrengthMessage("");
          setColor("");
          setStrengthLevel(0);
        
    }
    setStrengthLevel(level);
  };
  
  const submit = async () => {
    wupoSignUpData.accountId = userInfo.username;
    wupoSignUpData.customSettings["ilpOverHttp.incoming.simple.auth_token"] = userInfo.password;
    updateRegisterUser(userInfo);
    
    userParams.username = userInfo.username;
    userParams.email = userInfo.username;
    userParams.password = userInfo.password;
    userParams.account = wupoSignUpData;
    userParams.totpEnabled = secondFactor;
    userParams.deviceFingerprint = deviceFingerprint;
    userParams.privacyConsentChecked= privacyConsentChecked;

    //Disable the navbar to hide it while the personal information is completed
    updateNavBarEnabled(false);

    const response = await authRegister(userParams);
    console.log(response);
    
    if(response.status === 200){

      swal({
        title: "USUARIO CREADO",
        // text: `\n BIENVENIDO A WUPO`,
        icon: "success",
        button: "Súper"
      })

      secondFactorRegister(response.response);
    }

    else if(response.status === 201){

      succesfullRegister(response.response);
      
    }
    
    else if(response.status === 428){
      swal({
        title: "CONFIRMACIÓN REQUERIDA",
        text: authConditionEmail,
        icon: "success",
        button: "Confirmado"
      })
      .then(()=>{
        submit(); 
      });
    }
     
    else if(response.status === 409){
      swal({
        title: "YA TIENES UN USUARIO CREADO",
        text: duplicatedUsername,
        icon: "error",
        button: "Entiendo"
      }).then(()=>{
        history.push({
          pathname: "/"
        });  
      }); 
    }

    else if(response.status === 417){
      swal({
        title: "YA TIENES UN USUARIO CREADO",
        text: duplicatedEmail,
        icon: "error",
        button: "Entiendo"
      }).then(()=>{
        history.push({
          pathname: "/"
        });  
      }); 
    }

    else {
      swal({
        title: "NO PUDIMOS CREAR TU USUARIO",
        text: errTransaction,
        icon: "error",
        button: "Entiendo"
      }).then(()=>{
        history.push({
          pathname: "/"
        });  
      }); 
    }
  }

  const succesfullRegister = (body) => {

      const loginInfo = {
        accountId: body.username,
        ilpBearerToken: body.ilpBearerToken,
        totpToken: body.totpToken,
        jwtToken: body.accessToken,
        profileCompleted: body.profileCompleted,
        roles: body.roles  
      }

      console.log(loginInfo);
      userdata(loginInfo);

      updateNavBarEnabled(false); //Disable NavBar

      if(props.location.state && props.location.state.from && props.location.state.from === "simulator"){
        console.log("simulator props");
        history.push({pathname: "/account/generalinfo",
                    state:{
                      loginInfo: loginInfo,
                      creditInformation: props.location.state.creditInformation
                    }
        }); 
        return;
      }

      else{
        console.log("non simulator props");
        history.push({pathname: "/account/generalinfo",
                    state:{
                      loginInfo: loginInfo
                    }
        });
        return;  
      }
  };

  const secondFactorRegister = (body) => {

    //Receive the TOTP QR and transform it
    let imgBase64 = arrayBufferToBase64(body);
    let base64Flag = "data:image/jpeg;base64,"
    const img = base64Flag + imgBase64;

    history.push({
      pathname: "/qrregister",
      state: {
        qrImg: img
      }
    })
    
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    let bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
  }

  //Disclaimer and privacy consent handling
  const [disclaimer, setDisclaimer] = React.useState(false);
  function handleDisclaimer(){
      setDisclaimer(true);
  }

  const handleClose = () => {
      setDisclaimer(false);
  };

  /**
   * Handle the privacy consent checking and / or unchecking
   * @param {*} e 
   */
  const handleChange = (e) => {
    privacyConsentChecked = e.target.checked;
  };


  //Authentication second factor varibales to open a dialog box and set if enabled or not since the registration
  const [secondFactorBox, setSecondFactorBox] = React.useState(false);

  function handleSecondFactorBox(){
    // setSecondFactorBox(true);
    handleDisableSecondFactor();
  }

  const handleEnableSecondFactor= () => {
    // console.log("handle enable") //DEBBUG
    swal({
      title: "CONFRIMACIÓN HABILITACIÓN",
      text: `\n Por favor confírmanos que quieres activar el código dinámico al ingresar a WUPO`,
      icon: "success",
      buttons: {
        confirmed: "Confirmo", 
        notConfirmed: "Atrás"}
    }).then((value  )=>{
      switch(value){
        case "confirmed":
          secondFactor = true;
          handleSecondFactorClose();
          break;

        case "notConfirmed":
          handleSecondFactorBox();
          break;
        default:
          break;
      }
    });
  };

  const handleDisableSecondFactor= () => {
    // console.log("handle disable") //DEBBUG
    secondFactor = false;
    handleSecondFactorClose();
  };

  const handleSecondFactorClose = () => {
    setSecondFactorBox(false);
    setLoading(true);
    submit();
  };

  const goToLogin = () => {
    history.push("/login");
  };

  return (
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
      }}
    >
      <ContentForm>
        {
          loading ?
            <PuffLoader size={200} color={Colors.secondary} css={cssLoader}/>
            :
            <>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                  <Button onClick={goToLogin}
                    sx={{marginTop: "0.5rem", backgroundColor: "none", color: Colors.primary, fontSize: "1rem", fontWeight: "bold"}}
                    component="label"
                  >
                    ¿Ya estás registrado?
                  </Button>
                </Grid>
              </Grid>
              <StepProgress stepNumber={stepNumber} steps={steps} />
              <PageLabel>{steps[stepNumber].name}</PageLabel>
              { stepNumber === 0 ?
                <div style={{maxWidth: "300px", width: "100%"}}>
                  <TextField
                    variant="standard"
                    id="email"
                    name="email"
                    type="email"
                    label="Correo electrónico"
                    fullWidth
                    value={inputText}
                    autoComplete="given-email"
                    onChange={textChange}
                    // inputProps={{ maxLength: 10, minLength: 10}}
                  />
                </div>
                : stepNumber === "x" ?
                <div style={{maxWidth: "300px", width: "100%"}}>
                <TextField
                  required
                  variant="standard"
                  id="email2"
                  type="email"
                  name="email2"
                  label="Correo electrónico"
                  fullWidth
                  value={inputText}
                  autoComplete="given-email"
                  onChange={textChange}
                />
                </div>
                :
                <div style={{maxWidth: "300px", width: "100%"}}>
                <TextField
                  variant="standard"
                  required
                  id="password"
                  type="text"
                  name="password"
                  label="Crea una contraseña"
                  fullWidth
                  value={inputText}
                  onChange={textChange}
                />
                </div>
              }
              { color && strengthMessage ?
              <em style={{color: color, whiteSpace: "pre-line", fontSize:"0.8rem", maxWidth: "300px"}}>{strengthMessage}</em>
              :
              <></>
              }
              <AppButton 
                primary 
                width={"40%"}
                onClick={handleNextClick}
              >
                {stepNumber === steps.length - 1 ? "LISTO" : "LISTO"}
              </AppButton>
            </>
        }
        {stepNumber === 0 ?
          <Grid container>
            <Grid item xs={12} sm={12} md={12} style={{textAlign: "center"}}>
              <FormControlLabel
                  required
                  control={<Checkbox color="secondary" name="privacyConsent" value="yes" />}
                  label="Acepto la autorización de tratamiento de datos"
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{textAlign: "center"}}>
              <Link onClick={handleDisclaimer} className={classes.linkStyle}>
                Autorización
              </Link>
            </Grid>
          </Grid>
          :
          <></>
        }
        <React.Fragment>
          <Dialog open={disclaimer} onClose={handleDisclaimer} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">AUTORIZACIÓN TRATAMIENTO DE DATOS</DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.dialogStyle}>
            
              {privacyConsent}

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                  Entendido
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
        <React.Fragment>
          <Dialog open={secondFactorBox} onClose={handleSecondFactorClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">MAYOR SEGURIDAD</DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.dialogStyle}>
            
              {discAuthSecondFactorTextlaimerText}

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEnableSecondFactor} color="primary">
                  Habilitar
              </Button>
              <Button onClick={handleDisableSecondFactor} color="primary">
                  Deshabilitar
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </ContentForm>
    </div>
  )
}