import React from 'react';
import {getOrdersReady, getOrdersReadyPage, getOrderByCode} from "../../utilityFunctions/WarehouseUtil";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Typography} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Colors from "../../components/ui/Colors";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

let orders;
let allOrders;

const pageRequest = {
    page: "",
};

const pageSize=100;

export default function PendingOrders(props) {
    const {loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const[disableNextButton, setDisableNextButton] = React.useState(false);

    React.useEffect(()=> {
        orders = null;
        allOrders=null;
        initialValues();
    }, []);

    const initialValues = async () => {

        pageRequest.page= 0;
        
        setLoading(true);
        const receivedOrders = await getOrdersReadyPage(pageRequest, loginUser.jwtToken);
        setLoading(false);

        if(!receivedOrders){
            return;
        }

        if(receivedOrders.length < pageSize){
            setDisableNextButton(true);
        }else{
            setDisableNextButton(false);
        }

        //TODO: Conduct this filtering in the back. Done
        // orders = receivedOrders.filter((el) => el.status === "pending");
        orders = receivedOrders;

        renderReadyOrders();

        return orders;
    };

    const [readyOrders, setReadyOrders] = React.useState(null);

    const [selectedOrder, setSelectedOrder] = React.useState([]);

    const [currentPage, setCurrentPage] = React.useState(1);

    const handlePageChange = async (newPage) => {
        setCurrentPage(newPage);
        pageRequest.page= newPage-1;

        setLoading(true);
        const receivedOrders = await getOrdersReadyPage(pageRequest, loginUser.jwtToken);
        setLoading(false);

        if(!receivedOrders){
            return;
        }

        if(receivedOrders.length < pageSize){
            setDisableNextButton(true);
        }else{
            setDisableNextButton(false);
        }

        // orders = receivedOrders.filter((el) => el.status === "pending");
        orders = receivedOrders;

        renderReadyOrders();
    };

    // Function to handle order checkbox change
    const orderChecked = (order) => {
        // Toggle the checked state of the order
        const isChecked = selectedOrder.includes(order);
        if (isChecked) {
            // If already selected, unselect the order
            // setSelectedOrder(selectedOrder.filter((orderEl) => orderEl.orderCode !== order.orderCode));
            setSelectedOrder(selectedOrder.filter((orderEl) => orderEl.id !== order.id));
            
        } else {
            // If not selected, select the order
            
            const sameOrders = orders.filter((el) => el.orderCode === order.orderCode);
            setSelectedOrder([...selectedOrder, ...sameOrders]);

            // setSelectedOrder([...selectedOrder, order]);
        }
    };

    React.useEffect(()=> {
        renderReadyOrders();
    }, [selectedOrder]);

    const renderReadyOrders = async () => {
        const ordersArray = orders;

        if(!ordersArray){
            return;
        }

        const uniqueOrdersSet = new Set();

        if(selectedOrder && !allOrders){
            selectedOrder.forEach((order) => uniqueOrdersSet.add(order));
        }
        

        ordersArray.forEach((order) => uniqueOrdersSet.add(order));
        
        // Convert the set back to an array
        const uniqueOrdersArray = [...uniqueOrdersSet];

        if(selectedOrder && uniqueOrdersSet){
            uniqueOrdersArray.sort(sortOrders);
        }

        else{
            uniqueOrdersArray.sort((a, b) => b.orderCode - a.orderCode);
        }
        
        // console.log(uniqueOrdersArray);

        setReadyOrders(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {uniqueOrdersArray.map((order)=> {
                    const isChecked = selectedOrder.includes(order);
                    return(
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton key={generateRandomKey()} onClick={() => orderChecked(order)}>
                                <Grid key={generateRandomKey()}  container spacing={1}>
                                    <Grid item xs={2} sm={2} md={2} style={{textAlign: "center"}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="secondary" name="order" value="yes" 
                                                    checked={isChecked}
                                                    onChange={() => orderChecked(order)}
                                                />
                                                
                                            }
                                        />
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(order.date))}`}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.orderCode}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.clientName}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{order.description}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{new Intl.NumberFormat('es-CO').format(order.orderAmount)}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    )
                })

                }
            </List>
        );
    };

    const sortOrders = (a, b) => {
        // First, sort by 'checkpoint' (selected comes first)
        const isCheckedA = selectedOrder.includes(a);
        const isCheckedB = selectedOrder.includes(b);
    
        if (isCheckedA && !isCheckedB) {
          return -1; // a comes first
        } else if (!isCheckedA && isCheckedB) {
          return 1; // b comes first
        }
    
        // If 'checkpoint' is the same or both are selected/unselected, then sort by 'orderCode'
        return b.orderCode - a.orderCode;
    };


    const [actionsBox, setActionsBox] = React.useState(false);
    
    const closeActionBox = () => {
        setActionsBox(false);
    }

    const goToUpdate = () => {
        history.push({
            pathname: "/warehouse/update-orders",
            state: {
                order: selectedOrder,
            }
        });
    };

    const [selectedOrderCode, setSelectedOrderCode] = React.useState("");

    const orderCodeChange = (e) => {
        setSelectedOrderCode(e.target.value);
    }

    const getFilteredOrder = async (e) => {

        const orderRequest = {
            orderCode: selectedOrderCode
        }

        setLoading(true);
        const orderRequested = await getOrderByCode(orderRequest, loginUser.jwtToken);
        setLoading(false);

        if(!orderRequested){
            return;
        };

        allOrders=orders;

        orders=orderRequested;

        renderReadyOrders();
    };

    const allPageOrders = () => {
        setSelectedOrderCode("");

        orders=allOrders;

        allOrders=null;

        renderReadyOrders();
    }

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : 
            <>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={goToUpdate} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                            Despacho
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                <TextField
                                    sx={{width: "20%"}}
                                    variant="standard"
                                    fullWidth={true}
                                    id="selectedOrderCode"
                                    name="selectedOrderCode"
                                    label={<Typography variant="body2">Código</Typography>}
                                    value={selectedOrderCode}
                                    onChange={(e)=> orderCodeChange(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            {selectedOrderCode &&
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "0.5rem"}}>
                                    <Grid container sx={{width: "20%"}}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Button onClick={getFilteredOrder} variant="contained" size="small" sx={{backgroundColor: "gray",}}>
                                                Buscar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Button onClick={allPageOrders} variant="contained" size="small" sx={{backgroundColor: "gray",}}>
                                                Todas
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container sx={{marginTop: "1.5rem"}}>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                
                            </Grid>  
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Fecha</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Código</Typography>  
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Cliente</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Producto</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Cantidad</Typography>
                            </Grid>                          
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {readyOrders}
                    </Grid>

                        
                    {!selectedOrderCode &&
                        <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "center", marginTop: "2rem", marginBottom: "2rem" }}>
                            <Button
                                onClick={() => handlePageChange(currentPage - 1)}
                                variant="contained"
                                disabled={currentPage === 1}
                                sx={{ marginRight: "1rem" }}
                            >
                                Anterior
                            </Button>
                            <Typography>{currentPage}</Typography>
                            <Button
                                onClick={() => handlePageChange(currentPage + 1)}
                                variant="contained"
                                disabled={disableNextButton}
                                sx={{ marginLeft: "1rem" }}
                            >
                                Siguiente
                            </Button>
                        </Grid>
                    }
                </Grid>

             

                <Dialog open={actionsBox} onClose={closeActionBox}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "40%", md: "30%"},
                                height: "20%"
                            },
                        },
                    }}
                >
                    <List sx={{width: "100%",}}>
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={goToUpdate}>
                                <Typography variant="h6">Completar Orden</Typography>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Dialog>
            </>
            }
            </Box>
        </Box>
    )
}

