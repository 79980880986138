import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {Typography, Button} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Colors from "../../components/ui/Colors";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
};

export default function CediOrder(props) {

    const{loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        renderOrderList();
    }, []);

    const initialValues = async () => {

        if(props.location.state && props.location.state.order){
            return props.location.state.order;
        }

        return null;
    };

    const [ordersList, setOrdersList] = React.useState("");

    const renderOrderList = async () => {

        setLoading(true);
        const ordersArray = await initialValues();
        
        console.log(ordersArray);

        if(!ordersArray || !ordersArray.productBatches){
            
            return;
        }

        ordersArray.productBatches.sort((a, b) => new Date(a.supervisorDeliveryCheck) - new Date(b.supervisorDeliveryCheck));

        setOrdersList(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {ordersArray.productBatches.map((batch) => {
                    return(
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                <Grid key={generateRandomKey()}  container spacing={1}>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{batch.warehouseProduct.barCode}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{batch.warehouseProduct.itemNumber}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{batch.warehouseProduct.description}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{`${new Intl.NumberFormat('es-CO').format(batch.productAmount)}`}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{batch.id}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.qrDate))}`}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.expirationDate))}`}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{batch.batchCode}</Typography>
                                    </Grid>
                                </Grid>
                        </ListItem>
                    )
                })}
            </List>
        );

        setLoading(false);
    };

    const handlePrint = () => {

        const orderPrint = props.location.state.order;

        const printWindow = window.open('', '_blank');

        // Write the HTML structure
        const printDocument = printWindow.document;
        printDocument.write('<html><head><title>Print Label</title></head><body style="margin: 0; margin-top: 5%;">');
        printDocument.write('<style>@media print { @page { size: landscape; } }</style>'); // Set landscape mode for printing
        printDocument.write('<style>.container { width: 95%; margin: auto;}</style>'); // Use flexbox to create a container
        printDocument.write('<style>.label-paragraph { font-size: 16px; font-weight: bold; margin: 0 0 20px 0;}</style>');
        

        // Create a container for image and text
        printDocument.write('<div class="container">');

            printDocument.write('<div style="width: 100%; margin-bottom: 40px; margin-left: 10px;">');
                printDocument.write('<p class="label-paragraph">Alimentos Nutrion - Documento de Despacho </p>');
                printDocument.write('<p class="label-paragraph">Destino:  CEDI – Chiper</p>');
                printDocument.write('<p class="label-paragraph">No de documento: ' + orderPrint.id + '</p>');
                if(orderPrint.internalSequence){
                    printDocument.write('<p class="label-paragraph">Consecutivo: ' + orderPrint.internalSequence + '</p>');
                }
                printDocument.write('<p class="label-paragraph">Fecha y hora: ' +  new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(orderPrint.date)) + '</p>');
                printDocument.write('<p class="label-paragraph">Placa del vehículo: ' + orderPrint.transportPlate + '</p>');
            printDocument.write('</div>');

            printDocument.write('<div style="width: 100%; margin-bottom: 40px;">');
                // Add the table to the print document
                printDocument.write(buildBatchTable(orderPrint));
            printDocument.write('</div>');

            printDocument.write('<div style="width: 100%; display: flex; justify-content: space-between; margin-bottom: 18px;">');
                printDocument.write('<p class="label-paragraph" style="width: 33.33%; text-align: center;">__________________</p>');
                printDocument.write('<p class="label-paragraph" style="width: 33.33%; text-align: center;">__________________</p>');
                printDocument.write('<p class="label-paragraph" style="width: 33.33%; text-align: center;">__________________</p>');
            printDocument.write('</div>');

            printDocument.write('<div style="width: 100%; display: flex; justify-content: space-between; margin-bottom: 40px;">');
                printDocument.write('<p class="label-paragraph" style="width: 33.33%; text-align: center;">Despacho Nutrion</p>');
                printDocument.write('<p class="label-paragraph" style="width: 33.33%; text-align: center;">Vehículo de Transporte</p>');
                printDocument.write('<p class="label-paragraph" style="width: 33.33%; text-align: center;">Recibido en Chiper</p>');
            printDocument.write('</div>');

        // Close the container and HTML structure
        printDocument.write('</div></body></html>');
        printDocument.close();

        for (let i = 0; i < 1; i++) {
            printWindow.print();
          }
          printWindow.close();
    };

    const buildBatchTable = (order) => {
        // Start building the table HTML as a string
        let tableHTML = `
        <table border="1" cellspacing="0" cellpadding="5" style="width: 100%; border-collapse: collapse;">
            <thead>
                <tr>
                    <th>Barcode</th>
                    <th>Item Number</th>
                    <th>Description</th>
                    <th>Product Amount</th>
                    <th>ID</th>
                    <th>QR Date</th>
                    <th>Expiration Date</th>
                    <th>Batch Code</th>
                </tr>
            </thead>
            <tbody>
        `;

        // Loop through the product batches and add each as a row in the table
        order.productBatches.forEach((batch) => {
        tableHTML += `
            <tr>
                <td style="text-align: center;">${batch.warehouseProduct.barCode}</td>
                <td style="text-align: center;">${batch.warehouseProduct.itemNumber}</td>
                <td style="text-align: center;">${batch.warehouseProduct.description}</td>
                <td style="text-align: center;">${new Intl.NumberFormat('es-CO').format(batch.productAmount)}</td>
                <td style="text-align: center;">${batch.id}</td>
                <td style="text-align: center;">${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.qrDate))}</td>
                <td style="text-align: center;">${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(batch.expirationDate))}</td>
                <td style="text-align: center;">${batch.batchCode}</td>
            </tr>
        `;
        });

        // Close the table
        tableHTML += `
            </tbody>
        </table>
        `;

        return tableHTML;

    };

    const handleBack = () => {
        history.push('/warehouse/cedi-orders-list');
    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "95%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : 
            <>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "1rem", marginBottom: "2rem"}}>
                        <Grid container sx={{textAlign: "start"}}>
                            <Grid item xs={12} sm={12} md={12} sx={{marginTop: "0.5rem", marginBottom: "3rem"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold"}}>Alimentos Nutrion - Documento de Despacho</Typography>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} sx={{marginTop: "0.5rem"}}>
                                <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>Destino:</Typography>
                            </Grid>
                            <Grid item xs={9} sm={9} md={9} sx={{marginTop: "0.5rem"}}>
                                <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>CEDI – Chiper</Typography>
                            </Grid>
                            {props.location.state && props.location.state.order &&
                            <>
                                <Grid item xs={3} sm={3} md={3} sx={{marginTop: "0.5rem"}}>
                                    <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>No de documento:</Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} md={9}  sx={{marginTop: "0.5rem"}}>
                                    <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{`${props.location.state.order.id}`}</Typography>
                                </Grid>
                                {props.location.state.order.internalSequence &&
                                    <>
                                    <Grid item xs={3} sm={3} md={3} sx={{marginTop: "0.5rem"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>Consecutivo:</Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9}  sx={{marginTop: "0.5rem"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{`${props.location.state.order.internalSequence}`}</Typography>
                                    </Grid>
                                    </>
                                }
                                <Grid item xs={3} sm={3} md={3} sx={{marginTop: "0.5rem"}}>
                                    <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>Fecha y hora:</Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} md={9}  sx={{marginTop: "0.5rem"}}>
                                    <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(props.location.state.order.date))}`}</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} sx={{marginTop: "0.5rem"}}>
                                    <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>Placa del vehículo:</Typography>
                                </Grid>
                                <Grid item xs={9} sm={9} md={9} sx={{marginTop: "0.5rem"}}>
                                    <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{`${props.location.state.order.transportPlate}`}</Typography>
                                </Grid>
                            </>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container sx={{marginTop: "1.5rem"}}>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body2" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Código Barras</Typography>
                            </Grid>
                            <Grid  item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body2" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Número Artículo</Typography>
                            </Grid>
                            <Grid  item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body2" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Descripción</Typography>
                            </Grid>
                            <Grid  item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body2" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Cantidad</Typography>
                            </Grid>
                            <Grid  item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body2" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Id Estiba</Typography>
                            </Grid>
                            <Grid  item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body2" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Fecha QR</Typography>
                            </Grid>
                            <Grid  item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body2" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Vencimiento</Typography>
                            </Grid>
                            <Grid  item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body2" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Lote</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {ordersList}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "end", marginBottom: "1.5rem"}}>
                        <Button onClick={handleBack} variant="contained" sx={{backgroundColor: Colors.primary, marginRight: "1rem"}}>
                            Atrás
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", marginBottom: "1.5rem"}}>
                        <Button onClick={handlePrint} variant="contained" sx={{backgroundColor: Colors.primary, marginLeft: "1rem"}}>
                            Imprimir
                        </Button>
                    </Grid>
                </Grid>
            </>
            }
            </Box>
        </Box>
    )
}
