import React from 'react';
import Card from '@mui/material/Card';
import NumberFormat from "react-number-format";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../components/ui/Colors";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import {createWarehouseLabel, getWarehouseCodes, discardProductBatch, activateLabelBatch} from "../../utilityFunctions/WarehouseUtil.js";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import swal from 'sweetalert';
import moment from 'moment';
import 'moment/locale/es';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

const qrDateOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: 'numeric',
    minute: 'numeric',
}

const qrRequest = {
    level: "",
    barCode: "",
    individual: 0,
    date: null,
};

let newLabelBatch;

export default function LabelGenerator(props) {

    const {loginUser, warehouseProducts} = props;

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        // getProductsCodes();
    }, []);

    const [barcode, setBarcode] = React.useState("");
    const [codes, setCodes] = React.useState("");
    const [level, setLevel] = React.useState("");
    const [individual, setIndividual] = React.useState(0);
    const [description, setDescription]= React.useState(null);

    const barcodeChange = (e) => {
        setBarcode(e.target.value);
        qrRequest.barCode=e.target.value;

        const productFound = warehouseProducts.find((el) => el.barCode === e.target.value);

        if(productFound){
            setDescription(productFound.description);
        }
    }

    const levelChange = (e) => {
        setLevel(e.floatValue);
        qrRequest.level = e.floatValue;
    };

    const individualChange = (e) => {
        setIndividual(e.floatValue);
        qrRequest.individual = e.floatValue;
    };

    const [qrImg, setQrImg] = React.useState("");

    const generateQr = async () => {
        setLoading(true);
        const qrResponse = await createWarehouseLabel(qrRequest, loginUser.jwtToken);
        setLoading(false);

        setLevel("");
        setIndividual(0);
        setSelectedDate(null);
        setBarcode("");
        setDescription(null);
        
        if(!qrResponse){
            return;
        };

        setQrImg(qrResponse.qrImageBytes);

        newLabelBatch=qrResponse.productBatch;
        

    };

    React.useEffect(()=> {
        if(qrImg){
            setQrBox(true);
        }
    }, [qrImg]);

    const[qrBox, setQrBox] = React.useState(false);

    const closeQrBox = () => {
        setQrBox(false);
    };

    const notConfirmedLabel = async () => {

        const deleteObject={
            id: newLabelBatch.id,
        }

        setLoading(true);
        const deletedBatch= await discardProductBatch(deleteObject, loginUser.jwtToken);
        setLoading(false);

        setLevel("");
        setIndividual(0);
        setSelectedDate(null);
        setBarcode("");
        setDescription(null);

        closeQrBox(true);
    };

    // const handlePrint = () => {
    //     const imageUrl = URL.createObjectURL(qrImg);
      
    //     // Create a hidden iframe for printing
    //     const iframe = document.createElement('iframe');
    //     iframe.style.display = 'none';
    //     document.body.appendChild(iframe);
      
    //     // Write the HTML structure to the iframe
    //     const printDocument = iframe.contentDocument;
    //     printDocument.write('<html><head><title>Print Label</title></head><body>');
    //     printDocument.write('<style>@media print { @page { size: landscape; } }</style>'); // Set landscape mode for printing
    //     printDocument.write('<style>img { max-width: 100%; }</style');
    //     printDocument.write('<style>.custom-paragraph { font-size: 30px; font-weight: bold; }</style>'); // Define styles for custom paragraphs
    //     printDocument.write('<style>.container { display: flex; }</style>'); // Use flexbox to create a container
      
    //     // Create a container for image and text (50% width for each)
    //     printDocument.write('<div class="container">');
      
    //     // Append the image element on the right side (50%)
    //     printDocument.write('<div style="width: 50%;">');
    //     printDocument.write('<img src="' + imageUrl + '" alt="QR">');
    //     printDocument.write('</div>');
      
    //     // Append the typographies and other elements on the left side (50%)
    //     printDocument.write('<div style="width: 50%;">');
    //     // You can customize the HTML structure here as needed
    //     // For example:
    //     printDocument.write('<p class="custom-paragraph">' + newLabelBatch.warehouseProduct.description + '</p>');
    //     printDocument.write('<p>Código: </p>');
    //     printDocument.write('<p class="custom-paragraph">' + newLabelBatch.id + '</p>');
    //     printDocument.write('<p>Lote: </p>');
    //     printDocument.write('<p class="custom-paragraph">' + newLabelBatch.batchCode + '</p>');
    //     printDocument.write('<p>Cantidad: </p>');
    //     printDocument.write('<p class="custom-paragraph">' + new Intl.NumberFormat('es-CO').format(newLabelBatch.productAmount) + '</p>');
    //     printDocument.write('<p>Vencimiento: </p>');
    //     printDocument.write('<p class="custom-paragraph">' + new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(newLabelBatch.expirationDate)) + '</p>');
    //     printDocument.write('</div>');
      
    //     // Close the container and HTML structure
    //     printDocument.write('</div></body></html>');
    //     printDocument.close();
      
    //     // Wait for the image to load
    //     iframe.onload = () => {
    //       // Print the document
    //       setTimeout(function() {
    //         iframe.focus();
    //         iframe.contentWindow.print();
    //         document.body.removeChild(iframe);
    //     }, 0);
    //     //   iframe.focus();
    //     //   iframe.contentWindow.print();
    //     //   document.body.removeChild(iframe); // Remove the iframe after printing
    //     };
      
    //     closeQrBox();
    //   };
      
      
      
    const handleBatchActivation = () => {
        const activateRequest = {
            id: newLabelBatch.id,
        }
        return activateLabelBatch(activateRequest, loginUser.jwtToken);
    };  

    const handlePrint = () => {

        //Activate the current QR and delete the pending ones
        const enabledBatch = handleBatchActivation();

        //Open a new window to print the QR as soon as the button is clicked to avoid the popup blocker
        const printWindow = window.open('', '_blank');

        if(!enabledBatch){
            swal({
                title: "Error",
                text: "No se pudo activar la estiba. Vuelva a intentarlo",
                icon: "error",
                button: "ok",
            });
            return;
        };

        const imageUrl = URL.createObjectURL(qrImg);
      
        // const printWindow = window.open('', '_blank');
      
        // Write the HTML structure
        const printDocument = printWindow.document;
        printDocument.write('<html><head><title>Print Label</title></head><body style="margin: 0; margin-top: 7%;">');
        printDocument.write('<style>@media print { @page { size: landscape; } }</style>'); // Set landscape mode for printing
        // printDocument.write('<style>img { max-width: 100%; }</style>');
        
        printDocument.write('<style>.description-paragraph { font-size: 26px; font-weight: bold; margin: 0 0 20px 0;}</style>'); // Define styles for custom paragraphs
        printDocument.write('<style>.label-paragraph { font-size: 26px; }</style>');
        printDocument.write('<style>.custom-paragraph { font-size: 48px; font-weight: bold; margin: 5px 0;}</style>'); // Define styles for custom paragraphs
        printDocument.write('<style>.container { display: flex; }</style>'); // Use flexbox to create a container
        
        // Create a container for image and text
        printDocument.write('<div class="container">');

        // Append the image element on the right side (50%)
        printDocument.write('<div style="width: 65%;">');
        printDocument.write('<img src="' + imageUrl + '" alt="QR" style="width: 100%; height: auto; margin-top: 5%;">');
        printDocument.write('<div style="text-align: start; margin-left: 20px; margin-top: 80px;">');
        printDocument.write('<p class="label-paragraph"> ' + new Intl.DateTimeFormat('es-CO', qrDateOptions).format(new Date(Date.now())) + '</p>');
        printDocument.write('</div>');
        printDocument.write('</div>');
      
        // Append the typographies and other elements on the left side (50%)
        printDocument.write('<div style="width: 35%;">');
        // You can customize the HTML structure here as needed
        // For example:
        printDocument.write('<p class="description-paragraph">' + newLabelBatch.warehouseProduct.description + '</p>');
        printDocument.write('<p class="label-paragraph">Cantidad: </p>');
        printDocument.write('<p class="custom-paragraph">' + new Intl.NumberFormat('es-CO').format(newLabelBatch.productAmount) + '</p>');
        printDocument.write('<p class="label-paragraph">Lote: </p>');
        printDocument.write('<p class="custom-paragraph">' + newLabelBatch.batchCode + '</p>');
        printDocument.write('<p class="label-paragraph">Estiba No.: </p>');
        printDocument.write('<p class="custom-paragraph">' + newLabelBatch.id + '</p>');
        printDocument.write('<p class="label-paragraph">Vencimiento: </p>');
        printDocument.write('<p class="custom-paragraph">' + new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(newLabelBatch.expirationDate)) + '</p>');
        printDocument.write('</div>');
      
        // Close the container and HTML structure
        printDocument.write('</div></body></html>');
        printDocument.close();
        
        // Wait for the image to load
        const imgElement = printDocument.querySelector('img');
        imgElement.onload = () => {
          // Print 3 copies
          for (let i = 0; i < 2; i++) {
            printWindow.print();
          }
          printWindow.close();
        };
      
        closeQrBox();
    };
      

    const [selectedDate, setSelectedDate] = React.useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format('YYYYMMDD'));
        qrRequest.date=moment(date).format('YYYYMMDD');
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "center", sm: "center", md: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
            }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <>
                <Grid container spacing={2} sx={{display: "flex", justifyContent: {xs: "center", sm: "center"}, alignItems: "center", width: "100%"}}>
                    <Card sx={{maxWidth: "400px", minWidth: {xs: "90%", sm: "90%", md: "300px", borderRadius: "15px"}}}>
                        <Grid container>
                            {/* <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                <Autocomplete
                                    {...codes}
                                    id="codes"
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {"Código no existe"}
                                    sx={{ width: "80%" }}
                                    onChange={onChangeAutocomplete}
                                    // onInputChange={onChange}
                                    renderInput={(params) => (
                                        <TextField {...params} label={<Typography variant="body2">Código producto</Typography>} variant="standard" />
                                    )}
                                /> 
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem"}}>
                                <TextField
                                    sx={{width: "80%"}}
                                    variant="standard"
                                    fullWidth={true}
                                    id="barcode"
                                    name="barcode"
                                    label={<Typography variant="body2">Código Producto</Typography>}
                                    value={barcode}
                                    onChange={(e)=> barcodeChange(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            {description &&
                                <>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginTop: "1.3rem", marginLeft: "10%"}}>
                                    <Typography sx={{color: "gray", fontSize: "0.6rem"}}>Producto</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", alignItems: "center", marginLeft: "10%"}}>
                                    <Typography variant="body1">{`${description}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "1rem", marginLeft: "10%"}}>
                                    <Divider sx={{width: "90%"}} />
                                </Grid>
                                </>
                            }
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "80%", height: "90%"}}
                                    name="level"
                                    customInput={TextField}
                                    // prefix={'$ '}
                                    type="level"
                                    label={<Typography variant="body2">Niveles</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={level}
                                    onValueChange={(values) => {
                                        levelChange(values);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "80%", height: "90%"}}
                                    name="individual"
                                    customInput={TextField}
                                    // prefix={'$ '}
                                    type="individual"
                                    label={<Typography variant="body2">Sueltos</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={individual}
                                    onValueChange={(values) => {
                                        individualChange(values);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{width: "100%", marginTop: "0.5rem"}}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        timezone="America/Bogota"
                                        locale="es" 
                                        label={<Typography variant="body2">Fecha de producción</Typography>}
                                        inputFormat="DD/MM/YYYY"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        renderInput={(params) => 
                                            <TextField {...params} 
                                                variant="standard"
                                                fullWidth
                                                sx={{width: {xs: "90%", sm: "80%", md: "80%"}}}
                                            />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Button onClick={generateQr} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem", marginBottom: "2rem"}}>
                                    Generar
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Dialog open={qrBox} onClose={closeQrBox}>
                    
                    {qrImg && newLabelBatch &&
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "0.1rem", width: "100%"}}>
                            <Box 
                                component="img"
                                sx={{
                                height: "90%",
                                // maxHeight: "200px",
                                width: "90%",
                                justifyContent: "center",
                                }}
                                alt="QR"
                                src={URL.createObjectURL(qrImg)}

                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginBottom: "1rem"}}>
                            <Grid container sx={{width: {xs: "70%", sm: "60%", md: "50%"}, display: "flex", justifyContent: "center"}}>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem", width: "100%"}}>
                                    <Typography variant = "body2">{`${newLabelBatch.warehouseProduct.description}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem", width: "100%"}}>
                                    <Typography variant = "body2">{`Código: ${newLabelBatch.id}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem", width: "100%"}}>
                                    <Typography variant = "body2">{`Lote: ${newLabelBatch.batchCode}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem", width: "100%"}}>
                                    <Typography variant = "body2">{`Cantidad: ${new Intl.NumberFormat('es-CO').format(newLabelBatch.productAmount)}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem", width: "100%"}}>
                                    <Typography variant = "body2">{`Vencimiento: ${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(newLabelBatch.expirationDate))}`}</Typography>
                                </Grid>
                            </Grid> 
                        </Grid>
                    </Grid>
                    }
                    
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button onClick={handlePrint} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Confirmar
                        </Button>
                        <Button onClick={notConfirmedLabel} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Descartar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
            }
            </Box>
        </Box>
    )
}
