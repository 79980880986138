import React from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../components/ui/Colors";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import * as XLSX from 'xlsx';
import { createDeliveryOrder } from "../../utilityFunctions/WarehouseUtil";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let orderObject = null;
let invoiceObject = null;

export default function CreateDeliveryOrder(props) {

    const{loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const [orderData, setOrderData] = React.useState([]);
    const [orderFile, setOrderFile] = React.useState(null);

    const [invoiceData, setInvoiceData] = React.useState([]);
    const [invoiceFile, setInvoiceFile] = React.useState(null);

    const handleOrderUpload = (e) => {
        const file = e.target.files[0];
    
        if (!file) {
            return;
        }
    
        setOrderFile(e.target.files[0]);
    
        const reader = new FileReader();
    
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
            // Define the specific headers you want to include
            const desiredHeaders = [
                'Nombre de cliente/proveedor',
                'Pedido No',
                'Número de artículo',
                'Descripción artículo/serv.',
                'Cant.Pedida',
            ];
    
            // Define a mapping object that maps old keys to new keys
            const keyMapping = {
                'Nombre de cliente/proveedor': 'clientName',
                'Pedido No': 'orderCode',
                'Número de artículo': 'itemNumber',
                'Descripción artículo/serv.': 'description',
                'Cant.Pedida': 'orderAmount',
            };
    
            // Filter the header row to keep only the desired headers
            const headerRow = jsonData[0];
            const filteredHeaderIndices = desiredHeaders.map((desiredHeader) =>
            headerRow.findIndex((header) => header === desiredHeader)
    );

            // Convert the array of objects and map the keys
            // Convert the array of objects and map the keys
            const arrayOfObjects = jsonData
                .slice(1)
                .filter((row) => row.some((cell) => cell !== undefined && cell !== ''))
                .map((row) => {
                    const mappedObject = {};
                    filteredHeaderIndices.forEach((index, newIndex) => {
                        if (index !== -1) {
                            const oldKey = headerRow[index];
                            const newKey = keyMapping[oldKey] || oldKey;
                            mappedObject[newKey] = row[index];
                            // mappedObject[header] = row[index];
                        }
                    });
                    return mappedObject;
                });
    
            // Set the array of objects in state
            setOrderData(arrayOfObjects);
            orderObject = arrayOfObjects;
    
            // console.log(arrayOfObjects);
        };
    
        reader.readAsBinaryString(file);
    };

    const handleInvoiceUpload = (e) => {
        const file = e.target.files[0];
    
        if (!file) {
            return;
        }
    
        setInvoiceFile(e.target.files[0]);
    
        const reader = new FileReader();
    
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
             // Define the specific headers you want to include
            const desiredHeaders = [
                'Nombre de cliente/proveedor',
                'Número de documento',
                'ItemCode',
                'Dscription',
                'Cantidad Facturada',
            ];

            // Define a mapping object that maps old keys to new keys
            const keyMapping = {
                'Nombre de cliente/proveedor': 'clientName',
                'Número de documento': 'orderCode',
                'ItemCode': 'itemNumber',
                'Dscription': 'description',
                'Cantidad Facturada': 'orderAmount',
            };
    
            // Filter the header row to keep only the desired headers
            const headerRow = jsonData[0];
            const filteredHeaderIndices = desiredHeaders.map((desiredHeader) =>
            headerRow.findIndex((header) => header === desiredHeader)
    );

            // Convert the array of objects and map the keys
            // Convert the array of objects and map the keys
            const arrayOfObjects = jsonData
                .slice(1)
                .filter((row) => row.some((cell) => cell !== undefined && cell !== ''))
                .map((row) => {
                    const mappedObject = {};
                    filteredHeaderIndices.forEach((index, newIndex) => {
                        if (index !== -1) {
                            const oldKey = headerRow[index];
                            const newKey = keyMapping[oldKey] || oldKey;
                            mappedObject[newKey] = row[index];
                            // mappedObject[header] = row[index];
                        }
                    });
                    return mappedObject;
                });
    
            // Set the array of objects in state
            setInvoiceData(arrayOfObjects);
            invoiceObject = arrayOfObjects;
    
        };
    
        reader.readAsBinaryString(file);
    };
    
    const deleteOrderFile = () => {
        setOrderFile(null);
    };

    const deleteInvoiceFile = () => {
        setInvoiceFile(null);
    };

    const uploadOrders = async () => {

        let finalOrders = [];

        if(orderObject){
            finalOrders.push(...orderObject);
            orderObject=null;
            deleteOrderFile();
        };

        if(invoiceObject){
            finalOrders.push(...invoiceObject);
            invoiceObject=null;
            deleteInvoiceFile();
        };

        setLoading(true);
        const receivedOrder = await createDeliveryOrder(finalOrders, loginUser.jwtToken);
        setLoading(false);

        if(!receivedOrder){
            swal({
                title: "Ocurrio un error",
                text: "Inténtalo de nuevo.",
                icon: "error",
                buttons: "OK"
            });
            return;
        }

        swal({
            title: "Actualización exitosa",
            icon: "success",
            buttons: "OK"
        });

    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container direction="row" spacing={2} sx={{width: { xs: "95%", sm: "80%", md: "40%"}}}>
                        {!orderFile &&
                            <Grid item xs={6} sm={6} md={6}>
                                <Button
                                    sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                    component="label"
                                    >
                                    Pedidos Clientes
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            handleOrderUpload(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        }
                        {!invoiceFile &&
                            <Grid item xs={6} sm={6} md={6}>
                                <Button
                                    sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                    component="label"
                                    >
                                    Factura Venta
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            handleInvoiceUpload(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        }
                        {orderFile &&               
                            <Grid item xs={6} sm={6} md={6}>
                                <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{orderFile.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={deleteOrderFile}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                            Borrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {invoiceFile &&               
                            <Grid item xs={6} sm={6} md={6}>
                                <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{invoiceFile.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={deleteInvoiceFile}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                            Borrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {(orderData || invoiceData) &&
                            <Grid item xs={12} sm={12} md={12}>
                                <Button onClick={uploadOrders} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                    Cargar
                                </Button>
                            </Grid>
                        }
                    </Grid>
                }
            </Box>
        </Box>
    )
}
