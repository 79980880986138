import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, DialogContent, Typography } from "@mui/material";
import Colors from "../../../components/ui/Colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';


const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

export default function CattleDetail(props) {

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        // initialValues(); //Testing
        renderPurchases();
      }, []);
      

    const [purchases, setPurchases] = React.useState();

    const initialValues = () => {
        if(props.location.state && props.location.state.selectedCattle){
          console.log(props.location.state.selectedCattle);
          return props.location.state.selectedCattle;
        }
    };

    const renderPurchases = async () => {

        const cattleSelected = await initialValues();

        console.log(cattleSelected)

        if(!cattleSelected){
            return;
        }

        console.log(cattleSelected);
        setPurchases(
          <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
            <Grid container spacing={2}>
              {cattleSelected.map((purchase) => {
                return(
                  <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                    <ListItemButton  key={generateRandomKey()} onClick={() => handleDetail(purchase)}>
                      <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                          <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                              {`${purchase.name} ${purchase.lastName}`}
                          </Typography>
                      </Grid>
                      {/* <Grid key={purchase.id} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                          <Typography key={purchase.id} variant="body1" sx={{color: "black"}}>
                              {`${purchase.username}`}
                          </Typography>
                      </Grid> */}
                      <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                              {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(purchase.investmentDate))}`}
                          </Typography>
                      </Grid>
                      <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                          <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                              {`$ ${new Intl.NumberFormat('es-CO').format(purchase.initialBalance)}`}
                          </Typography>
                      </Grid>
                      <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                            {`${new Intl.NumberFormat('es-CO').format(purchase.investmentShare * 100)} %`}
                          </Typography>
                      </Grid>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </Grid>
          </List>
        );
    
    };

    const handleDetail = () => {

    };

    const generateRandomKey = () => {
      const randomString = Math.random().toString(36).slice(2);
      const timestamp = Date.now().toString(36);
      return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center"}}>
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
                overflowY: "scroll",
            }}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container sx={{marginTop: "1.2rem"}}>
                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Nombre</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Fecha</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Valor</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Participación</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  {purchases}
                </Grid>
              </Grid>
            </Box>
        </Box>
    )
}
