import React from "react"
import { useHistory } from "react-router-dom"
import styled from "@emotion/styled"
import { FaRegAddressCard, FaWallet, FaCodepen } from "react-icons/fa"
import { IoStorefront } from "react-icons/io5"
import Colors from "../components/ui/Colors"
import Avatar from '@mui/material/Avatar';
import AvatarJorge from "../img/avatars/avatar_jorge.png";
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { GiCow } from "react-icons/gi";
import { SiRedhat } from "react-icons/si";
import { AiOutlineShoppingCart } from "react-icons/ai";
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';



const vanking = true;

const navHeight = 60

const navMenus = [
  // {
  //   id: "tx-a",
  //   name: "ALIADOS",
  //   to: "/storesmap", 
  //   icon: <IoStorefront />
  // },
  // {
  //   id: "products",
  //   name: "PRODUCTOS",
  //   // to: "/account/create_account",
  //   to: "/account/handle_account",
  //   icon: <FaWallet />
  // },
  {
    id: "admin-a",
    name: "",
    to: "/handle_profile",
    icon: <Avatar alt="Perfil" src={Avatar} >
      <PersonIcon style={{fontSize: "2rem"}} />
    </Avatar>
  },
  {
    id: "initialLogin",
    name: "SALIR",
    to: "/",
    icon: <FaRegAddressCard />
  },
]

const NavBarStyle = styled.div(({
  display: "flex",
  flexDirection: "row",
  gap: "10%",
  height: "5rem",
  width: "100%",
  // maxWidth: "620px",
  // padding: "0.25rem 1.25rem",
  justifyContent: "center",
  alignSelf: "center",
  backgroundColor: Colors.primary
  // backgroundColor: "#50C878"
  // backgroundColor: "#25d366"
  // backgroundImage: "linear-gradient(to right, #56ccf2, #2f80ed)"
  // backgroundImage: "linear-gradient(to right, #009fff, #ec2f4b)"
  // backgroundImage: "linear-gradient(to right, #396afc, #2948ff)"
  // backgroundImage: "linear-gradient(to right, #0575E6, #00F260)"
  
}))

const NavItem = styled.div(({
  width: "20%",
  display: "flex",
  alignItems: "flex-end",
  color: "white",
  cursor: "pointer",
}))

const NavBar = ({ userdata }) => {

  const history = useHistory()
  const handleClickMenu = (event, to, id) => {
    
    if(!to) return;
    if (to === "/") {
      localStorage.clear();
      userdata(null);
      history.push(to);
    }
    console.log("after to null")
    console.log(id)
    if(id==="products"){
      console.log(event)
      setAnchorEl(event.currentTarget);

    }
    else{
      history.push(to);
    }
    
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toCredit = ()=>{
    history.push("/account/create_credit");
    handleClose();
  };

  const toWallet = () => {
    history.push("/account/handle_account")
    handleClose();
  };

  const toCreateToken = () => {
    history.push("/account/create_account")
    handleClose();
  };

  const toCrm = () => {
    history.push("/commercial/deals")
    handleClose();
  };

  const toDelivery = () => {
    history.push("/lastmile/board")
    handleClose();
  };

  const toSelling = () => {
    history.push("/selling/marketplace")
    handleClose();
  };

  const toPortfolio = () => {
    history.push("/selling/client-portfolio");
    handleClose();
  };
  const toHistory = () => {
    history.push("/selling/marketplace/operations");
    handleClose();
  };
  const toCreditCard = () => {
    // history.push("/creditCard/offer");
    history.push("/creditCard/mount");
    handleClose();
  };
  const toPurchaseSimulator = () => {
    history.push("/creditCard/purchase-simulator");
    handleClose();
  };
  

  return (
    <NavBarStyle>
      <Grid container>
          <Grid display={{ xs: "none", sm: "flex", md: "flex", lg: "flex"}} xs={1} sm={6} md={8} lg={8} style={{justifyContent: "start", alignItems: "center", marginLeft: "2rem", flex: 1}}>
            <Typography variant="h4" style={{color: "white"}}>Nutrion</Typography>
          </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} style={{display: "flex", justifyContent: "center", alignItems: "center", marginRight: "0.5rem", marginLeft: "0.5rem", flex: 1}}>
          {navMenus.map(menu => (
            <Grid container key={menu.id} style={{justifyContent: "center"}}>
              <Grid item xs={3} sm={3} md={3}>
                <NavItem id={menu.id} key={menu.id} onClick={(e) => handleClickMenu(e, menu.to, menu.id)}>
                  <div style={{ textAlign: "center", marginTop: "1rem", marginBottom: "1rem" }}>
                    {menu.icon}
                    <p
                      style={{
                        margin: 0,
                        fontSize: "0.5rem",
                        fontWeight: "400",
                        overflowWrap: "break-word",
                        color: "white",
                      }}
                    >
                      {menu.name}
                    </p>
                  </div>
                </NavItem>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                
                {vanking === false &&
                <>
                  <MenuItem onClick={toCredit}>Crédito</MenuItem>
                  <MenuItem onClick={toWallet}>Billetera</MenuItem>
                  <MenuItem onClick={toCreditCard}>Tarjetas</MenuItem>
                  <MenuItem onClick={toPurchaseSimulator}>Compra TC</MenuItem>
                  <MenuItem onClick={toCreateToken}>Nuevo token</MenuItem>
                  <MenuItem onClick={toCrm}>Comercial</MenuItem>
                  <MenuItem onClick={toDelivery}>Entregas</MenuItem>
                  <MenuItem onClick={toSelling}>Lotes en Venta</MenuItem>
                  <MenuItem onClick={toPortfolio}>Lotes Comprados</MenuItem>
                  <MenuItem onClick={toHistory}>Lotes Operando</MenuItem>
                </>
                }
                {vanking === true &&
                <>
                  <MenuItem onClick={toSelling}>
                    <ListItemIcon>
                      <AiOutlineShoppingCart style={{fontSize: "1.4rem", color: "#25d366"}}/>
                    </ListItemIcon>
                    Lotes en Venta
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toPortfolio}>
                    <ListItemIcon>
                      <RocketLaunchOutlinedIcon sx={{color: "#25d366"}} />
                    </ListItemIcon>
                    Lotes Comprados
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toHistory}>
                    <ListItemIcon>
                      <GiCow style={{fontSize: "1.8rem", color: "#25d366"}}/>
                    </ListItemIcon>
                    Lotes Operando
                  </MenuItem>
                </>
                } 
                </Menu>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </NavBarStyle>
  )
}

export default NavBar
